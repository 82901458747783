import React, {Component} from 'react';

import {Badge} from "react-bootstrap";
import TopicBadge from "./TopicBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiagramProject, faWarning} from "@fortawesome/free-solid-svg-icons";

class TopicBadges extends Component {
    constructor(props) {
        super(props);
        this.topics = props.topics;
        this.smallSize = props.smallSize?props.smallSize:false;
        this.limit = props.limit != undefined?props.limit:10;
    }
    render() {
        return(
            <>
                {this.topics.reduce((result, topic, i) => {
                    if (i < this.limit ) {
                        result.push(<TopicBadge key={i} topic={topic}></TopicBadge>);
                    }
                    return result;
                }, [])}
                {this.topics.length >= this.limit  &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                        {this.limit == 0 ?
                            <span><FontAwesomeIcon icon={faDiagramProject} /> {this.topics.length} {this.smallSize?'':"Projekte "}</span>
                            :
                            <span>+{this.topics.length - this.limit}{this.smallSize?'':" weitere Projekte "}<FontAwesomeIcon icon={faDiagramProject} /></span>
                        }
                    </Badge>                }
            </>
        )
    }
}
export default TopicBadges;
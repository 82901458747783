import React, {Component, useEffect, useState} from 'react';
import {Button, Col, Container, Row, Card, Form, NavLink} from "react-bootstrap";
import UserController from "../../controller/UserController";
import logo from "../../assets/bauabc_logo.PNG";
import './LoginPage.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard, faBackwardStep, faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import * as authConfig from "../../msalAuthConfig"
import * as formik from 'formik';
import * as yup from 'yup';
import {useMsal, useAccount, useIsAuthenticated} from "@azure/msal-react";

const  AzubiLoginComponent = (props) => {

    const { Formik } = formik;
    const schema = yup.object().shape({
        username: yup.string().required("Nutzername kann nicht leer sein"),
        password: yup.string().min(5, "Das Passwort sollte mindestens 5 Zeichen lang sein").required("Passwort kann nicht leer sein")
    });
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage]);

    return(
        <Formik validationSchema={schema}
                initialValues={{ username: "", password: "" }}
                onSubmit={ async (values, { resetForm }) => {
                    try {
                        setApiResponseMessage("");
                        setInProgress(true);
                        let user = await UserController.loginUniPlusUserByCredentials(values.username,values.password);
                        if(user == null){
                            setInProgress(false);
                            console.log("error while loggin in as rainee");
                            setApiResponseMessage("Login fehlgeschlagen. Bitte erneut versuchen");
                            resetForm();
                        }else{
                            UserController.loginLocalUser(
                                user.sessionToken,
                                user.type,
                                user.email,
                                user.id
                            );
                            resetForm();
                        }
                    }
                    catch (error){
                        resetForm();
                        throw Error(error);
                    }
                }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            E-Mail Adresse eingeben
                        </Form.Label>
                        <Form.Control type="text" placeholder="E-Mail Adresse" id="username" value={values.username}
                                      onBlur={handleBlur}
                                      isInvalid={touched.username && !!errors.username}
                                      onChange={(e) => setFieldValue( "username",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control type="password" placeholder="Passwort" id="password" value={values.password}
                                      onBlur={handleBlur}
                                      isInvalid={touched.password && !!errors.password}
                                      onChange={(e) => setFieldValue( "password",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className="alert alert-danger mt-3 mb-3">{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                <Button
                                    type="submit"
                                    variant="outline-secondary">
                                    <FontAwesomeIcon icon={faIdCard}/> Auszubildender Login
                                </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const AusbilderLoginComponent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});
    //const [graphAPIData, setGraphAPIData] = useState(null);

    const login = async () =>{
        await instance.acquireTokenRedirect(authConfig.loginRequest);
    }

    const continueLogin = async () => {
        if(accounts && isAuthenticated){
            await instance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: account
            }).then(token => {
                //EVENT EventType.ACQUIRE_TOKEN_SUCCESS handled in index.js with login redirect success
            }).catch(error => {
                console.log(error);
            });
        }
    }



    // check if MS Login session exists
    if (accounts.length > 0) {
        return (
            <>
                <Button onClick={continueLogin} variant={"outline-secondary"}>
                    <FontAwesomeIcon icon={faIdCard}/> Als Ausbilder fortfahren
                </Button>
            </>
        )
    }
    else if (inProgress === "login"){
        return <span>Login in Arbeit!</span>
    }
    else {
        return(
            <>
                <Button onClick={login} variant={"outline-secondary"}>
                    <FontAwesomeIcon icon={faIdCard}/> Ausbilder Login
                </Button>
            </>
        )
    }


}

class LoginPage extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            showLoginType: "",
            isloggedIn: UserController.isLoggedIn()
        }

        if(this.state.isloggedIn){
            window.location = UserController.getMyCoursesUrl();
        }
    }

    render() {

        return (
            <div className="Login">
                <Container>
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={8} lg={6} xs={12}>

                            <Card className="shadow">
                                <Card.Body>
                                    <div align={'center'}><img src={logo} style={{height: "80px"}} className="App-logo"
                                                               alt="logo"/></div>
                                    <div className="mb-3 mt-md-4">
                                        <div align={'center'}>
                                            <h3 className="fw-bold mb-3 text-uppercase "><b>Digitales Arbeitsblatt</b></h3>
                                            {this.state.showLoginType === "" ?
                                                (<p className="mb-4">Mit UniPlus oder Microsoft einloggen?</p>)
                                                :
                                                (
                                                    <div className="d-grid mb-4">
                                                        <div align={'left'}>
                                                            <Button onClick={() => {
                                                                this.setState({showLoginType:""})
                                                            }} variant={"light"}>
                                                                <FontAwesomeIcon icon={faBackwardStep}/> zurück
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {this.state.showLoginType === "" && (
                                            <div className="mb-3">
                                                <div className="d-grid">
                                                    <div align={'center'}>
                                                        <div
                                                            style={{display: 'inline-block', marginRight: '0.5em'}}>
                                                            <Button onClick={() => {
                                                                this.setState({showLoginType: "azubi"})
                                                            }} variant="outline-primary">
                                                                <FontAwesomeIcon icon={faIdCard}/> Auszubildender Login
                                                            </Button>
                                                        </div>
                                                        <div
                                                            style={{display: 'inline-block', marginRight: '0.5em'}}>
                                                            <AusbilderLoginComponent />
                                                            </div>
                                                            {/*<div style={{display: 'inline-block'}} className={'d-none'}>
                                                                <Button onClick={() => {
                                                                    this.setState({showLoginType: "admin"})
                                                                }} variant="success">
                                                                    <FontAwesomeIcon icon={faIdCard}/> Admin Login
                                                                </Button>
                                                            </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            {this.state.showLoginType === "azubi" && (<AzubiLoginComponent/>)}
                                        </div>
                                    </div>
                                </Card.Body>
                                {this.state.showLoginType === "azubi" && (
                                    <Card.Footer align={'end'} style={{backgroundColor: 'unset',borderTop:'unset'}}> <NavLink href={"/neuespasswort"}> <span className={'text-decoration-underline'} >Hast du dein Passwort vergessen?</span></NavLink></Card.Footer>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default LoginPage;
import React, {Component} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleCoursePage.css'
import {
    faArrowLeft,
    faCalendar,
    faCaretDown,
    faCaretUp, faClock, faClose,
    faDiagramProject, faFile, faHands, faPaperPlane,
    faSheetPlastic, faSync, faTasks, faUser, faWarning,
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import UserController from "../../controller/UserController";
import CenteredMessage from "../../includes/CenteredMessage";
import APIController from "../../controller/APIController";
import _ from "lodash";
import Progress from "../../components/progressBar";
import WorksheetTableAccordion from "../../components/WorksheetTableAccordion";
import he from "he";
import {Accordion, Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import image1 from "../../assets/topic_images/1.jpg";
import {Link} from "react-router-dom";
import WorksheetListStateBadges from "../../components/badges/WorksheetListStateBadges";
import ActionButton from "../../components/input/ActionButton";
import TraineeBadge from "../../components/badges/TraineeBadge";
import DateTimeUtil from "../../util/DateTimeUtil";
import SmartWorksheetActionButtons from "../../components/WorksheetEditorComponents/SmartWorksheetActionButtons";
import StatusBadge from "../../components/badges/StatusBadge";
import PradleAccordion from "../../components/PradleAccordion";
import PagePopup from "../../components/PagePopup";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import MultiSelectInputFormField from "../../components/formFields/MultiSelectInputFormField";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import config from "../../config";


class SingleCoursePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            user: UserController.getUser(),
            instructorId: props.instructorId,
            courseId:props.params.id,
            topicId:props.params.topicId !== undefined?props.params.topicId:null,
            openSections:{
                'workingsheets':false
            },
            //worksheets: [],
            //worksheetsForProgress: [],
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            },
            worksheetHandoutSelectedTraineeId: null,
            worksheetHandoutSelectedTopicId: null,
        }
        this.params = props.params;
        this.onWorksheetsUpdated = this.onWorksheetsUpdated.bind(this);
        this.renderPopupBody = this.renderPopupBody.bind(this);


    }

    async componentDidMount() {
        try {
            let course = await APIController.getCourseById(this.state.courseId);

            let allWorksheets = [];

            /*
            Object.keys(course.worksheets).forEach(function(traineeId,index) {
                let worksheetsOfTrainee = course.worksheets[traineeId];
                worksheetsOfTrainee.forEach(async (worksheet) => {
                    worksheet.trainee = _.find(course.trainees, {_id:worksheet.traineeId});
                    allWorksheets.push(worksheet);

                });
            });
*/



            /* group and sort
            let worksheetsGroupedByTopicId = _.groupBy(allWorksheets, "topic")
            let worksheetsGroupedByTopicIdSorted = Object.keys(worksheetsGroupedByTopicId)
                .sort()
                .reduce(function (acc, key) {
                    acc[key] = worksheetsGroupedByTopicId[key];
                    return acc;
                }, {});

            console.log(worksheetsGroupedByTopicIdSorted)
*/
            this.setState({
                isFetching: false,
                course: course,
                /*worksheetsGroupedByTopicId :worksheetsGroupedByTopicIdSorted,
                worksheets: _.groupBy(allWorksheets, "courseId"),
                worksheetsForProgress: allWorksheets*/
            })

        } catch (e) {
            console.log(e);
            this.setState({
                isFetching: false,
                course: null,
                /*worksheetsGroupedByTopicId : {},
                worksheets: [],
                worksheetsForProgress: []*/
            })
        }
    }

    onWorksheetsUpdated(updatedWorksheets){
        let allworksheets = [];
        Object.keys(updatedWorksheets).map((course, sectionIndex) => {
            updatedWorksheets[course].map((w) => {
                allworksheets.push(w);
            });
        });
        this.setState({
            worksheetsForProgress: allworksheets
        });
    }

    getTopicName(topicId){
        let name = "";
        this.state.course.topics.map((topic,idx)=>{
            if(topic.projectId == topicId){
                name = topic.name;
            }
        });
        return name;
    }

    async handoutWorkingsheet(traineeId,projectId){
        const response = await APIController.assignWorkingsheet(this.state.course._id, traineeId, projectId);
        if(response){
            await this.setState({popupProps:{show:false}});
            window.location.reload();
        }else{
            await this.setState({popupProps:{show:false}});
            alert("Es konnte kein Arbeitsblatt erstellt werden");
        }
    }

    renderPopupBody(){
        let topics = this.state.course.topics.map((topic,idx)=>{
            return(
                {name: he.decode("("+topic._id+") "+topic.name), value: topic._id}
            )
        });
        let trainees = this.state.course.trainees.map((trainee,idx)=>{
            return(
                {name: he.decode(trainee.name+", "+trainee.firstname), value: trainee._id}
            )
        });

        this.state.worksheetHandoutSelectedTraineeId = trainees[0].value;
        this.state.worksheetHandoutSelectedTopicId = topics[0].value;

        return (
            <>
                <SingleSelectInputFormField
                    title={<><FontAwesomeIcon icon={faUser} /> Azubi auswählen</>}
                    selectedValue={this.state.worksheetHandoutSelectedTraineeId}
                    options={trainees}
                    onChange={(selectedOption) => {
                        this.setState({
                            worksheetHandoutSelectedTraineeId: selectedOption.value,
                        })
                    }}
                />
                <SingleSelectInputFormField
                    title={<><FontAwesomeIcon icon={faDiagramProject} /> Projekt auswählen</>}
                    selectedValue={this.state.worksheetHandoutSelectedTopicId}
                    options={topics}
                    onChange={(selectedOption) => {
                        this.setState({
                            worksheetHandoutSelectedTopicId: selectedOption.value,
                        })
                    }}
                />

            </>
        )
    }

    render() {
        var self = this;

        if(this.state.isFetching){
            return <LoadingScreen/>;
        }

        if(this.state.topicId != null) {
            //let worksheetsOfTrainee = this.state.worksheetsGroupedByTopicId[this.state.topicId];

           // let worksheets = _.groupBy(worksheetsOfTrainee, "topic");
            return (

                <>
                    <div className={'SingleCoursePage'}>
                        <PageContent>
                            <Container>
                    <div>

                        <div style={{marginBottom:'1em'}}>
                            <Row>
                                <Col xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <h1 style={{color:'white',textAlign:'center'}}><a href={"/courses/"+this.state.course._id}><FontAwesomeIcon icon={faArrowLeft} /></a> {this.state.course.name}</h1>
                                </Col>
                                <Col xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <h3><FontAwesomeIcon icon={faDiagramProject} /> {this.state.topicId}</h3>
                                </Col>

                                <Col xs={8} sm={8} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                    <h2><span style={{display:'inline-block'}}>{he.decode(this.getTopicName(this.state.topicId))}</span>
                                    </h2>
                                </Col>
                                <Col xs={4} sm={4} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                    <h2><WorksheetListStateBadges worksheets={this.state.course.worksheets[this.state.topicId]} /></h2>
                                </Col>
                            </Row>
                        </div>
                        <WorksheetTableAccordion
                            groupedByTopics={true}
                            params={self.params}
                            topicId={self.state.topicId}
                            allTopics={self.state.course.topics}
                            userType={UserController.getUser().type}
                            onWorksheetsUpdated={self.onWorksheetsUpdated}
                            worksheets={this.state.course.worksheets}
                        />
                    </div>
                            </Container>
                        </PageContent>
                    </div>
                </>

            )
        }




        return (
            <>
                <div className={'SingleCoursePage'}>
                    <PageContent>
                        <Container>
                            <h1 style={{color:'white',textAlign:'center'}}>{this.state.course.name}</h1>
                            {this.state.course.topics.length == 0?
                                <h3>
                                    <small>Keine Projekte <FontAwesomeIcon icon={faDiagramProject} /></small>
                                </h3>
                                :<>
                                    <div style={{marginBottom:'1em'}}>

                                        <Button type="button" className={"btn btn-primary btn-lg"} style={{marginRight:'1em',display:'inline-block'}} variant="info" onClick={async ()=>{

                                            this.setState({
                                                isFetching:true
                                            });
                                            const params = new URLSearchParams({
                                                courseId: this.state.course._id
                                            });
                                            addSessionTokenToRequestHeader();
                                            let resp = await fetch(config.API_HOST_URL + "/courses/sync?" + params.toString());
                                            if (!resp.ok) {
                                                throw Error(resp.statusText);
                                            }else{
                                                this.setState({
                                                    isFetching:false
                                                });
                                                window.location.reload();
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faSync}></FontAwesomeIcon> Kurs synchronisieren</Button>
                                        <Button type="button" className={"btn btn-primary btn-lg"} variant="primary" onClick={()=>{
                                        this.setState({popupProps:{
                                                title: <><FontAwesomeIcon icon={faSheetPlastic} /> Digitales Arbeitsblatt - Manuelle Freigabe</>,
                                                body: this.renderPopupBody(),
                                                show: true,
                                                showFooter: true
                                            }});
                                        }}>
                                            <FontAwesomeIcon icon={faSheetPlastic}></FontAwesomeIcon> Arbeitsblatt herausgeben</Button>

                                        <Button type="button" className={"btn btn-primary btn-xs"} style={{marginRight:'1em',display:'inline-block',float:"right"}} variant="danger" onClick={async ()=>{
                                            await APIController.deleteAllWorksheetsByCourseId(this.state.course._id).then(r => {
                                                window.alert("Arbeitsblätter erfolgreich gelöscht");
                                                window.location.reload();
                                            })
                                        }}>
                                            <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> Alle löschen</Button>
                                    </div>
                                    <PagePopup
                                        className={"evaluationPopup"}
                                        onClose={()=>this.setState({popupProps:{show:false}})}
                                        onSave={async ()=>{
                                            await this.handoutWorkingsheet(
                                                this.state.worksheetHandoutSelectedTraineeId,
                                                this.state.worksheetHandoutSelectedTopicId,
                                            );
                                        }}
                                        saveButtonText={<><FontAwesomeIcon icon={faPaperPlane} /> Arbeitsblatt absenden</>}
                                        closeButtonText={<><FontAwesomeIcon icon={faClose} /> Abbrechen</>}
                                        key={this.state.popupProps.show}
                                        {...this.state.popupProps}>
                                    </PagePopup>
                                    <PradleAccordion title={<div><FontAwesomeIcon icon={faDiagramProject} /> Projekte</div>} keyId={"topics"} open={true}>
                                        <Row>
                                            {this.state.course.topics.map((topic,idx)=>{
                                                return <Col xs={12} sm={6} lg={4} xl={3}>
                                                    <div className={"topicKachelOuter"}>
                                                        <a href={"/courses/"+this.state.course._id+"/"+topic._id} style={{textDecoration:'none'}}>
                                                            <div className={"topicKachel"}>

                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <div className={"topicSticker"}><span style={{fontWeight:'bold'}}>{he.decode(topic._id)}</span></div>
                                                                        <img className={"topicImage"} src={image1}  alt={topic._id}/>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <div className={"topicKachelInner"} style={{marginBottom:'1em'}}>
                                                                            <h3><span className={"topicId"} style={{borderRadius:0}} >{he.decode(topic.name)}</span></h3>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} sm={9}>
                                                                        <div className={"topicKachelInner"}>
                                                                            {(this.state.course.worksheets.filter(obj => obj.topic	=== topic._id).length > 0) &&
                                                                                <div className={"workingsheetBadges"}>
                                                                                    <WorksheetListStateBadges worksheets={this.state.course.worksheets.filter(obj => obj.topic	=== topic._id)} />
                                                                                </div>
                                                                            }
                                                                            {(this.state.course.worksheets.filter(obj => obj.topic	=== topic._id).length === 0) &&
                                                                                <div className={"workingsheetBadges"}>
                                                                                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                                                                                        <span><FontAwesomeIcon icon={faDiagramProject} /> Keine Arbeitsblätter</span>
                                                                                    </Badge>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </a>
                                                    </div>
                                                </Col>
                                            })}
                                        </Row>

                                    </PradleAccordion>
                                </>
                            }
                        </Container>
                    </PageContent>
                </div>
            </>)


    }
}


export default SingleCoursePage;
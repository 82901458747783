import React, {Component} from 'react';

import {Badge} from "react-bootstrap";
import TraineeBadge from "./TraineeBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUsers, faWarning} from "@fortawesome/free-solid-svg-icons";

class TraineeBadges extends Component {
    constructor(props) {
        super(props);
        this.trainees = props.trainees;
        this.smallSize = props.smallSize?props.smallSize:false;
        this.limit = props.limit != undefined?props.limit:10;
    }
    render() {
        return(
            <>
                {this.trainees.reduce((result, trainee, i) => {
                    if (i < this.limit ) {
                        result.push(<TraineeBadge key={i} trainee={trainee}></TraineeBadge>);
                    }
                    return result;
                }, [])}
                {this.trainees.length > this.limit  &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                        {this.limit == 0 ?
                            <span><FontAwesomeIcon icon={faUsers} /> {this.trainees.length} {this.smallSize?'':"Auszubildende"}</span>
                            :
                            <span>+{this.trainees.length - this.limit} {this.smallSize?'':"weitere Auszubildende"} <FontAwesomeIcon icon={faUser} /></span>
                        }
                    </Badge>                     }
                {this.trainees.length == 0 &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                           className={"traineeBadge"} bg="dark">
                        <FontAwesomeIcon icon={faWarning}/> {this.smallSize?'':"Keine Auszubildenden"}
                    </Badge>
                }
            </>
        )
    }
}
export default TraineeBadges;
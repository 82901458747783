import React, {Component} from 'react';

class ActionButton extends Component {
    render() {
        return (
            <button
                type="button"
                className={"actionButton btn"}
                onClick={() => {this.props.handler()}}
            >
                {this.props.children}
            </button>
        );
    }

}
export default ActionButton;
import React, {Component} from 'react';


import config from "../../config";


import ProjectsTable from "../../components/TemplatesTable";

import LoadingScreen from "../../includes/LoadingScreen";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
class ProjectListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            projects : null,
        };
    }

    async componentDidMount() {
        await this.reload()
    }

    async reload() {
        try {
            addSessionTokenToRequestHeader();
            let response = await fetch(config.API_HOST_URL + "/projects");
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            this.setState({
                isFetching: false,
                projects : json
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                projects : null
            });
        }
    }



    render() {

        if (this.state.isFetching)
            return <LoadingScreen />;

        if(!this.state.isFetching && this.state.projects == null){
            return <div>error</div>
        }
        return (
            <div>
                <ProjectsTable projects={this.state.projects}></ProjectsTable>
            </div>
        );
    }
}


export default ProjectListPage;
import React, {Component} from 'react';

import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiagramProject, faFileWord, faSheetPlastic, faWarning} from "@fortawesome/free-solid-svg-icons";
import WorksheetBadge from "./WorksheetBadge";

class WorksheetBadges extends Component {
    constructor(props) {
        super(props);
        this.worksheets = props.worksheets;
        this.smallSize = props.smallSize?props.smallSize:false;
        this.limit = props.limit != undefined?props.limit:10;
    }
    render() {
        return(
            <>
                {this.worksheets.reduce((result, worksheet, i) => {
                    if (i < this.limit ) {
                        result.push(<WorksheetBadge key={i} worksheet={worksheet}></WorksheetBadge>);
                    }
                    return result;
                }, [])}
                {this.worksheets.length >= this.limit  &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                        {this.limit == 0 ?
                            <span><FontAwesomeIcon icon={faSheetPlastic} /> {this.worksheets.length} {this.smallSize?'':"Arbeitsblätter"}</span>
                            :
                            <span>+{this.worksheets.length - this.limit} {this.smallSize?'':"weitere Arbeitsblätter"} <FontAwesomeIcon icon={faFileWord} /></span>
                        }
                    </Badge>                }
            </>
        )
    }
}
export default WorksheetBadges;
import React, {Component} from 'react';

import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import {Col, Row} from "react-bootstrap";

class WorksheetViewStateSwitcher extends Component {
    constructor(props) {
        super(props);
        this.onStatesChange = props.onStatesChange;
        this.state = {
            instructorMode: props.instructorMode,
            editMode: props.editMode,
            readOnlyMode: props.readOnlyMode,
        };
    }

    render() {
        var self = this;
        return (
                <Row>
                    <Col>
                        <SingleSelectInputFormField
                            title={""}
                            description={""}
                            placeholder={"Ja/Nein"}
                            selectedValue={this.state.readOnlyMode?"true":"false"}
                            options={[
                                {name: "Lese-Ansicht", value: "true"},
                                {name: "Schreib-Ansicht", value: "false"}
                            ]}
                            onChange={(selectedOption) => {
                                let readOnlyMode = selectedOption.value === "true";
                                this.onStatesChange(this.state.instructorMode, this.state.editMode,readOnlyMode);
                                this.setState({
                                    readOnlyMode: readOnlyMode
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <SingleSelectInputFormField
                            title={""}
                            description={""}
                            placeholder={"Ja/Nein"}
                            selectedValue={this.state.instructorMode?"true":"false"}
                            options={[
                                {name: "Ausbilder-Ansicht", value: "true"},
                                {name: "Azubi-Ansicht", value: "false"}
                            ]}
                            onChange={(selectedOption) => {
                                let instructorMode = selectedOption.value === "true";
                                this.onStatesChange(instructorMode, this.state.editMode,this.state.readOnlyMode);
                                this.setState({
                                    instructorMode: instructorMode
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <SingleSelectInputFormField
                            title={""}
                            description={""}
                            placeholder={"Ja/Nein"}
                            selectedValue={this.state.editMode?"true":"false"}
                            options={[
                                {name: "Feldkonfiguration", value: "true"},
                                {name: "Arbeitsblatt-Modus", value: "false"}
                            ]}
                            onChange={(selectedOption) => {
                                let editMode = selectedOption.value === "true";
                                this.onStatesChange(this.state.instructorMode, editMode,this.state.readOnlyMode);
                                this.setState({
                                    editMode: editMode
                                });
                            }}
                        />
                    </Col>
                </Row>
        )
    }
}
export default WorksheetViewStateSwitcher;
import './App.css';


import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes, Navigate,
    useParams, useLocation
} from "react-router-dom";
import PageAbout from "./pages/user/PageAbout";
import PageNotFound from "./pages/pageComponents/PageNotFound";
import PageLayout from "./pages/pageComponents/PageLayout";
import ProjectListPage from "./pages/admin/ProjectListPage";
import ProjectPage from "./pages/user/ProjectPage";
import ProjectTemplateListPage from "./pages/user/ProjectTemplateListPage";
import TopMenuPageLayout from "./pages/pageComponents/TopMenuPageLayout";
import TopicsListPage from "./pages/admin/TopicsListPage";
import TraineesListPage from "./pages/admin/TraineesListPage";
import CoursesListPage from "./pages/admin/CoursesListPage";
import InstructorsListPage from "./pages/admin/InstructorsListPage";
import SingleInstructorPage from "./pages/instructor/SingleInstructorPage";
import SingleCoursePage from "./pages/instructor/SingleCoursePage";
import SingleTraineePage from "./pages/trainee/SingleTraineePage";
import UserController from "./controller/UserController";
import LoginPage from "./pages/user/LoginPage";
import LoadingScreen from "./includes/LoadingScreen";
import WorksheetEditorPage from "./pages/user/WorksheetEditorPage";
import {MsalProvider} from "@azure/msal-react";
import MsalRedirect from "./pages/user/MsalRedirect";
import WorksheetsListPageForTrainee from "./pages/trainee/WorksheetsListPageForTrainee";
import WorksheetsListPageForInstructor from "./pages/instructor/WorksheetsListPageForInstructor";
import MyCoursesListPage from "./pages/instructor/MyCoursesListPage";
import ActivateAccount from "./pages/user/ActivateAccount";
import ForgotPassword from "./pages/user/ForgotPassword";
import MyProfilePage from "./pages/admin/MyProfilePage";
import WorksheetPage from "./pages/user/WorksheetPage";
import TopicsListPageForInstructor from "./pages/instructor/TopicsListPageForInstructor";
import _ from "lodash";
import WorksheetPrintViewComponent from "./components/WorksheetPrintViewComponent";
import config from "./config";
import TraineeProfileUpdateFormComponent from "./pages/trainee/TraineeProfileUpdateFormComponent";
import SingleTopicsPageForInstructor from "./pages/instructor/SingleTopicPageForInstructor";
import UpdatePassword from "./pages/trainee/UpdatePassword";
import RestPassword from "./pages/trainee/RestPassword";
import WorksheetPrintPage from "./pages/user/WorksheetPrintPage";

function App({ instance }) {



  return (
      <MsalProvider instance={instance}>
          <Router>
              <AppRoutes />
          </Router>
      </MsalProvider>
  );
}



function AppRoutes() {
  const location = useLocation();


    if(location.pathname === "/pdf"){
        const urlParams = new URLSearchParams(window.location.search);
        const worksheetId = encodeURIComponent(urlParams.get('id'));
        const token = urlParams.get('token');
        if(token !== config.PRADLE_PDF_EXPORT_TOKEN){
            return(<div className={'WorksheetPrintViewComponent'}><span>this is not allowed</span></div>)
        }else{
            return (<WorksheetPrintViewComponent worksheetId={worksheetId} />)
        }
    }
    if(location.pathname === "/aktivieren"){
        if(UserController.isLoggedIn()){
            window.location = UserController.getMyCoursesUrl();
            return null;
        }
        const urlParams = new URLSearchParams(window.location.search);
        return (<ActivateAccount params={urlParams} />)
    }
    if(location.pathname === "/neuespasswort"){
        if(UserController.isLoggedIn()){
            window.location = UserController.getMyCoursesUrl();
            return null;
        }
        const urlParams = new URLSearchParams(window.location.search);
        return (<ForgotPassword params={urlParams}/>);
    }
    if(location.pathname === "/passwortzuruecksetzen"){
        if(UserController.isLoggedIn()){
            window.location = UserController.getMyCoursesUrl();
            return null;
        }
        const urlParams = new URLSearchParams(window.location.search);
        return (<RestPassword params={urlParams}/>);
    }

    if(location.pathname !== "/login" && location.pathname !== "/redirect" && !UserController.isLoggedIn()){
        UserController.logout();
    }




    if((location.pathname === "/login" || UserController.isLoggedIn())){




        return <Routes>
            <Route path="/" element={<Navigate replace to="/login" />}/>
            <Route path="/notfound" element={<PageLayout location={location}><PageNotFound/></PageLayout>}/>
            <Route path="/:projectId" element={<ProjectPageLink />} />
            <Route path="/editor/:projectId" element={<ProjectEditorPageLink />} />

            <Route path="/meine-projekte" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>
            <Route path="/ltb/digitales-arbeitsblatt/meine-projekte" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>
            <Route path="/ltb/digitales-arbeitsblatt/meine-projekte/ausbildungsjahr/:ausbildungsjahr" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>
            <Route path="/ltb/digitales-arbeitsblatt/meine-projekte/gruppe/:groupNameFilter" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>
            <Route path="/ltb/digitales-arbeitsblatt/meine-projekte/projekt/:topicFilter" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>
            <Route path="/ltb/digitales-arbeitsblatt/meine-projekte/gruppe/:groupNameFilter/projekt/:topicFilter" element={<TraineeWorksheetListPageLink></TraineeWorksheetListPageLink>}></Route>

            <Route path={"/instructor/:instructorId/worksheets"} element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location}>
                        <InstructorWorksheetListPageLink />
                    </TopMenuPageLayout>
                </PageLayout>
            }></Route>
            <Route path="/worksheets/:id" element={
                <PageLayout location={location}>
                    <WorksheetEditorPageForTraineePageLink />
                </PageLayout>
            } />
            <Route path="/worksheets" element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location}>
                        <ProjectListPage />
                    </TopMenuPageLayout>
                </PageLayout>
            } />
            <Route path="/login" element={
                <LoginPage />
            } />
            <Route path="/templates" element={<TemplatesPageLink />} />
            <Route path="/templates/:professionId" element={<TemplatesPageLink />}/>


            <Route path="/projects" element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location} >
                        <TopicsListPage />
                    </TopMenuPageLayout>
                </PageLayout>
            } />
            <Route path="/trainees" element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location}>
                        <TraineesListPage />
                    </TopMenuPageLayout>
                </PageLayout>
            } />

            <Route path="/courses" element={<CoursesListPageLink />} />

            <Route path="/worksheets/:id/pdf" element={<WorksheetPDFPageLink />} />


            <Route path="/profile" element={<MyProfilePagePageLink />} />

            <Route path="/instructors" element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location} >
                        <InstructorsListPage />
                    </TopMenuPageLayout>
                </PageLayout>
            } />
            <Route path="/instructors/:id" element={<SingleInstructorPageLink />} />

            <Route path="/instructor/:id/profile" element={<SingleInstructorPageLink />} />

            <Route path="/instructor/:id/courses" element={<InstructorCoursesListPageLink />}/>
            <Route path="/trainee/:id/profile" element={<SingleTraineePageLink />} />
            <Route path="/trainee/:id/profile/update" element={
                <PageLayout location={location}>
                    <TopMenuPageLayout location={location} >
                        <TraineeProfileUpdateFormComponent />
                    </TopMenuPageLayout>
                </PageLayout>
            } />
            <Route path="/trainee/:id/profile/update-password" element={<UpdatePassword />} />

            <Route path="/topics" element={<TopicsListPageForInstructorPageLink />}/>
            <Route path="/topics/:professionId" element={<TopicsPageForInstructorPageLink />}/>
            <Route path="/topics/:professionId/:ausbildungsjahrFilter/:gewerkFilter" element={<TopicsPageForInstructorPageLink />}/>
            <Route path="/topics/:professionId/:gewerkFilter" element={<TopicsPageForInstructorPageLink />}/>

            <Route path="/courses/:id" element={<SingleCoursePageLink />} />

            <Route path="/courses/:id/:topicId" element={<SingleCoursePageLink />} />


            <Route path="/trainees/:id" element={<SingleTraineePageLink />} />

            <Route path="/about" element={
                <PageLayout location={location}>
                    <PageAbout />
                </PageLayout>
            } />
            <Route path="*" element={
                <PageLayout location={location}>
                    <PageNotFound/>
                </PageLayout>
            }/>
        </Routes>
    }else{
        if(location.pathname === "/redirect"){
            return <MsalRedirect />;
        }else{
            return <LoadingScreen />;
        }
    }


}

function InstructorCoursesListPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <MyCoursesListPage userType={'instructor'} params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function MyProfilePagePageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <MyProfilePage location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function WorksheetPDFPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <WorksheetPrintPage worksheetId={params.id} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function CoursesListPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <CoursesListPage params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}
function TemplatesPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <ProjectTemplateListPage professionId={params.professionId!==undefined?params.professionId:null} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}


function TraineeCoursesListPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <MyCoursesListPage userType={'trainee'} params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function TopicsListPageForInstructorPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <TopicsListPageForInstructor params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function TopicsPageForInstructorPageLink(){
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout location={location}>
                <SingleTopicsPageForInstructor params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function SingleInstructorPageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout  location={location}>
                <SingleInstructorPage params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function SingleTraineePageLink() {
    const location = useLocation();
    const params = useParams();
    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout  location={location}>
                <SingleTraineePage params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}

function SingleCoursePageLink(props) {
    const location = useLocation();
    const params = useParams();

    if(UserController.getUser().type === 'trainee'){
        throw new Error('invalid user permissions');
    }

    return <>
        <PageLayout location={location}>
            <TopMenuPageLayout  location={location}>
                <SingleCoursePage params={params} location={location} />
            </TopMenuPageLayout>
        </PageLayout>
    </>;
}


function ProjectEditorPageLink() {
  const location = useLocation();
  const params = useParams();
  return <>
      <PageLayout location={location}>
          <ProjectPage editMode={true}  key={params.projectId} projectId={params.projectId} location={location}/>
      </PageLayout>
  </>;
}

function WorksheetEditorPageForTraineePageLink(){
    const location = useLocation();
    const params = useParams();
    const urlSearchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const user = UserController.getUser();

    if(queryParams.readOnly==="true"){
        return <>
            <WorksheetPage
                location={location}
                key={params.id}
                instructorMode={queryParams.evaluate==="true" && user.type === 'instructor'?true:false}
                worksheetId={params.id}
                traineeId={params.traineeId}
            />
        </>;
    }else{
        return <>
            <WorksheetEditorPage
                location={location}
                key={params.id}
                instructorMode={queryParams.evaluate==="true" && user.type === 'instructor' ?true:false}
                worksheetId={params.id}
                traineeId={params.traineeId}
            />
        </>;
    }


}
function ProjectPageLink() {
  const location = useLocation();
  const params = useParams();
    const urlSearchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    let instructorMode = false;
    if(!_.isEmpty(queryParams.instructorMode) && queryParams.instructorMode === 'true'){
        instructorMode = true;
    }

  return <>
      <PageLayout location={location}>
          <ProjectPage editMode={false} instructorMode={instructorMode} key={params.projectId} projectId={params.projectId} location={location}/>
      </PageLayout>
  </>;
}

function TraineeWorksheetListPageLink(){
    const location = useLocation();
    const params = useParams();
    return(
    location.pathname.includes("ltb")?
    <>
        <div className={"ltb-view"}>
            <PageLayout location={location}>
                <TopMenuPageLayout location={location}>
                    <WorksheetsListPageForTrainee
                        key={params.id}
                        ltbView={true}
                        traineeId={params.traineeId}
                        groupNameFilter={params.groupNameFilter}
                        topicFilter={params.topicFilter}
                        location={location}
                    />
                </TopMenuPageLayout>
            </PageLayout>

        </div>
    </>:
        <>
            <PageLayout location={location}>
                <TopMenuPageLayout location={location}>
                        <WorksheetsListPageForTrainee
                        key={params.id}
                        ltbView={false}
                        traineeId={params.traineeId}
                        groupNameFilter={params.groupNameFilter}
                        topicFilter={params.topicFilter}
                        location={location}
                    />
                </TopMenuPageLayout>
            </PageLayout>
        </>
    );
}

function InstructorWorksheetListPageLink(){
    const location = useLocation();
    const params = useParams();

    return <>
        <WorksheetsListPageForInstructor
            key={params.id}
            instructorId={params.instructorId}
            location={location}
        />
    </>;
}
export default App;

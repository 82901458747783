import React, {Component} from 'react';


import config from "../../config";
import {Badge, Button, Col, Container, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './CoursesListPage.css'
import {
    faBox, faBoxes,
    faCalendarDay,
    faCalendarDays,
    faClock,
    faProjectDiagram, faSearchMinus,
    faStopwatch,
    faUser,
    faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import DateTimeUtil from "../../util/DateTimeUtil";
import {Link} from "react-router-dom";
import DataTablePageHeader from "../../includes/DatatablePageHeader";
import PageContent from "../../includes/PageContent";
import KWFilter from "../../includes/KWFilter";
import CenteredMessage from "../../includes/CenteredMessage";
import TraineeBadge from "../../components/badges/TraineeBadge";
import InstructorBadge from "../../components/badges/InstructorBadge";
import TraineeBadges from "../../components/badges/TraineeBadges";
import InstructorBadges from "../../components/badges/InstructorBadges";
import TopicBadges from "../../components/badges/TopicBadges";
import GenericBadges from "../../components/badges/GenericBadges";
import GenericBadge from "../../components/badges/GenericBadge";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import CoursesListAccordions from "../../components/CoursesListAccordions";



class CoursesListPage extends Component {

    constructor(props) {
        super(props);

        this.params = props.params;
        this.location = props.location;
        this.state = {
            isFetching: true,
            reload:true,
            page:1,
            pagesTotel:0,
            numTotal:0,
            courses : [],
            worksheets:[],
            userType:props.userType !== undefined ? props.userType:null,
            userId:props.params !== undefined ? props.params.id:null,
            typeFilter: 'alle',
            currentKw: DateTimeUtil.getCurrentKW(),
        };
    }




    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            //window.alert(JSON.stringify(this.state.currentKw))

            let queryParams = {
                page: this.state.page,
                typeFilter: 'noInstructors'
            };
            if(this.state.typeFilter != null){
                queryParams["typeFilter"] = this.state.typeFilter;
            }
            if(this.state.userType != null){
                queryParams["userType"] = this.state.userType;
            }
            if(this.state.userId != null){
                queryParams["userId"] = this.state.userId;
            }
            if(this.state.currentKw != null){
                queryParams["from"] = this.state.currentKw.mondayDate;
                queryParams["to"] = this.state.currentKw.sundayISO;
            }

            let params = new URLSearchParams(queryParams);
            addSessionTokenToRequestHeader();
            let response = await fetch(config.API_HOST_URL + "/courses?"+params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let json = await response.json();

            json.data = json.data.reverse();

            for (let i=0;i< json.data.length; i++) {
                json.data[i].worksheetsArray = [];
                Object.keys(json.data[i].worksheets).forEach(function(traineeId,index) {
                    let worksheetArray = json.data[i].worksheets[traineeId];
                    json.data[i].worksheetsArray = json.data[i].worksheetsArray.concat(worksheetArray);
                });
            }

            this.setState({
                isFetching: false,
                reload:false,
                courses : json.data,
                pagesTotel :json.pages_total,
                numTotal: json.num_total
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                courses : [],
                worksheets: []
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    render() {
        return (
            <div className={"MyCoursesListPage"}>
                {this.state.isFetching? (
                    <PageContent>
                        <>
                            <KWFilter
                                weekNumber={this.state.currentKw.weekNumber}
                                year={this.state.currentKw.year}
                                dateTimespan={this.state.currentKw.dateTimespan}
                                onChange={(weekday,year) => {
                                    let kw = DateTimeUtil.getKW(weekday,year);
                                    this.state.currentKw = kw;
                                    this.reload();
                                }}
                                style={{marginBottom:'0em'}}
                            />
                            <LoadingScreen />
                        </>

                    </PageContent>
                ):(
                    <PageContent>
                        <KWFilter
                            key={this.state.currentKw.weekNumber}
                            location={this.location}
                            weekNumber={this.state.currentKw.weekNumber}
                            year={this.state.currentKw.year}
                            dateTimespan={this.state.currentKw.dateTimespan}
                            onChange={(weekday,year) => {
                                let kw = DateTimeUtil.getKW(weekday,year);
                                this.state.currentKw = kw;
                                this.reload();
                            }}
                            style={{marginBottom:'0em'}}
                        />
                        {this.state.courses.length == 0?
                            <CenteredMessage>
                                <FontAwesomeIcon icon={faSearchMinus} />
                                Keine Kurse
                            </CenteredMessage>
                            :<>
                                <CoursesListAccordions courses={this.state.courses} />
                            </>
                        }
                    </PageContent>
                )}
            </div>
        );
    }
}


export default CoursesListPage;
import React, { Component } from 'react';
import {Accordion, Alert, Button, Card, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackwardStep, faCaretDown, faCaretUp, faClock, faDiagramProject, faListNumeric, faTimes,
    faTools, faUniversity, faUser
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./input/ActionButton";
import StatusBadge from "./badges/StatusBadge";
import './ProjectTaskDescription.css';
import UserController from "../controller/UserController";
import _ from "lodash";
import he from "he";
import WorksheetUtil from "../util/WorksheetUtil";



class ProjectTaskDescription extends Component {

    constructor(props) {
        super(props);
        this.topicId = props.topicId;
        this.worksheet = props.worksheet;
        this.trainee = props.trainee;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.showActionButtons = props.showActionButtons!==undefined?props.showActionButtons:true;
        this.state = {
            openSections: {},
        };
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
    }

    findPhaseById(phaseId) {
        return WorksheetUtil.finaPhaseNameByPhaseId(this.worksheet, phaseId);
    }


    render() {
        return (
            <div className={"ProjectTaskDescription"}>
                <div className={"taskDescription"}>
                    <Alert key={'warning'} variant={'warning'} style={{marginBottom:0}}>
                        <Accordion className={"sectionAccordion"} activeKey={this.state.openSections['auftrag_section']?"-1":"section-accordion-"+'auftrag_section'}>
                            <Card>
                                <Card.Header className={"sectionAccordionCardheader"}>
                                    <Row>
                                        <Col xs={2}>
                                            <Button style={{marginRight:'1em'}} onClick={()=>{
                                                // eslint-disable-next-line no-restricted-globals
                                                history.back();
                                            }} variant="light"><FontAwesomeIcon icon={faBackwardStep} /></Button>
                                        </Col>
                                        <Col xs={8}>

                                            <div style={{textAlign:'center'}}>
                                                <h2 className={this.state.openSections['auftrag_section']?"closed":"open"}>
                                                    {_.isEmpty(this.worksheet.description.taskName)?"Projektauftrag "+this.worksheet.topic:he.decode(this.worksheet.description.taskName)}
                                                </h2>
                                            </div>
                                            <div style={{textAlign:'center'}}>
                                                {this.worksheet != null &&
                                                    <h5>
                                                        <span><small><FontAwesomeIcon icon={faClock} /> {this.findPhaseById(this.worksheet.phase)}</small></span>
                                                    </h5>
                                                }
                                            </div>


                                        </Col>
                                        <Col xs={2}>
                                            <div align={"right"} style={{paddingRight:'1em'}}>
                                                <ActionButton handler={() => {this.toggleSection('auftrag_section')}}>
                                                    {
                                                        this.state.openSections['auftrag_section']?
                                                            <FontAwesomeIcon icon={faCaretDown} />:
                                                            <FontAwesomeIcon icon={faCaretUp} />
                                                    }
                                                </ActionButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey={"section-accordion-"+'auftrag_section'}>
                                    <Card.Body className={"sectionAccordionCardBody"}>
                                        {!_.isEmpty(this.worksheet.description.taskDescription) &&
                                            <div style={{textAlign:'center'}}>
                                                <div style={{marginBottom:'1em'}}>
                                                    <span>{he.decode(this.worksheet.description.taskDescription)}</span>
                                                </div>
                                            </div>
                                        }
                                        <div style={{textAlign:'center'}}>
                                            <div className={"workingSheetCurriculumDetailsWrapper"}>
                                                <table className={"workingSheetCurriculumDetails"}>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faUser} /> Auszubildender</td>
                                                        <td><span>{_.isEmpty(this.trainee)?"Keine Angabe":he.decode(this.trainee.name) +", "+he.decode(this.trainee.firstname)+" (Gruppe: "+this.trainee.groupName+", TNr: "+this.trainee.externalId+")"}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faTools} /> Beruf</td>
                                                        <td><span>{_.isEmpty(this.trainee.professionName)?"Keine Angabe":he.decode(this.trainee.professionName)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faUniversity} /> Rahmenlehrplannr</td>
                                                        <td><span>{_.isEmpty(this.worksheet.description.curriculumId)?"Keine Angabe":he.decode(this.worksheet.description.curriculumId)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faListNumeric} /> Lernfeldnummer</td>
                                                        <td><span>{_.isEmpty(this.worksheet.description.learningFieldId)?"Keine Angabe":he.decode(this.worksheet.description.learningFieldId)}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Alert>
                </div>
            </div>
        )
    }
}


export default ProjectTaskDescription;
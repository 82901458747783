import React, { Component } from 'react';
import {Accordion, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
     faCaretDown, faCaretUp
} from "@fortawesome/free-solid-svg-icons";
import '../components/WorksheetViewComponent.css';
import ComponentBlock from "./ComponentBlock";
import ActionButton from "./input/ActionButton";
import ProjectTaskDescription from "./ProjectTaskDescription";
import _ from "lodash";
import UserController from "../controller/UserController";



class WorksheetViewComponent extends Component {

    constructor(props) {
        super(props);
        this.readOnlyMode = props.readOnlyMode;
        this.instructorMode = props.instructorMode;
        this.showEditorView = props.showEditorView;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.onCommentsUpdated = props.onCommentsUpdated;
        this.topicId = props.topicId;
        this.trainee = props.trainee;
        this.isTraineeUser =  UserController.getUser().type === 'trainee';

        this.state = {
            openSections: {},
            worksheet: props.worksheet,
            sections: props.sections
        };

        this._onComponentUpdate = this._onComponentUpdate.bind(this);
        this.onComponentUpdate = props.onComponentUpdate!=null?props.onComponentUpdate:this._onComponentUpdate;

    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
    }


    _onComponentUpdate(component){

        var sectionIndex = null;
        var componentIndex = null;
        this.state.sections.map((section, secIndex) => {
            section.components.map( (comp, compIndex) => {
                if(comp.id == component.id){
                    sectionIndex = secIndex;
                    componentIndex = compIndex;
                }
            });
        });
        let sections = this.state.sections;
        sections[sectionIndex].components[componentIndex] = component;
        this.setState({
            sections:sections
        });
    }

    render() {
        return (
            <>
                <div className="worksheetView">

                    <Row>
                        <Col xs={12} sm={12} lg={12}>
                            <div className={"my-worksheet"} style={{marginRight:'0.5em',marginLeft:'0.5em'}}>
                                <div className={"sections"}>
                                    {this.state.sections.map((section, sectionIndex) => {
                                        if(this.state.worksheet != null){

                                            const selectedPhase = ( _.find(section.phase, {pid:this.state.worksheet.phase}) );
                                            //console.table({"section.title":section.title, "Visibility": selectedPhase?.visibility, "Active": selectedPhase?.active});
                                            // hiddenbasedOnPhase shall override the writeModeBasedOnPhase, as it is set for the section
                                            let hiddenbasedOnPhase  = false;
                                            // writeModeBasedOnPhase shall to be set for each component
                                            let writeModeBasedOnPhase = true;

                                            switch(selectedPhase?.visibility){
                                                case 'S': // ReadWrite
                                                    hiddenbasedOnPhase = false;
                                                    writeModeBasedOnPhase = true;
                                                    break;
                                                case 'L': // ReadOnly
                                                    hiddenbasedOnPhase = false;
                                                    writeModeBasedOnPhase = false;
                                                    break;
                                                case 'H': // Hidden
                                                    hiddenbasedOnPhase = true;
                                                    writeModeBasedOnPhase = false;
                                                    break;
                                                default: // ReadWrite
                                                    hiddenbasedOnPhase = false;
                                                    writeModeBasedOnPhase = true;
                                                    break;
                                            }

                                            // Nagate the effect of the writeModeBasedOnPhase to the components if User is not a trainee
                                            const hiddenbasedOnPhaseForUser = this.isTraineeUser ? hiddenbasedOnPhase : false;
                                            section.hiddenBasedOnPhase = selectedPhase?.active === true ? hiddenbasedOnPhaseForUser: false;
                                            const writeModeBasedOnPhaseForUser = this.isTraineeUser ? writeModeBasedOnPhase : true;
                                            //console.table({"Section ":section.title ,"hiddenbasedOnPhaseForUser": hiddenbasedOnPhaseForUser, "writeModeBasedOnPhaseForUser":writeModeBasedOnPhaseForUser});
                                            // Extend the components with the writeModeBasedOnPhase
                                            section.components.map(comp => _.extend(comp, {
                                                writeModeBasedOnPhase : selectedPhase?.active === true ? writeModeBasedOnPhaseForUser : true
                                            }));
                                        }else{
                                            section.hiddenBasedOnPhase = false;
                                            section.components.map(comp => _.extend(comp, {
                                                writeModeBasedOnPhase : false
                                            }));
                                        }

                                        if(!section.hiddenBasedOnPhase) {
                                            return (
                                                <div key={sectionIndex} className={"section-wrapper"}>
                                                    <div className={"section-header"}>
                                                        <Row>
                                                            <Col xs={10}>
                                                                <h2 className={this.state.openSections[section.id] ? "closed" : "open"}>{section.title}</h2>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div align={"right"} style={{paddingRight: '1em'}}>
                                                                    <ActionButton handler={() => {
                                                                        this.toggleSection(section.id)
                                                                    }}>
                                                                        {
                                                                            this.state.openSections[section.id] ?
                                                                                <FontAwesomeIcon icon={faCaretDown}/> :
                                                                                <FontAwesomeIcon icon={faCaretUp}/>
                                                                        }
                                                                    </ActionButton>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className={"section"} key={sectionIndex}>
                                                        {!section.hide &&
                                                            <Accordion className={"sectionAccordion"}
                                                                       activeKey={this.state.openSections[section.id] ? "-1" : "section-accordion-" + section.id}>
                                                                <Card>
                                                                    <Accordion.Collapse
                                                                        eventKey={"section-accordion-" + section.id}>
                                                                        <Card.Body
                                                                            className={"sectionAccordionCardBody"}>
                                                                            <div className={"components"}>
                                                                                <Row>
                                                                                    {section.components.map((component, index) => {
                                                                                        console.table({
                                                                                            "Section": section.title,
                                                                                            "Component": component.title,
                                                                                            "should be": component.writeModeBasedOnPhase ? "S" : "L"
                                                                                        });
                                                                                        return (
                                                                                            <Col key={index}
                                                                                                 xs={component.config.size ? component.config.size : 12}>
                                                                                                {!component.hide &&
                                                                                                    <ComponentBlock
                                                                                                        key={component.id}
                                                                                                        onRemoveComponentClicked={() => {
                                                                                                        }}
                                                                                                        component={component}
                                                                                                        onComponentUpdate={this.onComponentUpdate}
                                                                                                        componentIndex={index}
                                                                                                        readOnlyMode={this.readOnlyMode}
                                                                                                        instructorMode={this.instructorMode}
                                                                                                        showEditorView={this.showEditorView}
                                                                                                        onCommentsUpdated={this.onCommentsUpdated}
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })}
                                                                                </Row>
                                                                                <Row>
                                                                                    {section.components.length === 0 &&
                                                                                        <Col xs={12}>
                                                                                            <div className={"no-components"} style={{padding:'1em', textAlign:"center"}}>
                                                                                                <p>Keine Komponenten in diesem Abschnitt</p>
                                                                                            </div>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </Accordion>
                                                        }
                                                    </div>
                                                </div>

                                            );
                                        }else{
                                            //console.log("Section was Hidden based on Phase for trainee");
                                        }
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </>
        )
    }
}


export default WorksheetViewComponent;
import React, { Component } from 'react';
import {Accordion, Badge, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarCheck,
    faCaretDown,
    faCaretUp,
    faDiagramProject,
    faHourglassHalf, faSheetPlastic,
    faStopwatch, faSync,
    faUsers, faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import '../components/CoursesListAccordions.css';
import ActionButton from "./input/ActionButton";
import TopicBadges from "./badges/TopicBadges";
import WorksheetBadges from "./badges/WorksheetBadges";
import TraineeBadges from "./badges/TraineeBadges";
import {Link} from "react-router-dom";
import DateTimeUtil from "../util/DateTimeUtil";
import WorksheetListStateBadges from "./badges/WorksheetListStateBadges";
import InstructorBadges from "./badges/InstructorBadges";
import Progress from "./progressBar";
import he from "he";





class CoursesListAccordions extends Component {

    constructor(props) {
        super(props);

        let openAccordions  = {};
        this.onSyncButtonClicked = props.onSyncButtonClicked;

        if(localStorage.getItem('CoursesListAccordions_openAccordions') == null){
            props.courses.map((course, idx) => {
                var id = "id" + course._id;
                let courseState = this.getCourseTimeState(course.dtStart, course.dtEnd);
                if (courseState == 'RUNNING') {
                    openAccordions[id] = id;
                }
            });
        }else{
            openAccordions = JSON.parse(localStorage.getItem('CoursesListAccordions_openAccordions'));
        }

        this.courseWorksheetsMap = {};

        this.state = {
            openAccordions: openAccordions,
            courses : props.courses,
        };
        localStorage.setItem('CoursesListAccordions_openAccordions',JSON.stringify(openAccordions));
    }

    toggleAccordion(id){
        if (this.state.openAccordions.hasOwnProperty(id)) {
            delete this.state.openAccordions[id];
        }else{
            this.state.openAccordions[id] = true;
        }
        let openAccordions = this.state.openAccordions;
        this.setState({
            openAccordions
        });
        localStorage.setItem('CoursesListAccordions_openAccordions',JSON.stringify(openAccordions));
    }


    renderStartDateInfo(startDateTime){
        let start = Date.parse(startDateTime);
        let daysUntilStart = DateTimeUtil.getDaysBetween(new Date(),new Date(start))
        return (
            <div>
                <span>{daysUntilStart>=0?"in "+daysUntilStart+" tagen":"beendet"}</span>
            </div>
        )
    }


    getCourseTimeState(startDateTime,endDateTime){
        let start = Date.parse(startDateTime);
        let end = Date.parse(endDateTime);
        let daysUntilStart = DateTimeUtil.getDaysBetween(new Date(),new Date(start))
        let daysUntilEnd = DateTimeUtil.getDaysBetween(new Date(),new Date(end));
        if(daysUntilStart <= 0 && daysUntilEnd >= 0){
            return 'RUNNING';
        }else if(daysUntilStart <= 0 && daysUntilEnd <= 0){
            return 'FINISHED';
        }else{
            return 'COMMING';
        }
    }

    renderCourseTimeState(startDateTime,endDateTime){
        let state = this.getCourseTimeState(startDateTime,endDateTime);
        let daysUntilStart = DateTimeUtil.getDaysBetween(new Date(),new Date(Date.parse(startDateTime)))

        switch (state) {
            case 'RUNNING':
                return (
                    <Badge className={"running_course"}>
                        <span><FontAwesomeIcon icon={faStopwatch} /> Läuft</span>
                    </Badge>
                );
            case 'COMMING':
                return (
                    <Badge className={"comming_course"}>
                        <span><FontAwesomeIcon icon={faHourglassHalf} /> in {daysUntilStart} Tagen</span>
                    </Badge>
                );
            case 'FINISHED':
                return (
                    <Badge className={"finished_course"}>
                        <span><FontAwesomeIcon icon={faCalendarCheck} /></span>
                    </Badge>
                );
            default:
                throw new Error('unknown state');
        }
    }


    courseIsFinished(endDateTime){
        let end = Date.parse(endDateTime);
        let daysUntilEnd = DateTimeUtil.getDaysBetween(new Date(),new Date(end));
        if(daysUntilEnd >= 0){
            return false
        }else{
            return true;
        }
    }


    render() {
        let self = this;
        return (
            <div className={"courseListAccordions"}>
                <Row>
                {this.state.courses.map((course, sectionIndex) => {
                    var id = "id" + course._id;
                    return (
                        <Col style={{marginBottom:'1em'}} xs={12} md={12} lg={12} xl={6}>
                                <Accordion className={"pradleAccordion"} activeKey={this.state.openAccordions[id]?id:'-1'}>
                                    <Card>
                                        <Card.Header>
                                            <div className={"cardHeaderWrapper"}>
                                                <Row onClick={()=> {
                                                    window.location.href = '/courses/'+course._id;
                                                }}>
                                                <Col xs={10}>
                                                    <Row>
                                                        <Col xs={12} sm={9} lg={8}>
                                                            <h2 style={{marginBottom:'0.2em'}} className={this.state.openAccordions[id]?"closed":"open"}>{course.name}</h2>
                                                        </Col>
                                                        <Col xs={12} sm={3} lg={4} style={{justifyContent:'left',alignItems:'center'}}>
                                                            <TraineeBadges smallSize={true} limit={0} trainees={course.trainees} />
                                                            <TopicBadges smallSize={true} topics={course.topics} limit={0} />
                                                            <WorksheetBadges smallSize={true} worksheets={course.worksheetsArray} limit={0} />
                                                            <InstructorBadges smallSize={true} instructors={course.instructors} limit={0} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                            <span><small>{DateTimeUtil.getHumanReadableDate(course.dtStart)} - {DateTimeUtil.getHumanReadableDate(course.dtEnd)}</small></span>
                                                            <span>{this.renderCourseTimeState(course.dtStart,course.dtEnd)}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={2}>
                                                    <div onClick={(e)=> {
                                                        e.stopPropagation(); //prevent parent click event
                                                    }} align={"right"} style={{paddingRight:'1em'}}>
                                                        <ActionButton handler={() => {this.toggleAccordion(id)}}>
                                                            {
                                                                this.state.openAccordions[id]?
                                                                    <FontAwesomeIcon icon={faCaretUp} />:
                                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                            }
                                                        </ActionButton>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card.Header>
                                        <Progress fontSize={"0.2em"} noText={true} bgColor={"#021a91"} worksheets={course.worksheetsArray}></Progress>
                                        <Accordion.Collapse eventKey={id}>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={1}><FontAwesomeIcon icon={faUserTie}/></Col>
                                                    <Col xs={11}>
                                                        {course.instructors.length == 0 &&
                                                            <span className={"novalue"}>Keine Ausbilder</span>
                                                        }
                                                        {course.instructors.map((instructor, sectionIndex) => {
                                                            return (<Link to={"/instructors/"+instructor._id}>
                                                                <span className={"instructor"}>{he.decode(instructor.firstname+" "+instructor.name)}</span>
                                                            </Link>)
                                                        })}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={1}><FontAwesomeIcon icon={faSheetPlastic}/></Col>
                                                    <Col xs={11}>
                                                        {course.worksheetsArray.length == 0 &&
                                                            <span className={"novalue"}>Keine Arbeitsblätter</span>
                                                        }
                                                        {course.worksheetsArray.length > 0 &&
                                                            <WorksheetListStateBadges worksheets={course.worksheetsArray} />
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={1}><FontAwesomeIcon icon={faDiagramProject}/></Col>
                                                    <Col xs={11}>
                                                        {course.topics.length == 0 &&
                                                            <span className={"novalue"}>Keine Projekte</span>
                                                        }

                                                        <Link to={'#'}><span onClick={() => this.onSyncButtonClicked(course._id)} className={"topic"}>
                                        <FontAwesomeIcon icon={faSync} />
                                                        </span></Link>

                                                        {course.topics.map((topic, sectionIndex) => {
                                                            return (<Link to={"/courses/"+course._id+"/"+topic._id}>
                                                                <span className={"topic"}>{topic._id}</span>
                                                            </Link>)
                                                        })}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={1}><FontAwesomeIcon icon={faUsers}/></Col>
                                                    <Col xs={11}>
                                                        {course.trainees.length == 0 &&
                                                            <span className={"novalue"}>Keine Auszubildenden</span>
                                                        }
                                                        {course.trainees.map((trainee, sectionIndex) => {
                                                            return (<Link to={"/trainees/"+trainee._id}>
                                                                <span className={"trainee"}>{he.decode(trainee.firstname+" "+trainee.name)}</span>
                                                            </Link>)
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                        </Col>
                    );
                })}
                </Row>
            </div>
        )
    }
}


export default CoursesListAccordions;
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import UserController from "../controller/UserController";
import {Col, Dropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCalendar, faCube, faCubes, faDiagramProject, faProjectDiagram,
    faSheetPlastic, faSignOut, faSquare, faSquareArrowUpRight, faTools, faUser, faUserAstronaut,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import './UserMenu.css';
import logo from "../assets/bauabc_logo.PNG";
import APIController from "../controller/APIController";


class UserMenu extends Component {

    constructor(props) {
        super(props);


        this.state = {
            fetching:true
        }

    }
    _getStammgewerkNummerByProfessionId(professionId){
        if(professionId === undefined || professionId.toString().length !== 4){
            return null;
        }
        return professionId.toString()[1]+professionId.toString()[2];
    }
    async componentDidMount() {
        let allTopics = [];
        if(UserController.getUser().type == 'instructor'){
            allTopics = await APIController.getAllTopics();
            allTopics = allTopics.filter(t=>!t._id.endsWith("1"));
        }
        this.setState({
            fetching:false,
            user: UserController.getUser(),
            currentPathName: this.props.currentPathName,
            allTopics :allTopics
        });
    }
    render() {
        let user = UserController.getUser();

        return (
            <div className={"user-menu"}>
                <Row>
                    <Col xs={10} sm={10} md={10} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                        <img src={logo} style={{height: "20px",marginRight:'0.5em'}} className="App-logo" alt="logo"/>

                        {UserController.getUser().type == 'instructor' &&
                        <Link className={this.state.currentPathName === UserController.getMyCoursesUrl()?'active':''} to={UserController.getMyCoursesUrl()}>
                            <span><FontAwesomeIcon icon={faCalendar} /> Kurse</span>
                        </Link>
                        }
                        {UserController.getUser().type == 'trainee' &&
                        <Link className={this.state.currentPathName === UserController.getMyWorksheetUrl()? 'active':''} to={UserController.getMyWorksheetUrl()}>
                            <span><FontAwesomeIcon icon={faSheetPlastic} /> Arbeitsblätter</span>
                        </Link>
                        }


                        {!this.state.fetching && UserController.getUser().type == 'instructor' &&
                            <>
                                <Dropdown  className={(this.state.currentPathName.startsWith('/topics'))?'active':''}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <span><FontAwesomeIcon icon={faSheetPlastic} /> Arbeitsblätter</span>
                                    </Dropdown.Toggle>
                                    <div>
                                        <Dropdown.Menu style={{height:'15em',overflowY:'scroll'}}>
                                            <Dropdown.Item onClickCapture={() =>  {window.location = '/topics'}}>
                                                <span><FontAwesomeIcon icon={faSheetPlastic}/> Alle Arbeitsblätter</span>
                                            </Dropdown.Item>
                                            {this.state.allTopics.map((topicGroupedByProfession,idx)=> {

                                                let stammdatengewerk = this._getStammgewerkNummerByProfessionId(topicGroupedByProfession._id);
                                                let url = "";
                                                if(stammdatengewerk != null){
                                                    url = '/topics/'+topicGroupedByProfession._id+"/*-"+stammdatengewerk+"-*"
                                                }else{
                                                    url = '/topics/'+topicGroupedByProfession._id
                                                }

                                                return(
                                                    <Dropdown.Item onClickCapture={() =>  {window.location = url;}}>
                                                        <span><FontAwesomeIcon icon={faTools}/>({topicGroupedByProfession._id}) {topicGroupedByProfession.professionName}</span>
                                                    </Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </div>
                                </Dropdown>

                                <Dropdown  className={(this.state.currentPathName.startsWith('/templates'))?'active':''}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <span><FontAwesomeIcon icon={faCubes} /> Templates</span>
                                    </Dropdown.Toggle>
                                    <div>
                                        <Dropdown.Menu style={{height:'15em',overflowY:'scroll'}}>
                                            <Dropdown.Item onClickCapture={() =>  {window.location = '/templates'}}>
                                                <span><FontAwesomeIcon icon={faCubes}/> Alle Templates</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClickCapture={() =>  {window.location = '/templates/-1'}}>
                                                <span><FontAwesomeIcon icon={faCube}/> Gewerksübergreifend</span>
                                            </Dropdown.Item>
                                            {this.state.allTopics.map((topicGroupedByProfession,idx)=> {
                                                return(
                                                    <Dropdown.Item onClickCapture={() =>  {window.location = '/templates/'+topicGroupedByProfession._id}}>
                                                        <span><FontAwesomeIcon icon={faTools}/>({topicGroupedByProfession._id}) {topicGroupedByProfession.professionName}</span>
                                                    </Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </div>
                                </Dropdown>
                            </>

                        }
                        {this.state.fetching &&
                            <Link to={'/topics'}>
                                <span><FontAwesomeIcon icon={faSheetPlastic} /> Arbeitsblätter</span>
                            </Link>
                        }

                    </Col>
                    <Col xs={2} sm={2} md={2} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                        <Dropdown className={this.state.currentPathName === UserController.getMyProfileUrl()?'active':''}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                         <span className={'userType'}>
                            {UserController.getUser().type === 'trainee' &&
                                <FontAwesomeIcon icon={faUser} />
                            }
                             {UserController.getUser().type === 'instructor' &&
                                 <FontAwesomeIcon icon={faUserTie} />
                             }
                             {UserController.getUser().type === 'admin' &&
                                 <FontAwesomeIcon icon={faUserAstronaut} />
                             }
                             <span className={"menuUserLoginName"} style={{marginLeft:'0.5em'}}>{UserController.getUser().email}</span>
                         </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClickCapture={() =>  {
                                    window.location = UserController.getMyProfileUrl();
                                }}>
                                    <span>Mein Profil</span>
                                </Dropdown.Item>
                                {UserController.getUser().type === 'trainee' &&
                                    <>

                                        <Dropdown.Item onClickCapture={() =>  {
                                            window.location = UserController.getMyProfileUrl() + "/update";
                                        }}>
                                            <span>Meine Profildaten aktualisieren</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClickCapture={() =>  {
                                            window.location = UserController.getMyProfileUrl() + "/update-password";
                                        }}>
                                            <span>Kennwort ändern</span>
                                        </Dropdown.Item>

                                    </>
                                }
                                <Dropdown.Divider />
                                <Dropdown.Item onClickCapture={() =>  UserController.logout()}>
                                    <span><FontAwesomeIcon icon={faSignOut}/> Ausloggen</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default UserMenu;
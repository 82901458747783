import React, { Component } from 'react';
import {Accordion, Alert,  Card, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDiagramProject, faListNumeric,
    faTools, faUniversity, faUser
} from "@fortawesome/free-solid-svg-icons";
import StatusBadge from "./badges/StatusBadge";
import './ProjectTaskDescriptionPrintView.css';
import _ from "lodash";
import he from "he";



class ProjectTaskDescriptionPrintView extends Component {

    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.trainee = props.trainee;
    }

    render() {
        return (
            <div className={"ProjectTaskDescription"}>
                <h5>
                    <span className={'topic'} style={{marginRight:'1em'}}><FontAwesomeIcon icon={faDiagramProject} /> {this.worksheet.topic}</span>
                    {this.worksheet != null &&
                    <span><StatusBadge
                        onWorksheetUpdated = {() => {}}
                        changeable={false} worksheet={this.worksheet}/></span>
                    }
                </h5>

                <div className={"taskDescription"}>
                    <Alert key={'warning'} variant={'warning'} style={{marginBottom:0}}>
                        <Accordion className={"sectionAccordion"} activeKey={"section-accordion-auftrag_section"}>
                            <Card>
                                <Card.Header className={"sectionAccordionCardheader"}>
                                    <Row>
                                        <Col xs={10}>
                                            <h2 className={"open"}>{
                                                _.isEmpty(this.worksheet.description.taskName)?"Projektauftrag "+this.worksheet.topic:he.decode(this.worksheet.description.taskName)}</h2>
                                        </Col>
                                        <Col xs={2}>
                                            <div align={"right"} style={{paddingRight:'1em'}}>

                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey={"section-accordion-auftrag_section"}>
                                    <Card.Body className={"sectionAccordionCardBody"}>
                                        {!_.isEmpty(this.worksheet.description.taskDescription) &&
                                            <div style={{marginBottom:'1em'}}>
                                                <span>{he.decode(this.worksheet.description.taskDescription)}</span>
                                            </div>
                                        }
                                        <div className={"workingSheetCurriculumDetailsWrapper"}>
                                            <table className={"workingSheetCurriculumDetails"}>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faUser} /> Auszubildender</td>
                                                    <td><span>{_.isEmpty(this.trainee)?"Keine Angabe":he.decode(this.trainee.name) +", "+he.decode(this.trainee.firstname)+" (Gruppe: "+this.trainee.groupName+", TNr: "+this.trainee.externalId+")"}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faTools} /> Beruf</td>
                                                    <td><span>{_.isEmpty(this.trainee.professionName)?"Keine Angabe":he.decode(this.trainee.professionName)}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faUniversity} /> Rahmenlehrplannr</td>
                                                    <td><span>{_.isEmpty(this.worksheet.description.curriculumId)?"Keine Angabe":he.decode(this.worksheet.description.curriculumId)}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faListNumeric} /> Lernfeldnummer</td>
                                                    <td><span>{_.isEmpty(this.worksheet.description.learningFieldId)?"Keine Angabe":he.decode(this.worksheet.description.learningFieldId)}</span></td>
                                                </tr>
                                            </table>
                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Alert>
                </div>
            </div>
        )
    }
}


export default ProjectTaskDescriptionPrintView;
import React, {Component} from "react";

import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import APIController from "../../controller/APIController";
import {Col, Container, ProgressBar, Row} from "react-bootstrap";
import './TopicsListPageForInstructor.css'
import he from 'he'
import {
    faCheck,
    faCheckSquare, faInfo, faInfoCircle,
    faSheetPlastic,
    faSquare,
    faSquareCheck,
    faUser,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

class TopicsListPageForInstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            topics: [],
            topicsGroupedByProfession: {}
        }
        this.location = props.location;
        this.params = props.params;
    }


    async componentDidMount() {
        await this.reload()
    }


    _getStammgewerkNummerByProfessionId(professionId){
        if(professionId === undefined || professionId.toString().length !== 4){
            return null;
        }
        return professionId.toString()[1]+professionId.toString()[2];
    }

    getReadableStammgewerkNummerByProfessionId(professionId){
        if(professionId === undefined){
            return "<span>*</span>";
        }

        if(professionId.toString().length !== 4){
            return (<span>
                {professionId}
            </span>);
        }
        return (<span style={{color:'#021260'}}>
            {professionId.toString()[0]}
            <font style={{color:'#000'}}>{professionId.toString()[1]+professionId.toString()[2]}</font>
            {professionId.toString()[3]}
        </span>);
    }

    getTopicFilterSettingsByProfessionId(professionId){
        let stammgewerkNummber = this._getStammgewerkNummerByProfessionId(professionId);
        if(stammgewerkNummber != null){
            return {
                'stammgewerkNummber': stammgewerkNummber,
                'topicFilter': '*-'+stammgewerkNummber+'-*',
                'url': '/topics/'+professionId+'/'+'*-'+stammgewerkNummber+'-*'
            }
        }else{
            return {
                'stammgewerkNummber': '*',
                'topicFilter': '*',
                'url': '/topics/'+professionId+'/*'
            }
        }

    }

    async reload(){
        try {
            this.setState({
                isFetching: true
            })
            let allTopics = await APIController.getAllTopics(true);
            allTopics = allTopics.filter(t=>!t._id.endsWith("1"));

            console.log(allTopics)

            var self = this;
            allTopics.forEach(function(part, index, theArray) {


                let topicForStats = allTopics[index];

                let topicFilterSettings = self.getTopicFilterSettingsByProfessionId(topicForStats._id);
                let str = topicFilterSettings.topicFilter;
                str = str.replace(/\*/g, '.*');
                let pattern = new RegExp("^" + str + "$");
                topicForStats.descriptions = topicForStats.descriptions.filter(desc => pattern.test(desc.topicId));


                allTopics[index].worksheetProgress = self.calcWorksheetProgress(topicForStats);
                allTopics[index].descriptionProgress = self.calcDescriptionProgress(topicForStats);
                allTopics[index].score = Math.round((allTopics[index].worksheetProgress.procentage + allTopics[index].descriptionProgress.procentage)/2.0)
            });

            allTopics.sort((a, b) => a.score - b.score).reverse();

            console.log(allTopics)
            this.setState({
                isFetching: false,
                allTopics: allTopics
            })
        } catch (e) {
            window.alert(e)
            this.setState({
                isFetching: false,
                allTopics: [],
            })
        }
    }





    calcWorksheetProgress(topicGroupedByProfession){
        let all = topicGroupedByProfession.descriptions.length;
        if(all==0){
            return {
                all: 0,
                unfinished: 0,
                finished: 0,
                procentage: 0.0,
                variant: 'warning',
                text:''
            }
        }
        let unfinished = topicGroupedByProfession.descriptions.filter(desc => desc.connectedTopics.length == 0).length;
        let finished = topicGroupedByProfession.descriptions.filter(desc => desc.connectedTopics.length > 0).length;
        let procentage = Math.round((finished / all) * 100.0);
        let variant = this.calcVariant(procentage)
        if (procentage <= 5){
            procentage = 5;
        }
        let text = procentage+"%";
        if(procentage <= 5){
            text = "0%";
        }
        return {
            all: all,
            unfinished: unfinished,
            finished: finished,
            procentage: procentage,
            variant: variant,
            text:text
        }
    }

    calcVariant(procentage){
        let variant = 'success';
        if(procentage <= 50){
            variant = "info";
        }
        if (procentage <= 25){
            variant = "danger";
        }
        return variant;
    }

    calcDescriptionProgress(topicGroupedByProfession){
        let all = topicGroupedByProfession.descriptions.length;

        if(all==0){
            return {
                all: 0,
                unfinished: 0,
                finished: 0,
                procentage: 0.0,
                variant: 'warning',
                text:''
            }
        }

        let unfinished = topicGroupedByProfession.descriptions.filter(desc =>
            _.isEmpty(desc.taskName)
            || _.isEmpty(desc.taskDescription)
            || _.isEmpty(desc.learningFieldId)
            || _.isEmpty(desc.curriculumId)
        ).length;

        let finished = topicGroupedByProfession.descriptions.filter(
            desc =>
                !_.isEmpty(desc.taskName)
                && !_.isEmpty(desc.taskDescription)
                && !_.isEmpty(desc.learningFieldId)
                && !_.isEmpty(desc.curriculumId)
        ).length;
        let procentage = Math.round((finished / all) * 100.0);
        let variant = 'success';
        if(procentage <= 50){
            variant = "info";
        }
        if (procentage <= 25){
            variant = "danger";
        }
        if (procentage <= 5){
            procentage = 5;
        }
        let text = procentage+"%";
        if(procentage <= 5){
            text = "0%";
        }
        return {
            all: all,
            unfinished: unfinished,
            finished: finished,
            procentage: procentage,
            variant: variant,
            text:text
        }
    }

    render() {
        var self = this;
        if (this.state.isFetching)
            return <LoadingScreen/>;

        return (
                <div className={"TopicsListPageForInstructor"}>
                    <PageContent>
                        <Container>
                            <Row>
                                {this.state.allTopics.map((topicGroupedByProfession,idx)=>{
                                    let progress = topicGroupedByProfession.worksheetProgress;
                                    let descriptionProgress = topicGroupedByProfession.descriptionProgress;

                                    let topicFilterSettings = self.getTopicFilterSettingsByProfessionId(topicGroupedByProfession._id);

                                    let professionNumberTitle = this.getReadableStammgewerkNummerByProfessionId(topicGroupedByProfession._id);

                                    return (
                                        <Col xs={12} sm={12} lg={6} xl={4}>
                                            <div className={"topicKachelOuter"}>
                                                <a href={topicFilterSettings.url} style={{textDecoration:'none'}}>
                                                    <div className={"topicKachel"} style={{minHeight:'10em'}}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <h3><span className={"topicId"}>{professionNumberTitle}<br /><span style={{fontSize:'0.8em'}}>{topicGroupedByProfession.professionName}</span></span> </h3>
                                                                <ProgressBar className={"myProgressBar"}
                                                                             style={{borderRadius: "unset",height:'2em',background:'#4444',fontSize:this.fontSize}} animated
                                                                             striped now={topicGroupedByProfession.score} variant={this.calcVariant(topicGroupedByProfession.score)}
                                                                             label={topicGroupedByProfession.score+"%"}/>
                                                                <div style={{padding:'0.5em'}}>
                                                                    <div><span style={{color:'#444'}}><FontAwesomeIcon icon={faSheetPlastic} /> {progress.finished} / {progress.all} Arbeitsblätter</span></div>
                                                                    {/*<div className={"topicStickerRight"}><span style={{fontWeight:'bold'}}>{JSON.stringify(topics.length)} Projekte</span></div>*/}
                                                                    <ProgressBar className={"myProgressBar"}
                                                                                 style={{borderRadius: "unset",height:'1em',background:'#4444',fontSize:this.fontSize}} animated
                                                                                 striped now={progress.procentage} variant={progress.variant}
                                                                                 label={progress.text}/>
                                                                </div>
                                                                <div style={{padding:'0.5em',paddingTop:0}}>
                                                                    <div><span style={{color:'#444'}}><FontAwesomeIcon icon={faInfoCircle} /> {descriptionProgress.finished} / {descriptionProgress.all} Aufträge</span></div>
                                                                    {/*<div className={"topicStickerRight"}><span style={{fontWeight:'bold'}}>{JSON.stringify(topics.length)} Projekte</span></div>*/}
                                                                    <ProgressBar className={"myProgressBar"}
                                                                                 style={{borderRadius: "unset",height:'1em',background:'#4444',fontSize:this.fontSize}} animated
                                                                                 striped now={descriptionProgress.procentage} variant={descriptionProgress.variant}
                                                                                 label={descriptionProgress.text}/>
                                                                </div>
                                                                {progress.unfinished > 0 &&
                                                                    <div>
                                                                        <div className={"topicSticker"} style={{background:'red'}}><span style={{fontWeight:'bold'}}><FontAwesomeIcon icon={faWarning} /> {progress.unfinished} Arbeistblätter fehlen</span></div>
                                                                        {/*<div className={"topicStickerRight"}><span style={{fontWeight:'bold'}}>{JSON.stringify(topics.length)} Projekte</span></div>*/}
                                                                    </div>
                                                                }
                                                                {descriptionProgress.unfinished > 0 &&
                                                                    <div style={{marginTop:'2.5em'}}>
                                                                        <div className={"topicSticker"} style={{background:'#dc3545'}}><span style={{fontWeight:'bold'}}><FontAwesomeIcon icon={faWarning} /> {descriptionProgress.unfinished} Aufträge fehlen</span></div>
                                                                        {/*<div className={"topicStickerRight"}><span style={{fontWeight:'bold'}}>{JSON.stringify(topics.length)} Projekte</span></div>*/}
                                                                    </div>
                                                                }
                                                                <div className={"coverImage"} style={{height:'8em',borderBottomRightRadius:'0.6em',backgroundSize:'contain',backgroundPosition:'right bottom',backgroundRepeat:'no-repeat',backgroundImage:"url('/topics/1.jpg')"}}></div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Container>
                    </PageContent>
                </div>
            )

    }
}

export default TopicsListPageForInstructor;
import React, {Component} from 'react';

import './InstructorBadge.css'
import {
    faBox, faCaretDown, faCaretUp,
    faCheck, faCircle,
    faCog, faComments, faDraftingCompass, faExclamationCircle, faFlag,
    faListCheck,
    faPencil,
    faPenClip, faQuestion, faQuestionCircle,
    faStamp,
    faUserCheck, faWarning
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import GenericBadge from "./GenericBadge";
import DateTimeUtil from "../../util/DateTimeUtil";
import UserController from "../../controller/UserController";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Draggable} from "react-beautiful-dnd";
import ActionButton from "../input/ActionButton";
import APIController from "../../controller/APIController";
import EvaluationFormInput from "../WorksheetEditorComponents/EvaluationFormInput";
import PagePopup from "../PagePopup";

class StatusBadge extends Component {

    static TYPES = {
        DRAFT_WORKING_SHEET : {
            state : 'DRAFT_WORKING_SHEET',
            text: 'Entwurf',
            color: 'white',
            bgColor: '#222',
            icon: faDraftingCompass
        },
        OPEN_WORKING_SHEET : {
            state : 'OPEN_WORKING_SHEET',
            text: UserController.getUser().type === "trainee" ? 'Noch offen' : "In Arbeit",
            color: 'white',
            bgColor: 'orange',
            icon: faCircle
        },
        SUBMITTED_WORKING_SHEET : {
            state : 'SUBMITTED_WORKING_SHEET',
            text: UserController.getUser().type === "trainee" ? 'Wird kontrolliert' : "Prüfen",
            color: '#f1f1f1',
            bgColor: '#021260',
            icon: faQuestionCircle
        },
        DISCARDED_WORKING_SHEET : {
            state : 'DISCARDED_WORKING_SHEET',
            text: 'Aussortiert',
            color:'white',
            bgColor: 'red',
            icon: faExclamationCircle
        },
        GRADED_WORKING_SHEET : {
            state : 'GRADED_WORKING_SHEET',
            text: 'Benotet',
            color: 'white',
            bgColor: '#003300',
            icon: faCheck
        }
    }


    constructor(props) {
        super(props);
        this.course = props.course;
        this.worksheet = props.worksheet;
        this.smallSize = props.smallSize;
        this.changeable = props.changeable?props.changeable:false;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.state = {
            status: this.getStatus(this.worksheet.state),
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            },
        }

    }


    handleEvaluateWorkingSheet() {
        var self = this;
        //delete self.worksheet.evaluation;//TEST
        self.setState({
                popupProps: {
                    title: <h2>Bitte Bewertung abgeben</h2>,
                    body: <EvaluationFormInput
                        worksheet={self.worksheet}
                        onFinishEvaluation={(updatedWorksheet) => {
                            self.setState({
                                status:  this.getStatus(updatedWorksheet.state),
                                worksheet: updatedWorksheet,
                                popupProps: {
                                    title: "",
                                    body: "",
                                    show: false,
                                    showFooter: false
                                }
                            });
                            self.onWorksheetUpdated(updatedWorksheet);
                        }} />,
                    show: true,
                    showFooter: false
                }
            }
        );
    }

    getStatus(worksheetStatus){
        switch (worksheetStatus){
            case "DRAFT_WORKING_SHEET":
                return StatusBadge.TYPES.DRAFT_WORKING_SHEET;
            case "OPEN_WORKING_SHEET":
                return StatusBadge.TYPES.OPEN_WORKING_SHEET;
            case "SUBMITTED_WORKING_SHEET":
                return StatusBadge.TYPES.SUBMITTED_WORKING_SHEET
            case "GRADED_WORKING_SHEET":
                return StatusBadge.TYPES.GRADED_WORKING_SHEET
            case "DISCARDED_WORKING_SHEET":
                return StatusBadge.TYPES.DISCARDED_WORKING_SHEET
        }
    }

    getOtherStatus(worksheetStatus){
        switch (worksheetStatus){
            case "DRAFT_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                    ];
            case "OPEN_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.DRAFT_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                ];
            case "SUBMITTED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.DRAFT_WORKING_SHEET,
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                ];
            case "GRADED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.DRAFT_WORKING_SHEET,
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET
                ];
            case "DISCARDED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DRAFT_WORKING_SHEET,
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                ];
        }
    }


    getRandomDate(){
        let date = this.worksheet.lastEditedTime;
        return DateTimeUtil.getHumanReadableDateTime(date);
    }


    change(selectedStatus){
        if(selectedStatus === ""+StatusBadge.TYPES.GRADED_WORKING_SHEET.state){
            this.handleEvaluateWorkingSheet();
        }else {
            let worksheet = this.worksheet;
            worksheet.state = selectedStatus;
            this.setState({
                status:  this.getStatus(selectedStatus),
                worksheet: worksheet
            });
            this.onWorksheetUpdated(worksheet);
        }
    }

    renderBadge(statusBadge,otherBadges){
        return (
            <>
                <PagePopup
                    className={"evaluationPopup"}
                    onClose={()=>this.setState({popupProps:{show:false}})}
                    key={this.state.popupProps.show}
                    {...this.state.popupProps}>
                </PagePopup>
                {!this.changeable &&
                <div style={{padding:'0em',marginLeft:'0.5em',display:'inline-block'}}>
                    <span style={{color:'white'}}>
                            <FontAwesomeIcon style={{color:statusBadge.color}}
                                             icon={statusBadge.icon}/> {this.smallSize?"":statusBadge.text}</span>
                </div>
                }
                {this.changeable &&
                <Dropdown style={{display:'inline-block'}}>
                    <Dropdown.Toggle variant="success" style={{background:statusBadge.bgColor}} id="dropdown-basic">
                        <span style={{color:'white'}}>
                            <FontAwesomeIcon style={{color:statusBadge.color}}
                                             icon={statusBadge.icon}/> {this.smallSize?"":statusBadge.text}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {otherBadges.map((status, index) => (
                            <Dropdown.Item onClickCapture={() => this.change(status.state)} key={index}>
                                 <span style={{color:'#222'}}>
                            <FontAwesomeIcon style={{color:'#222'}}
                                             icon={status.icon}/> {status.text}</span>
                            </Dropdown.Item>
                        ))}

                    </Dropdown.Menu>
                </Dropdown>
                }
            </>
        )
    }

    render() {
        return(
            this.renderBadge(this.state.status,this.getOtherStatus(this.state.status.state))
        )
    }
}
export default StatusBadge;
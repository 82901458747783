import React from 'react';
import {v4 as uuidv4} from "uuid";
import {Alert, Col, Row} from "react-bootstrap";
import NumberInputFormField from "../formFields/NumberInputFormField";
import InputComponent from "./InputComponent";
import ComponentHeader from "../text/ComponentHeader";
import ComponentFooter from "../text/ComponentFooter";
import _ from "lodash";

class TextareaInput extends InputComponent {
    constructor(props) {
        super(props);
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.uuid = uuidv4();
        this.state = {
            component: props.component
        };
        this.shouldBeDisabled = _.has(this.state.component,'writeModeBasedOnPhase')? !this.state.component.writeModeBasedOnPhase: false;
    }

    _handleChange(id,checked) {
        this.state.component.config[id] = checked;
        //this.updateComponent(this.component);
    };

    _handleDataChange(id,value) {
        this.state.component.data[id] = value.target.value;
        this.setState({
            component: this.state.component
        });
        this.updateComponent(this.component);
    };
    static create(){
        return {
                id: uuidv4(),
                type : "textInput",
                title: "Text-Eingabe",
                data : {
                    text: "",
                },
                config: {
                    rows: 5,
                    commentFunctionChecked : true,
                    evaluationChecked: true,
                    description: ""
                }
            }
    }

    // We need to spread the previous state and change the one we're targeting, so other data cannot be lost.


    renderEditorView(){
        return (
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <NumberInputFormField
                            title={"Anzahl an Zeilen"}
                            description={"Wie groß soll der Textbereich sein?"}
                            maxValue={30}
                            value={this.state.component.config.rows}
                            suffix={"Zeilen"}
                            onChange={(value) => this._handleChange('rows',value)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderInputView(){
        return (
            <div className={this.state.component.type}>
                <textarea value={this.state.component.data.text}
                          disabled={this.shouldBeDisabled}
                          onChange={(value) => this._handleDataChange('text',value)}
                          className={"form-control"} style={{width:'100%'}} rows={this.state.component.config.rows}>
                </textarea>
            </div>
        );
    }

    renderInstructorView(){
        return (
            <div className={this.state.component.type}>
                <div>
                    <Alert key={'info'} variant={'info'}>
                        <div style={{fontStyle:"italic"}}>{this.state.component.data.text}</div>
                    </Alert>
                </div>
            </div>
        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default TextareaInput;
import React, {Component} from 'react';


import config from "../../config";
import {Badge, Button, Col, Container, Row, Table} from "react-bootstrap";
import NumberInputFormField from "../../components/formFields/NumberInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './TopicsListPage.css'
import {
    faBox, faCalendarDay, faCalendarDays, faClock,
    faEdit, faEnvelope,
    faEye,
    faProjectDiagram, faSearchMinus, faStopwatch, faSync, faTrash, faUser, faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../includes/PageHeader";
import LoadingScreen from "../../includes/LoadingScreen";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import {Link} from "react-router-dom";
import DataTablePageHeader from "../../includes/DatatablePageHeader";
import CenteredMessage from "../../includes/CenteredMessage";
import PageContent from "../../includes/PageContent";
import CourseBadge from "../../components/badges/CourseBadge";
import TopicBadge from "../../components/badges/TopicBadge";
import CourseBadges from "../../components/badges/CourseBadges";
import TopicBadges from "../../components/badges/TopicBadges";
import EmailTextField from "../../components/simple/EmailTextField";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
class InstructorsListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            reload:true,
            page:1,
            pagesTotel:0,
            numTotal:0,
            topics : null,
            typeFilter:'alle'
        };
    }


    async reload(){
        try {
            this.setState({
                isFetching:true
            })

            let queryParams = {
                page: this.state.page,
            };
            if(this.state.typeFilter != null){
                queryParams["typeFilter"] = this.state.typeFilter;
            }


            let params = new URLSearchParams(queryParams);
            addSessionTokenToRequestHeader()
            let response = await fetch(config.API_HOST_URL + "/admin/instructors?"+params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            this.setState({
                isFetching: false,
                reload:false,
                instructors : json.data,
                pagesTotel :json.pages_total,
                numTotal: json.num_total
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                instructors : null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    render() {

        if(!this.state.isFetching && this.state.instructors == null){
            return <div>error</div>
        }


        return (
            <div>
                <DataTablePageHeader
                    title={"Ausbilder"}
                    fontAwesomeIcon={faUserTie}
                    key={this.state.isFetching}
                    isFetching={this.state.isFetching}
                    numTotal={this.state.numTotal}
                    pagesTotel={this.state.pagesTotel}
                    page={this.state.page}
                    onPageChange={(page)=>{
                        this.state.page = page;
                        this.reload()}}
                    selectionOptions={[
                        {name: "Alle", value: 'alle'},
                        {name: "Nur mit Kurse", value: 'onlyWithCourses'},
                        {name: "Keine Kurse", value: 'noCourses'},
                        {name: "Nur mit E-Mail", value: 'onlyWithEmail'},
                        {name: "Keine E-Mail", value: 'noEmail'},
                    ]}
                    selectedOptionValue={this.state.typeFilter}
                    onOptionValueSelected={(value)=>{
                        if(this.state.typeFilter != null){
                            this.state.page = 1;
                        }
                        this.state.typeFilter = value;
                        this.reload()}}
                >
                </DataTablePageHeader>

                {this.state.isFetching? (<LoadingScreen />):(
                    <>
                        <PageContent>
                            <div key={this.state.page}>
                                <div>
                                    {this.state.instructors.length == 0 &&
                                        <CenteredMessage>
                                            <FontAwesomeIcon icon={faSearchMinus} />
                                            Keine Ausbilder gefunden
                                        </CenteredMessage>
                                    }
                                    <Table className={"table-responsive styledTable"}>
                                        <tbody>
                                        {this.state.instructors.map( (instructor, idx) => {
                                            return (
                                                <tr key= {instructor.id}>
                                                    <td>
                                                        <Row>
                                                            <Col xs={12} lg={3} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                                <Link to={"/instructors/"+instructor.id}>
                                                                    <span className={'rowTitle'}style={{marginRight:'0.2em'}}><FontAwesomeIcon icon={faUserTie} /> {instructor.firstname}</span>
                                                                    <span className={'rowTitle'}>{instructor.name}</span>
                                                                </Link>
                                                            </Col>
                                                            <Col xs={12} lg={3} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                                <EmailTextField email={instructor.email} />
                                                            </Col>
                                                            <Col xs={12} lg={6}>
                                                                {instructor.gruppen !== undefined &&
                                                                    <CourseBadges courses={instructor.gruppen} />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </PageContent>
                    </>
                )}
            </div>
        );
    }
}


export default InstructorsListPage;
import React, {Component} from 'react';




import config from "../../config";
import './MyProfilePage.css'

import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import LoadingScreen from "../../includes/LoadingScreen";
import InlinePageHeader from "../../includes/InlinePageHeader";
import {
    faEnvelope,
    faExclamationTriangle,
    faHashtag,
    faUser,
    faHistory
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageContent from "../../includes/PageContent";
import { Button, Col, Row, Table} from "react-bootstrap";
import DateTimeUtil from "../../util/DateTimeUtil";
import APIController from "../../controller/APIController";
class MyProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            reload:true,
            profile : null,
            syncLog : null
        };
    }


    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            let queryParams = {
                id: this.state.id,
            };
            let params = new URLSearchParams(queryParams);
            addSessionTokenToRequestHeader();
            let response = await fetch(config.API_HOST_URL + "/users/me?"+params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const syncLogInfo = await APIController.getSyncLogs();

            const json = await response.json();
            this.setState({
                isFetching: false,
                reload:false,
                profile : json,
                syncLog: syncLogInfo
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                profile : null,
                syncLog: null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }

    render() {
        if(!this.state.isFetching && this.state.profile == null){
            return <div>{JSON.stringify(this.state)}</div>
        }
        return(
            <div className={"MyProfilePage"}>
                {this.state.isFetching? (<LoadingScreen />):(
                    <>
                        <PageContent>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5>E-Mail</h5>
                                        <div>
                                            {this.state.profile.email != ""?
                                                <span><FontAwesomeIcon icon={faEnvelope} /> {this.state.profile.email}</span>
                                                :
                                                <span style={{color:'red'}}><FontAwesomeIcon icon={faExclamationTriangle} /> Keine E-Mail Adresse</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5>Benutzertyp</h5>
                                        <div>
                                            <span><FontAwesomeIcon icon={faUser} /> {this.state.profile.type}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5>User-ID</h5>
                                        <div>
                                            <span><FontAwesomeIcon icon={faHashtag} /> {this.state.profile.id}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <div className={'profileBlock'} style={{padding:'1em'}}>
                                        {/*
                                        <Row>
                                            <Col xs={11} lg={10}>
                                                <h5><FontAwesomeIcon icon={faHistory}/> Last Sync</h5>
                                            </Col>
                                            <Col xs={1} lg={2}>
                                                <Button disabled={true} className={'btn'} variant="outline-secondary" onClick={console.log("started...")}>
                                                Sync
                                            </Button>
                                        </Col>
                                        </Row>
                                        */
                                        }
                                        <div>
                                            <Table>
                                            <thead>
                                                <tr>
                                                    <th>Schnittstelle</th>
                                                    <th>Ereignis</th>
                                                    <th>Läuft</th>
                                                    <th>Zuletzt beendet</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.syncLog.syncLog != null &&
                                                <tr>
                                                    <td>{this.state.syncLog.syncLog.lms}</td>
                                                    <td>{"Data Fetch"}</td>
                                                    <td>{DateTimeUtil.lessThanHoursAndMinutesAgoStatusText(this.state.syncLog.syncLog.timestamp)}</td>
                                                    <td>{DateTimeUtil.getHumanReadableDateTime(this.state.syncLog.syncLog.timestamp)}</td>
                                                </tr>
                                                }
                                                {this.state.syncLog.migrationsLog != null &&
                                                    <tr>
                                                        <td>{this.state.syncLog.migrationsLog.lms}</td>
                                                        <td>{"Migration"}</td>
                                                        <td>{DateTimeUtil.lessThanHoursAndMinutesAgoStatusText(this.state.syncLog.migrationsLog.end_timestamp)}</td>
                                                        <td>{DateTimeUtil.getHumanReadableDateTime(this.state.syncLog.migrationsLog.end_timestamp)}</td>
                                                    </tr>
                                                }
                                                {this.state.syncLog.worksheetSyncLog != null &&
                                                    <tr>
                                                        <td>{this.state.syncLog.worksheetSyncLog.lms}</td>
                                                        <td>{"Worksheet Sync"}</td>
                                                        <td>{DateTimeUtil.lessThanHoursAndMinutesAgoStatusText(this.state.syncLog.worksheetSyncLog.end_timestamp)}</td>
                                                        <td>{DateTimeUtil.getHumanReadableDateTime(this.state.syncLog.worksheetSyncLog.end_timestamp)}</td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </PageContent>
                    </>
                )}
            </div>
        )
    }
}


export default MyProfilePage;
import React from 'react';
import {v4 as uuidv4} from "uuid";
import ComponentHeader from "./ComponentHeader";
import TextComponent from "../input/TextComponent";

class HeadlineTextComponent extends TextComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
    }

    static create(){
        return {
                id: uuidv4(),
                type : "headline",
                description: "",
                title: "Kapitel / Überschrift",
                data : {},
                config: {}
            }
    }

    renderEditorView(){
        return (
            <div>
            </div>
        );
    }

    renderInputView(){
        return (
            <></>
        );
    }

    renderInstructorView(){
        return (
            <></>
        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default HeadlineTextComponent;
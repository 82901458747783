import React, {Component} from 'react';


import config from "../../config";
import {Badge, Button, Col, Container, Row, Table} from "react-bootstrap";
import NumberInputFormField from "../../components/formFields/NumberInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleTraineePage.css'
import {
    faBank,
    faBox,
    faBoxes,
    faCalendar,
    faDiagramProject,
    faEnvelope,
    faExclamationTriangle,
    faHandshake,
    faHashtag,
    faHourglassEnd, faLocationDot,
    faPerson,
    faPhone,
    faPhoneAlt,
    faProjectDiagram,
    faRoad,
    faSearchMinus,
    faSheetPlastic,
    faSignOut,
    faSync,
    faTools,
    faTreeCity,
    faUser,
    faUserTie,
    faVault,
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../includes/PageHeader";
import LoadingScreen from "../../includes/LoadingScreen";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import InlinePageHeader from "../../includes/InlinePageHeader";
import PageContent from "../../includes/PageContent";
import DateTimeUtil from "../../util/DateTimeUtil";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import CourseBadges from "../../components/badges/CourseBadges";
import TopicBadges from "../../components/badges/TopicBadges";
import CenteredMessage from "../../includes/CenteredMessage";
import UserController from "../../controller/UserController";
import he from "he";
import APIController from "../../controller/APIController";
import PradleAccordion from "../../components/PradleAccordion";
import SmartWorksheetActionButtons from "../../components/WorksheetEditorComponents/SmartWorksheetActionButtons";
import image1 from "../../assets/topic_images/1.jpg";
import StatusBadge from "../../components/badges/StatusBadge";
import TopicBadge from "../../components/badges/TopicBadge";
import _ from "lodash";
class SingleInstructorPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            reload:true,
            id:props.params.id,
            trainee : null,
            profile : null,
        };
    }



    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            const json = await APIController.getTraineeById(this.state.id);
            const profileResponse = await APIController.getTraineeProfile();
            json.connectedTopics = _.sortBy(json.connectedTopics, 'id').reverse();

            this.setState({
                isFetching: false,
                reload:false,
                trainee : json,
                profile : profileResponse,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                trainee : null,
                profile: null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    render() {

        if(!this.state.isFetching && this.state.trainee == null){
            return (
                    <>
                        <PageHeader title={"Auzubildenden Profil"} fontAwesomeIcon={faUser}/>
                        <PageContent>
                            <CenteredMessage>
                                <FontAwesomeIcon icon={faUser} />
                                Das Profil konnte nicht geladen werden
                            </CenteredMessage>
                        </PageContent>
                    </>
                )
        }

        return (
            <div>
                {this.state.isFetching? (<LoadingScreen />):(
                    <div className={"SingleTraineePage"}>
                        <PageContent>
                            <div>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faUser} /> Name</h5>
                                        <div>
                                            <span>{he.decode(this.state.trainee.firstname+" "+this.state.trainee.name)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Teinehmer-Nr.</h5>
                                        <div>
                                            {this.state.trainee.externalId}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Uniplus CRM-ID</h5>
                                        <div>
                                            {this.state.trainee._id}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faEnvelope} /> E-Mail</h5>
                                        <div>
                                            {this.state.trainee.email != ""?
                                                <span>{he.decode(this.state.trainee.email)}</span>
                                                :
                                                <span>Keine E-Mail Adresse</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faTools} /> Beruf</h5>
                                        <div>
                                            {this.state.trainee.professionId != ""?
                                                <span><FontAwesomeIcon icon={faTools} /> {this.state.trainee.professionId} | {he.decode(this.state.trainee.professionName)}</span>
                                                :
                                                <span style={{color:'red'}}><FontAwesomeIcon icon={faExclamationTriangle} /> Beruf vorhanden</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHourglassEnd} /> Gültig bis</h5>
                                        <div>
                                            {!_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>{DateTimeUtil.parseIsoDateOfYear(this.state.trainee.gueltigBis)}</span>
                                            }
                                            {_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>Keine Angabe</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faBoxes} /> Gruppen <small>({this.state.trainee.courses.length})</small></h5>
                                        <div>
                                            <CourseBadges courses={this.state.trainee.courses} limit={50}></CourseBadges>
                                        </div>
                                    </div>
                                </Col>


                            </Row>

                                <PradleAccordion title={<div><FontAwesomeIcon icon={faDiagramProject} /> Projekte</div>} keyId={"topics"} open={true}>
                                    <Row>
                                    {this.state.trainee.connectedTopics.map((connectedTopic,idx)=> {
                                        return(
                                            <Col xs={12} md={6} lg={4}>
                                                <h3 style={{marginBottom:'0.5em'}}>
                                                    <FontAwesomeIcon icon={faDiagramProject} /> {this.state.trainee.courses.find((obj) => obj._id === connectedTopic._id).name}</h3>
                                                {connectedTopic.topicIds.map((topicId,idx)=> {
                                                    return(
                                                        <Badge style={{marginRight:'0.2em',marginBottom:'0.5em',color:'#021260'}}
                                                               className={"topicBadge"} bg="light">
                                                            <FontAwesomeIcon icon={faDiagramProject}/> {topicId}
                                                        </Badge>)
                                                })}
                                                <div style={{marginBottom:'1em'}}></div>
                                            </Col>
                                        )
                                    })}
                                    </Row>
                                </PradleAccordion>

                            </div>
                            {/*
                            {(this.state.profile && this.state.profile.communicationData) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Mobile</h5>
                                                <div>
                                                    {this.state.profile.communicationData.mobile && (this.state.profile.communicationData.mobile)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhoneAlt}/> Phone</h5>
                                                <div>
                                                    {this.state.profile.communicationData.phone && (this.state.profile.communicationData.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.bankDetails) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faBank}/> Kreditinstitut</h5>
                                                <div>
                                                    {(this.state.profile.bankDetails.creditInstitution) && he.decode(this.state.profile.bankDetails.creditInstitution)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> IBAN</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.iban && (this.state.profile.bankDetails.iban)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> BIC</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.bic && (this.state.profile.bankDetails.bic)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faUserTie}/> Abweichender Kontoinhaber</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.differentAccountHolder && he.decode(this.state.profile.bankDetails.differentAccountHolder)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.legalGuardian) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} md={3} lg={3}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faHandshake}/> Anrede</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.salutation && (this.state.profile.legalGuardian.allSalutationOptions.filter((option) => option.key === this.state.profile.legalGuardian.salutation)[0].value)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={9} lg={9}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPerson}/> Name</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.firstname && this.state.profile.legalGuardian.name) && he.decode(this.state.profile.legalGuardian.firstname + " " + this.state.profile.legalGuardian.name)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Telefon</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faEnvelope}/> E-Mail</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.email && (this.state.profile.legalGuardian.email)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faRoad}/> Strasse</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.street && (this.state.profile.legalGuardian.street)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faLocationDot}/> PLZ</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.zip && this.state.profile.legalGuardian.zip}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={8} lg={8}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faTreeCity}/> Ort</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.city && he.decode(this.state.profile.legalGuardian.city)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            }
                            */}
                        </PageContent>
                    </div>
                )}
            </div>
        );
    }
}


export default SingleInstructorPage;
const config = {
    dev : {
        API_HOST_URL: "http://localhost:8080",
        MSAL_CLIENT_ID: "17be20df-a52f-4827-b461-2bbee68e9d46", // from dema
        MSAL_TENANT_ID: "9a7f47cb-bdb1-45de-8bc0-7e90a7886767", // from dema
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/9a7f47cb-bdb1-45de-8bc0-7e90a7886767', // Replace the placeholder with your tenant subdomain
            redirectUri: 'http://localhost:3000/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
    } ,
    staging : {
        API_HOST_URL: "https://staging-api-my.ausbildung.cloud",
        MSAL_CLIENT_ID: "0200f95e-3ba9-41b5-af4b-ebd8947685c0", // from bauabc
        MSAL_TENANT_ID: "e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0", // from bauabc
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0',
            redirectUri: 'https://my.ausbildung.cloud/redirect',
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
    } ,
    production : {
        API_HOST_URL: "https://api-my.ausbildung.cloud",
        MSAL_CLIENT_ID: "0200f95e-3ba9-41b5-af4b-ebd8947685c0", // from bauabc
        MSAL_TENANT_ID: "e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0", // from bauabc
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0',
            redirectUri: 'https://my.ausbildung.cloud/redirect',
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
    }
};

export default config.production;
import config from "../config";

class UserController{

    static ACL = {
        "trainee" : [
            "/login",
            "/notfound",
            "/about",
            "/trainee/:id/profile",
            "/trainee/:traineeId/worksheets", // Main view for trainee
        ],
        "instructor": [
            "/login",
            "/notfound",
            "/about",
            "/redirect",
            "/instructor/:id/profile",
            "/instructor/:id/courses",
            "/instructor/:instructorId/worksheets" // landing page,
        ],
        "admin": [
            "/login",
            "/notfound",
            "/about",
            "/worksheets",
            "/templates",
            "/projects",
            "/trainees",
            "/courses",
            "/instructors",
            "/redirect",

            "/:projectId",
            "/editor/:projectId",
            "/worksheets/t/:instructorId",
            "/worksheets/i/:instructorId",
            "/worksheets/:instrcutorId/:traineeId/:id",
            "/instructors/:id",

            "/trainees/:id",
            "/courses/:id",

        ]
    }

    static loginLocalUser(sessionToken,userType,email,id){
        localStorage.setItem('sessionToken', sessionToken);
        localStorage.setItem('type', userType);
        localStorage.setItem('email', email);
        localStorage.setItem('id', id);


        switch (userType){
            case 'admin':
                window.location = UserController.getMyProfileUrl();
                break;
            case 'instructor':
                window.location = UserController.getMyCoursesUrl();
                break;
            case 'trainee':
                window.location = UserController.getMyWorksheetUrl();
                break;
            default:
                window.location = UserController.getMyProfileUrl();
        }

    }


    static logout(){
        fetch(config.API_HOST_URL + "/users/logout", {
            method: "DELETE",
            headers : {
                "Content-Type": "application/json",
                "Authorization" : 'Bearer '+ this.getUser().sessionToken
            }
        }).then((response) => {
            try {
                if(response.ok){
                    console.log("User logged out successfully.");
                }
            }
            catch(e) {
                console.error(e);
            }
            finally {
                localStorage.removeItem('sessionToken');
                localStorage.removeItem('type');
                localStorage.removeItem('email');
                localStorage.removeItem('id');

                localStorage.removeItem('CoursesListAccordions_openAccordions');
                localStorage.removeItem('WorksheetTableAccordion_openSections');
                localStorage.removeItem('TopicsListPageForInstructor_selectedValues');


                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function(){ window.location = "/login"; }, 100);
            }
        });

    }

    static isAdmin(){
        let user = UserController.getUser();
        return user.type === 'admin';
    }

    static getMyCoursesUrl(){
        let user = UserController.getUser();
        if(user.type === 'instructor'){
            return "/instructor/"+user.id+"/courses";
        }else if(user.type === 'trainee'){
            return "/ltb/digitales-arbeitsblatt/meine-projekte";
        } else if(user.type === 'admin'){
            return "/courses";
        }
        else{
            UserController.logout();
            throw new Error('unknown user and userType');
        }
    }

    static getMyProfileUrl(){
        let user = UserController.getUser();
        if(user.type === 'instructor'){
            return "/instructor/"+user.id+"/profile";
        }else if(user.type === 'trainee'){
            return "/trainee/"+user.id+"/profile";
        }else if(user.type === 'admin'){
            return "/profile";
        } else{
            UserController.logout();
            throw new Error('unknown user and userType');
        }
    }

    static getMyWorksheetUrl(){
        let user = UserController.getUser();
        if(user.type === 'instructor'){
            // /instructor/:instructorId/worksheets
            return "/instructor/"+user.id+"/worksheets";
        }else if(user.type === 'trainee'){
            return "/meine-projekte";
        }else if(user.type === 'admin'){
            return "/admin/"+user.id+"/worksheets";
        }else{
            UserController.logout();
            throw new Error('unknown user and userType');
        }
    }

    static hasAccessToTheView(location){
        let user = UserController.getUser();
        let permissions = UserController.ACL[user.type];
        console.log(location);
        console.log(permissions);
        return location in permissions;
    }

    static isLoggedIn(){
        let user = UserController.getUser();
        return (user !== undefined && user.sessionToken !== undefined && user.sessionToken !== null);
    }


    static async getUniPlusUserByJWTToken(token){
        try{

            let response = await fetch(config.API_HOST_URL + "/users/me",{
                headers: {
                    "Authorization": "Bearer "+token,
                }
            });
            if (!response.ok) {
                return null;
            }
            return await response.json();
        }catch (e) {
            return null;
        }
    }

    static async loginUniPlusUserByCredentials(username,password){
        try{
            const params = new URLSearchParams();
            params.append("username", username);
            params.append("password", password);
            let response = await fetch(config.API_HOST_URL + "/users/unipluslogin?"+params.toString());
            if (!response.ok) {
                return null;
            }else{
                return await response.json();
            }
        }catch (e) {
            return null;
        }
    }

    static createLocalUser(sessionToken, type, email, id){
        return {
            'sessionToken': sessionToken,
            'type': type,
            'email': email,
            'id': id
        }
    }

    static getUser(){
        return {
            'sessionToken': localStorage.getItem('sessionToken'),
            'type': localStorage.getItem('type'),
            'email': localStorage.getItem('email'),
            'id': localStorage.getItem('id')
        };
    }
}
export default UserController;
import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import TextInputFormField from "../formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {v4 as uuidv4} from "uuid";
import Multiselect from "multiselect-react-dropdown";
import ComponentHeader from "../text/ComponentHeader";
import InputComponent from "./InputComponent";
import _ from "lodash";


class MultipleChoiceInput extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.updateComponent = props.updateComponent;
        this.insertOption = this.insertOption.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.updateColumn = this.updateColumn.bind(this);
        this.shouldBeDisabled = _.has(this.component,'writeModeBasedOnPhase')? !this.component.writeModeBasedOnPhase: false;
    }

    static create(){
        return {
            id: uuidv4(),
            type : "multipleChoice",
            title: "Multiple-Choice",
            data : {
                "defaultSelected": {}
            },
            config: {
                options: [],
                showHeader: false
            }
        };
    }

    updateColumn(){

    }

    _handleDataChange(id,value) {
        this.component.data[id] = value;
        this.updateComponent(this.component);
    };

    deleteOption(optionIndex){
        this.component.config.options.splice(optionIndex, 1);
        this.updateComponent(this.component);
    }
    insertOption(){
        var id = uuidv4();
        this.component.config.options.push(
            {
                id: uuidv4(),
                value: "",
                name: "Option "+(this.component.config.options.length+1),
            }
        );
        this.updateComponent(this.component);
    }

    renderOptionRow(option,optionIndex){
        return (
            <div key={option.id}>
                <Row>
                    <Col xs={12} md={5}>
                        <TextInputFormField
                            title={""}
                            rows={1}
                            description={""}
                            value={option.name}
                            onChange={(value) => {
                                this.component.config.options[optionIndex].name = value;
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <TextInputFormField
                            title={""}
                            rows={1}
                            description={""}
                            value={option.value}
                            onChange={(value) => {
                                this.component.config.options[optionIndex].value = value;
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <Button onClick={() => {this.deleteOption(optionIndex)}}><FontAwesomeIcon icon={faTrashCan} /></Button>
                    </Col>
                </Row>

            </div>
        )
    }

    renderOptionRowHeader(){
        return (
            <Row>
                <Col xs={12} md={5}>
                    Name
                </Col>
                <Col xs={12} md={5}>
                    Wert
                </Col>
                <Col xs={12} md={2}>
                </Col>
            </Row>
        )
    }

    renderEditorView(){
        return (
            <div className={this.component.type}>
                <div>
                    <Form.Group>
                        <Form.Label>Optionen</Form.Label>

                        {this.component.config.options.length > 0 &&
                            <div style={{marginBottom: '0.5em'}}>
                                {this.renderOptionRowHeader()}
                            </div>
                        }

                        <Form.Floating>
                            {this.component.config.options.map( (option, idx) => {
                                return (
                                    <div>
                                        { this.renderOptionRow(option, idx) }
                                    </div>
                                );
                            })}
                        </Form.Floating>
                        <Button onClick={this.insertOption}>Option hinzufügen <FontAwesomeIcon icon={faPlus}/></Button>
                    </Form.Group>

                </div>
            </div>

        );
    }

    renderInputView(){
        return (
            <div className={this.component.type}>
                <div suppressContentEditableWarning={true} contentEditable="false">
                    <Multiselect
                        disable={this.shouldBeDisabled}
                        singleSelect={false}
                        placeholder={"Auswählen"}
                        emptyRecordMsg={"Alles bereits ausgewählt"}
                        options={this.component.config.options} // Options to display in the dropdown
                        selectedValues={this.component.data.defaultSelected}
                        onRemove={(selectedOption) => {
                            this._handleDataChange("defaultSelected",selectedOption)
                        }}
                        onSelect={(selectedOption) => {
                            this._handleDataChange("defaultSelected",selectedOption)
                        }}
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </div>
            </div>
        );
    }

    renderInstructorView(){
        return (
            <div className={this.component.type}>
                <div suppressContentEditableWarning={true} contentEditable="false">
                    {this.component.data.defaultSelected.map !== undefined &&
                    <ul>
                        {this.component.data.defaultSelected.map((value, idx) => {
                            return (<li>
                                {value.name+" - "+value.value}
                            </li>)
                        })}
                    </ul>
                    }
                </div>
            </div>
        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default MultipleChoiceInput;
import React, {Component} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faExclamationTriangle, faWarning} from "@fortawesome/free-solid-svg-icons";

class EmailTextField extends Component {
    constructor(props) {
        super(props);
        this.email = props.email;
    }
    render() {
        return(
            <>
                {this.email != ""?
                    <span><FontAwesomeIcon icon={faEnvelope} /> {this.email}</span>
                    :
                    <span style={{color:'red'}}><FontAwesomeIcon icon={faExclamationTriangle} /> Keine E-Mail Adresse</span>
                }
            </>
        )
    }
}
export default EmailTextField;
import React, {Component} from 'react';


import config from "../../config";


import ProjectsTable from "../../components/TemplatesTable";
import {faCube, faCubes} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import APIController from "../../controller/APIController";
import PageContent from "../../includes/PageContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class ProjectTemplateListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            projects : null,
        };
    }

    async componentDidMount() {
        try {
            addSessionTokenToRequestHeader();
            let response = await fetch(config.API_HOST_URL + "/projects/templates");
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let projects = await response.json();

            let professionName = null;

            if(this.props.professionId === '-1'){
                professionName = <span><FontAwesomeIcon icon={faCube} /> Gewerksübergreifend</span>
                projects = projects.filter(p=>p.professionId === this.props.professionId);
            }else if(this.props.professionId != null){
                let x = await APIController.getAllTopics(true,this.props.professionId);
                professionName = <span><FontAwesomeIcon icon={faCube} /> {x.filter(p=>p._id === this.props.professionId)[0].professionName}</span>
                projects = projects.filter(p=>p.professionId === this.props.professionId);
            }else{
                professionName = <span><FontAwesomeIcon icon={faCubes} /> Alle Templates</span>
            }


            this.setState({
                isFetching: false,
                projects : projects,
                professionName: professionName
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                projects : null,
                professionName: null
            });
        }
    }



    render() {
        if(this.state.fetching || this.state.projects == null){
            return <LoadingScreen />
        }
        return (
            <PageContent>
                {this.state.professionName != null &&
                    <h1>{this.state.professionName}</h1>
                }
                <ProjectsTable professionId={this.props.professionId} showTemplates={true} projects={this.state.projects}></ProjectsTable>
            </PageContent>
        );
    }
}


export default ProjectTemplateListPage;
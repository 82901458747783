import React, {Component} from 'react';

import { Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faComments,
    faEdit,
    faEye,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import APIController from "../../controller/APIController";

class SmartWorksheetActionButtons extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.traineeId = props.traineeId;
        this.userType = props.userType;
        this.onDeleteWorkingSheet = props.onDeleteWorkingSheet;
    }

    calculateButtonVisibilityByState(worksheetState){

        let showButton = false;
        let showEditButton = false;
        let showCommentButton = false;
        let showFinalDeleteButton = false;

        if(this.userType === 'trainee'){
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showButton = true;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showButton = false;
                    showEditButton = true;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showButton = true;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
            }
        }else{
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = true;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showButton = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = true;
                    break;
            }
        }

        return {
            showButton : showButton,
            showEditButton : showEditButton,
            showCommentButton :showCommentButton,
            showFinalDeleteButton:showFinalDeleteButton
        }
    }



    static getURLByState(state, worksheetId, traineeId) {
        switch (state) {
            case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                return "/worksheets/" + worksheetId;
            case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            default:
                return "/worksheets/" + worksheetId + "?readOnly=true";
        }
    }


    render() {
        var self = this;
        var buttonVis = this.calculateButtonVisibilityByState(this.worksheet.state);
        return (
            <div>
                    {buttonVis.showButton &&
                        <Link to={"/worksheets/"+ this.worksheet.id+"?readOnly=true"}>
                            <Button title={"Anzeigen"} variant={'success'} style={{minWidth:'3em',marginRight:'0.2em'}}><FontAwesomeIcon icon={faEye} /></Button>
                        </Link>
                    }
                    {buttonVis.showEditButton &&
                        <Link to={"/worksheets/"+ this.worksheet.id}>
                            <Button title={"Bearbeiten"} variant={'info'} style={{minWidth:'3em',marginRight:'0.2em'}}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Link>
                    }
                    {buttonVis.showCommentButton &&
                        <Link to={"/worksheets/"+ this.worksheet.id+"?evaluate=true"}>
                            <Button title={"Bewerten"}
                                    variant={'info'} style={{minWidth:'3em',marginRight:'0.2em'}}>
                                <FontAwesomeIcon icon={faComments} /></Button>
                        </Link>
                    }
                    {buttonVis.showFinalDeleteButton &&
                        <Button title={"Endgültig Löschen"} onClick={()=>{self.onDeleteWorkingSheet(this.worksheet)}}
                                variant={'danger'} style={{minWidth:'3em',marginRight:'0.2em'}}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    }
                </div>
        )
    }
}
export default SmartWorksheetActionButtons;
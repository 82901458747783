import React, {Component} from "react";


import _ from "lodash";

import "./WorksheetListPageForInstructor.css";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import Progress from "../../components/progressBar";
import WorksheetTableAccordion from "../../components/WorksheetTableAccordion";

class WorksheetsListPageForInstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            user: UserController.getUser(),
            instructorId: props.instructorId,
            worksheets: [],
            worksheetsForProgress: []
        }
        this.onWorksheetsUpdated = this.onWorksheetsUpdated.bind(this);
    }

    async componentDidMount() {
        try {
            let user = this.state.user;
            /*  if(this.state.traineeId !== user.id){
                alert("No Access possible");
                window.location = UserController.getMyProfileUrl();
            }*/
            const instructorId = encodeURIComponent(this.state.instructorId);
            const worksheets = await APIController.getWorksheetByParam("instructorId", instructorId);

            this.setState({
                isFetching: false,
                worksheets: _.groupBy(worksheets, "courseId"),
                worksheetsForProgress: worksheets
            })
        } catch (e) {
            console.log(e);
            this.setState({
                isFetching: false,
                worksheets: [],
                worksheetsForProgress: []
            })
        }
    }

    onWorksheetsUpdated(updatedWorksheets){
        let allworksheets = [];
        Object.keys(updatedWorksheets).map((course, sectionIndex) => {
            updatedWorksheets[course].map((w) => {
                allworksheets.push(w);
            });
        });
        this.setState({
            worksheetsForProgress: allworksheets
        });
    }

    render() {
        if (!this.state.isFetching && this.state.worksheets.length === 0) {
            return <div>You dont have any working sheets</div>
        } else {
            return (
                this.state.isFetching ? (<LoadingScreen/>) : (
                    <div className={"WorksheetsListPageForInstructor"}>
                        <PageContent>
                                <div>
                                    <Progress bgColor={"#01aef3"} noText={false} fontSize={"1.2em"} worksheets={this.state.worksheetsForProgress}></Progress>
                                </div>
                                <WorksheetTableAccordion
                                    userType={UserController.getUser().type}
                                    onWorksheetsUpdated={this.onWorksheetsUpdated}
                                    worksheets={this.state.worksheets}
                                />
                        </PageContent>
                    </div>
                )
            )
        }
    }
}

export default WorksheetsListPageForInstructor;
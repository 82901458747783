import React, {Component} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {Accordion, Button, Card, Col, Dropdown, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './DragableComponentBlock.css';
import {
    faCaretDown,
    faCaretUp, faCheckDouble,
    faClose, faCommenting, faEdit,
    faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import TextareaInput from "./input/TextareaInput";
import TableInput from "./input/TableInput";
import MultipleChoiceInput from "./input/MultipleChoiceInput";
import FileUploadInput from "./input/FileUploadInput";
import GefaerdungsbeurteilungInput from "./input/GefaerdungsbeurteilungInput";
import ComponentUtil from "./ComponentUtil";
import ToggleSwitchFormField from "./formFields/ToggleSwitchFormField";
import TextInputFormField from "./formFields/TextInputFormField";
import HeadlineTextComponent from "./text/HeadlineTextComponent";
import ContentTextComponent from "./text/ContentTextComponent";
import ImageContentTextComponent from "./text/ImageTextComponent";
import DownloadTextComponent from "./text/DownloadTextComponent";
import ExternalLinkTextComponent from "./text/ExternalLinkTextComponent";
import MarkdownTextComponent from "./input/MarkdownTextComponent";
import ComponentSizeDropdown from "./WorksheetEditorComponents/ComponentSizeDropdown";


function ActionIconButton({ className, children, handler }) {
    return (
        <button
            type="button"
            className={className+" actionButton btn"}
            onClick={() => {handler()}}
        >
            {children}
        </button>
    );
}


class DragableComponentBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            component: props.component
        };

        /* Set default values if not initialized */
        if(this.state.component.config.showEditorView === undefined){
            this.state.component.config.showEditorView = true;
        }
        if(this.state.component.config.openCard === undefined){
            this.state.component.config.false = false;
        }
        if(!this.state.component.config.titleHidden === undefined) {
            this.state.component.config.titleHidden = false;
        }

        this.toggleCard = this.toggleCard.bind(this);
        this.onComponentUpdate = props.onComponentUpdate;
        this.updateComponent = this.updateComponent.bind(this);
        this.componentTitleRef = React.createRef();
    }

    toggleCard(){
        this.state.component.config.openCard = !this.state.component.config.openCard;
        this.updateComponent(this.state.component);
        this.onComponentUpdate(this.state.component);
    }
    toggleEditorView(){
        this.state.component.config.showEditorView = !this.state.component.config.showEditorView;
        this.updateComponent(this.state.component);
        this.onComponentUpdate(this.state.component);
    }

    hideParentComponentClicked = () => {
        this.state.component.hide = !this.state.component.hide;
        this.updateComponent(this.state.component);
        this.onComponentUpdate(this.state.component);
    }

    updateComponent(component){
        this.setState({
            component: component
        })
    }


    renderComponentContent(){

        let uniqueStateKey = this.state.component.config.showEditorView
            +"_"+this.state.component.config.commentFunctionChecked
            +"_"+this.state.component.config.evaluationChecked;

        let showEditorView = this.state.component.config.showEditorView;
        if(this.state.component.fromParent){
            showEditorView = false;
        }

        if(this.state.component.type == "textInput"){
            return <div>
                <TextareaInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "markdownText"){
            return <div>
                <MarkdownTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component}  />
            </div>
        }else if(this.state.component.type == "tableInput"){
            return <div>
                <TableInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "multipleChoice"){
            return <div>
                <MultipleChoiceInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "fileUpload"){

            return <div>
                <FileUploadInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "gefaehrdungsbeurteilung"){

            return <div>
                <GefaerdungsbeurteilungInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "headline"){
            return <div>
                <HeadlineTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "text"){
            return <div>
                <ContentTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "image"){
            return <div>
                <ImageContentTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "download"){
            return <div>
                <DownloadTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else if(this.state.component.type == "externalLink"){
            return <div>
                <ExternalLinkTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    showEditorView={showEditorView}
                    component={this.state.component} />
            </div>
        }else{
            return (<div></div>);
        }
    }

    renderComponentAccording(){
        let isEvaluationComponent = (
            this.state.component.type != 'headline'
            && this.state.component.type != 'text'
            && this.state.component.type != 'markdownText'
            && this.state.component.type != 'image'
            && this.state.component.type != 'download'
            && this.state.component.type != 'externalLink'
        );

        return(
            <div className={'component'}>
                <Accordion className={`${'componentAccordion'} ${this.state.component.hide?'hided':' '}`} activeKey={[this.state.component.config.openCard?"0":"-1"]}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={8}>
                                    {!this.state.component.fromParent &&
                                        <h4 className={this.state.component.config.titleHidden ? "componentTitle hidden" : "componentTitle"}>
                                            {ComponentUtil.getIconByComponentType(this.props.component.type)}
                                            <Button className={"hiddenButton"} title={"Titel verbergen"}
                                                    onClick={() => {
                                                        this.state.component.config.titleHidden = !this.state.component.config.titleHidden;
                                                        this.updateComponent(this.state.component);
                                                    }}>
                                                {
                                                    this.state.component.config.titleHidden ?
                                                        <FontAwesomeIcon icon={faEyeSlash}/> :
                                                        <FontAwesomeIcon icon={faEye}/>
                                                }
                                            </Button>
                                            <div style={{display: 'inline'}}>
                                                <Form.Control value={this.state.component.title}
                                                              className={"componentTitleInputField"}
                                                              ref={this.componentTitleRef}
                                                              onChange={(value) => {
                                                                  var component = this.state.component;
                                                                  component.title = this.componentTitleRef.current.value;
                                                                  this.updateComponent(component);
                                                              }}
                                                              type="text" placeholder={"Bitte Titel eingeben"}/>
                                            </div>
                                        </h4>
                                    }
                                    {this.state.component.fromParent &&
                                        <h4 className={this.state.component.config.titleHidden ? "componentTitle hidden" : "componentTitle"}>
                                            {ComponentUtil.getIconByComponentType(this.props.component.type)} <span style={this.state.component.config.titleHidden?{textDecoration:'line-through'}:{}}> {this.state.component.title}</span>
                                        </h4>
                                    }
                                    </Col>
                                <Col xs={4}>
                                    <div style={{float:"right"}}>
                                        <div style={{display:'inline-block'}}>
                                            {!this.state.component.fromParent &&
                                                <ComponentSizeDropdown
                                                    selectedSize={this.state.component.config.size}
                                                    onChange={(selectedSize) => {
                                                        var component = this.state.component;
                                                        this.state.component.config.size = selectedSize;
                                                        this.updateComponent(component);
                                                    }}
                                                />
                                            }
                                            {this.state.component.fromParent &&
                                                <>{ComponentSizeDropdown.renderIcon(this.state.component)}</>
                                            }
                                                </div>
                                        {true &&
                                            (this.state.component.config.openCard && !this.state.component.fromParent) &&
                                            <ActionIconButton className={!this.state.component.config.showEditorView?"":"selected"} handler={() => {this.toggleEditorView()}}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ActionIconButton>
                                        }
                                        {!this.state.component.hide &&
                                        <ActionIconButton handler={() => {this.toggleCard()}}>
                                            {
                                                this.state.component.config.openCard?
                                                    <FontAwesomeIcon icon={faCaretUp} />:
                                                    <FontAwesomeIcon icon={faCaretDown} />
                                            }
                                        </ActionIconButton>
                                        }
                                        {this.state.component.fromParent &&
                                        <ActionIconButton handler={() => {this.hideParentComponentClicked()}}>
                                            {!this.state.component.hide?
                                                <FontAwesomeIcon icon={faEye} />
                                                :<FontAwesomeIcon icon={faEyeSlash} />
                                            }
                                        </ActionIconButton>
                                        }
                                        {!this.state.component.fromParent &&
                                            <ActionIconButton handler={() => {this.props.onRemoveComponentClicked()}}><FontAwesomeIcon icon={faClose} /></ActionIconButton>
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Card.Header>
                        {!this.state.component.hide &&
                        <Accordion.Collapse eventKey="0">
                            <div suppressContentEditableWarning={true} contentEditable="false" style={{cursor:"initial"}}>

                                <Card.Body>


                                    {false && (this.state.component.config.showEditorView && !this.state.component.fromParent) &&
                                        <div className={"componentTopSettings"}>
                                            <Row>
                                                <Col xs={12} md={isEvaluationComponent?8:12}>
                                                    <TextInputFormField
                                                        title={"Titel"}
                                                        rows={1}
                                                        description={"Geben Sie einen Titel für die Komponente ein"}
                                                        onChange={(value) => {
                                                            var component = this.state.component;
                                                            component.title = value;
                                                            this.updateComponent(component);
                                                        }}
                                                        value={this.state.component.title}
                                                    />
                                                </Col>


                                                {isEvaluationComponent &&
                                                    <Col xs={12} md={4}>
                                                        <ToggleSwitchFormField
                                                            style={{display: "inline-block", marginLeft: "1em", float: "right"}}
                                                            description={"Kommentare"}
                                                            icon={<FontAwesomeIcon icon={faCommenting}/>}
                                                            onChange={(value) => {
                                                                var component = this.state.component;
                                                                component.config.commentFunctionChecked = value;
                                                                this.updateComponent(component);
                                                            }}
                                                            checked={this.state.component.config.commentFunctionChecked}
                                                        />
                                                        <ToggleSwitchFormField
                                                            description={"Bewertungen"}
                                                            style={{display: "inline-block", float: "right"}}

                                                            icon={<FontAwesomeIcon icon={faCheckDouble}/>}
                                                            onChange={(value) => {
                                                                var component = this.state.component;
                                                                component.config.evaluationChecked = value;
                                                                this.updateComponent(component);
                                                            }}
                                                            checked={this.state.component.config.evaluationChecked}
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                            <TextInputFormField
                                                title={"Beschreibung"}
                                                rows={2}
                                                description={"Geben Sie einen Erläuterungstext zu dieser Aufgabe ein"}
                                                onChange={(value) => {
                                                    var component = this.state.component;
                                                    component.config.description = value;
                                                    this.updateComponent(component);
                                                }}
                                                value={this.state.component.config.description}
                                            />
                                        </div>

                                    }
                                    {this.renderComponentContent()}
                                </Card.Body>
                            </div>
                        </Accordion.Collapse>
                        }
                    </Card>
                </Accordion>
            </div>

        )
    }


    renderDraggableBlock(component,fixed = false){
        var self = this;
        return(
            <Draggable draggableId={"draggable-component-"+component.id} index={this.props.componentIndex}>
                {function (provided, snapshot) {
                    var dragHandleProps = provided.dragHandleProps;
                    if(fixed){
                        dragHandleProps = []; //remove drag handler to have fixed section block
                    }
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...dragHandleProps}
                            className={`${'componentDraggable'} ${component.fromParent?'fromParent':' '} ${snapshot.isDragging?'isDragging':' '}`}
                            /*style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}*/
                        >
                            {self.renderComponentAccording()}
                        </div>
                    );
                }}
            </Draggable>
        )
    }

    render() {
        if(this.state.component.fromParent){
            return(this.renderDraggableBlock(this.state.component,true));
        }else{
            return(this.renderDraggableBlock(this.state.component,false));
        }
    }
}
export default DragableComponentBlock;
import React, {Component} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Accordion, Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './DragableSectionBlock.css';
import {
    faCaretLeft,
    faCaretDown,
    faCaretUp, faClose, faCog, faEye, faEyeSlash, faPlusSquare
} from "@fortawesome/free-solid-svg-icons";
import DragableComponentBlock from "./DragableComponentBlock";
import ComponentUtil from "./ComponentUtil";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {SectionPhaseConfigEditor} from "./PhaseEditor";

class DragableSectionBlock extends Component {
    constructor(props) {
        super(props);
        this.section = props.section;
        this.onSectionUpdate = props.onSectionUpdate
        this.removeSection = props.removeSection;
        this.onSectionUpdate = props.onSectionUpdate;
        this.sectionIndex = props.sectionIndex;
        this.removeComponent = this.removeComponent.bind(this);
        this.onComponentUpdate = this.onComponentUpdate.bind(this);
        this.renderDraggableBlock = this.renderDraggableBlock.bind(this);
        this.addComponent = this.addComponent.bind(this);

        this.state = {
            openSections: {},
            flippedCardSections: {}
        };
    }


    onComponentUpdate(component){
        var componentIndex = null;
        this.section.components.map( (comp, compIndex) => {
            if(comp.id == component.id){
                componentIndex = compIndex;
            }
        });
        this.section.components[componentIndex] = component;
        this.updateSection(this.section);
    }

    removeComponent = (componentIndex) => {
        const section = this.section;
        section.components.splice(componentIndex, 1);
        this.updateSection(section);
    }

    hideParentSectionClicked = () => {
        this.section.hide = !this.section.hide;
        this.updateSection(this.section);
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            ...
            openSections
        });
    }

    flipSectionCard(sectionId){
        if(!this.state.flippedCardSections.hasOwnProperty(sectionId)) {
            this.state.flippedCardSections[sectionId] = false;
        }
        let flippedCardSections =  this.state.flippedCardSections
        flippedCardSections[sectionId] = !this.state.flippedCardSections[sectionId];
        this.setState({
            ...
            flippedCardSections
        })
    }

    updateSection(section){
        this.onSectionUpdate(section);
        console.log(section, " Check after Section Updated from onchange in DragableSectionBlock");
    }

    addComponent(componentTypeId){
        let newComponent = ComponentUtil.createNewComponentByTypeId(componentTypeId);
        this.section.components.push(newComponent);
        this.updateSection(this.section);

    }

    renderAddComponentDropdown(title,componentList,disabled=false){
        return <Dropdown key={"Dropdown_"+title}>
            <Dropdown.Toggle key={"Dropdown.Toggle_"+title} variant="success" id="dropdown-basic">
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu key={"Dropdown.Menu_"+title}>
                {componentList.map((componentType,idx) => {
                    return <>
                        <Dropdown.Item key={idx} disabled={disabled} onClickCapture={() =>  {this.addComponent(componentType.id)}}>
                            <span key={"span_"+idx}>{ComponentUtil.getIconByComponentType(componentType.id)} {ComponentUtil.getTitleByComponentType(componentType.id)}</span>
                        </Dropdown.Item>
                    </>
                })}
            </Dropdown.Menu>
        </Dropdown>
    }

    renderSectionAccording(){
        return (
            <div className={`${'section'} ${this.section.hide?'hided':' '}`}>
                <Accordion  className={`${'sectionAccordion'} ${this.section.hide?'hided':' '}`} activeKey={this.state.openSections[this.section.id]?"-1":"section-accordion-"+this.section.id}>
                    <Card>
                        <Card.Header className={"sectionAccordionCardheader"}>
                            <Row>
                                <Col xs={8}>
                                    <h2 className={this.state.openSections[this.section.id]?"closed":"open"}>{this.section.title}</h2>
                                </Col>
                                <Col xs={4}>
                                    <div align={"right"} style={{paddingRight:'1em'}}>
                                        {!this.section.hide &&
                                        <Button onClick={() => {this.toggleSection(this.section.id)}}>
                                            {
                                                this.state.openSections[this.section.id]?
                                                    <FontAwesomeIcon icon={faCaretDown} />:
                                                    <FontAwesomeIcon icon={faCaretUp} />
                                            }
                                        </Button>
                                        }
                                        {this.section.fromParent &&
                                        <Button style={{marginLeft:"0.5em"}} onClick={() => {this.hideParentSectionClicked()}}>
                                            {!this.section.hide?
                                                <FontAwesomeIcon icon={faEye} />
                                                :<FontAwesomeIcon icon={faEyeSlash} />
                                            }
                                        </Button>
                                        }
                                        {!this.section.hide && this.section.phase && this.section.phase.length > 0 &&
                                        <Button style={{marginLeft:"0.5em"}} onClick={()=> this.flipSectionCard(this.section.id)}>
                                            <FontAwesomeIcon icon={this.state.flippedCardSections[this.section.id] ? faCaretLeft : faCog} />{this.state.flippedCardSections[this.section.id] ? ' Geh zurück' : ' Phase bearbeiten'}
                                        </Button>
                                        }
                                        {!this.section.fromParent &&
                                            <div style={{display:'inline-block'}}>
                                                <Button style={{marginLeft:"0.5em"}} onClick={() => {this.removeSection(this.sectionIndex)}}><FontAwesomeIcon icon={faClose} /></Button>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        {!this.section.hide &&
                        <Accordion.Collapse eventKey={"section-accordion-"+this.section.id}>
                            <Card.Body className={"sectionAccordionCardBody"}>

                                <div className={this.section.components.length>0?"components":"components empty"} style={{
                                        display: !this.section.hide && this.section.phase && this.section.phase.length > 0 && this.state.flippedCardSections[this.section.id] ? 'none' : 'block'
                                      }}>
                                    <div>
                                        <Droppable type={"inputDragables"}  droppableId={"dad-section-"+this.sectionIndex}>
                                            {(provided, snapshot) => (
                                                <><div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className={snapshot.isDraggingOver?"componentsDropZone isDraggingOver":"componentsDropZone"}
                                                >
                                                    <Row>
                                                        {this.section.components.map( (component, index) => {
                                                            return (
                                                                <Col xs={component.config.size?component.config.size:12} key={"DroppableCol" + Math.random().toString()}>
                                                                    <DragableComponentBlock
                                                                        component={component}
                                                                        key={component.id}
                                                                        onRemoveComponentClicked={()=>this.removeComponent(index)}
                                                                        onComponentUpdate={this.onComponentUpdate}
                                                                        componentIndex={index} />
                                                                </Col>)
                                                        })}
                                                    </Row>
                                                    {provided.placeholder}
                                                </div>
                                                    <div style={{marginBottom:'1em'}}>
                                                        <div style={{display:'inline-block',marginRight:'0.5em'}}>
                                                            {this.renderAddComponentDropdown(
                                                                <span key={"Eingabe"}><FontAwesomeIcon key={"Eingabe_Icon"} icon={faPlusSquare} /> Eingabe</span>,
                                                                ComponentUtil.getEingabeKomponenten())
                                                            }
                                                        </div>
                                                        <div style={{display:'inline-block',marginRight:'0.5em'}}>
                                                            {this.renderAddComponentDropdown(
                                                                <span><FontAwesomeIcon icon={faPlusSquare} /> Text</span>,
                                                                ComponentUtil.getTextKomponenten())
                                                            }
                                                        </div>
                                                        <div style={{display:'inline-block'}}>
                                                            {this.renderAddComponentDropdown(
                                                                <span><FontAwesomeIcon icon={faPlusSquare} /> Spezial</span>,
                                                                ComponentUtil.getSpecialKomponenten(),
                                                            true)
                                                            }
                                                        </div>
                                                    </div>
                                                </>

                                            )}
                                        </Droppable>
                                    </div>
                                </div>
                                <div style={{
                                    display: !this.section.hide && this.section.phase && this.section.phase.length > 0 && this.state.flippedCardSections[this.section.id] ? 'block' : 'none'
                                }}>
                                    <div className={"mt-3 mb-3"} style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, minmax(80px, 1fr))',
                                        gap: '1rem'
                                    }}>
                                        {this.section.phase && this.section.phase.map((phaseOfSection, phaseOfSectionIndex) => {
                                            return <SectionPhaseConfigEditor section={this.section}
                                                                             phaseOfSection={phaseOfSection}
                                                                             key={phaseOfSectionIndex}
                                                                             onSectionUpdate={this.onSectionUpdate}/>
                                        })}
                                    </div>
                                </div>

                            </Card.Body>
                        </Accordion.Collapse>
                        }
                    </Card>
                </Accordion>
            </div>
        )
    }


    renderDraggableBlock(section,fixed = false){
        var self = this;
        return(
            <Draggable key={section.id} draggableId={"draggable-section-"+section.id} index={this.props.sectionIndex}>
                {function (provided, snapshot) {
                    var dragHandleProps = provided.dragHandleProps;
                    if(fixed){
                        dragHandleProps = []; //remove drag handler to have fixed section block
                    }
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...dragHandleProps}
                            className={`${'sectionDraggable'} ${section.fromParent?'fromParent':' '} ${section.hide?'hided':' '} ${snapshot.isDragging?'isDragging':' '}`}
                            /*style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}*/
                        >
                            {self.renderSectionAccording()}
                        </div>
                    );
                }}
            </Draggable>
        )
    }

    render() {
        if(this.section.fromParent){
            return(this.renderDraggableBlock(this.section,true));
        }else{
            return(this.renderDraggableBlock(this.section,false));
        }
    }
}
export default DragableSectionBlock;
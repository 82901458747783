import React, { Component } from 'react';
import {Accordion, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {

     faCaretDown, faCaretUp,
     faDiagramProject,
} from "@fortawesome/free-solid-svg-icons";
import '../components/WorksheetTableAccordion.css';
import {Link} from "react-router-dom";
import TraineeBadge from "./badges/TraineeBadge";
import StatusBadge from "./badges/StatusBadge";
import DateTimeUtil from "../util/DateTimeUtil";
import SmartWorksheetActionButtons from "./WorksheetEditorComponents/SmartWorksheetActionButtons";
import UserController from "../controller/UserController";
import APIController from "../controller/APIController";
import Progress from "./progressBar";
import WorksheetListStateBadges from "./badges/WorksheetListStateBadges";
import {PhaseSwitcher} from "./PhaseEditor";



class WorksheetTableAccordion extends Component {

    constructor(props) {
        super(props);
        this.onWorksheetUpdated = this.onWorksheetUpdated.bind(this);
        this.handleDeleteWorksheet = this.handleDeleteWorksheet.bind(this);
        this.onWorksheetsUpdated = props.onWorksheetsUpdated;
        this.userType = props.userType;
        this.params = props.params;
        this.allTopics = props.allTopics;
        this.topicId = props.topicId?props.topicId:null;
        this.groupedByTopics = props.groupedByTopics?props.groupedByTopics:false;

        let openSections = {};
        if(localStorage.getItem('WorksheetTableAccordion_openSections') != null){
            openSections = JSON.parse(localStorage.getItem('WorksheetTableAccordion_openSections'));
        }

        let filteredWorksheets = props.worksheets;
        console.log("all");
        console.log(props.worksheets)
        if(this.topicId != null){
            filteredWorksheets = props.worksheets.filter(obj => obj.topic === this.topicId);
        }
        console.log("filteredWorksheets");
        console.log(filteredWorksheets)
        this.state = {
            openSections: openSections,
            worksheets : filteredWorksheets
        };
        localStorage.setItem('WorksheetTableAccordion_openSections',JSON.stringify(openSections));
    }


    onWorksheetUpdated(updatedWorksheet){
        let self = this;
        let ws = this.state.worksheets.filter(obj => obj.id === updatedWorksheet.id)[0];
        if(updatedWorksheet.evaluation !== undefined){
            ws.evaluation = updatedWorksheet.evaluation;
        }
        if(updatedWorksheet.phase !== undefined){
            ws.phase = updatedWorksheet.phase;
        }
        APIController._updateWorksheet(
        ws,
        updatedWorksheet.state,
        (json) => {
            console.log("worksheet updated...");
            const index = self.state.worksheets.findIndex(wsobj => wsobj.id+"" === updatedWorksheet.id+"");
            self.state.worksheets[index] = updatedWorksheet;
            self.setState({
                worksheets: this.state.worksheets
            });
        },
        (err) => {
            console.log(`Error: ${err}`)
        });
    }

    handleDeleteWorksheet(workingsheet){
        let self = this;
        let ws = this.state.worksheets.filter(obj => obj.id === workingsheet.id)[0];

        APIController.deleteWorksheet(
            ws.id,
            true,
            (json) => {
                console.log("worksheet deleted...");
                const index = self.state.worksheets.findIndex(wsobj => wsobj.id+"" === workingsheet.id+"");

                self.state.worksheets.splice(index, 1);
                self.setState({
                    worksheets: this.state.worksheets
                });
                self.onWorksheetsUpdated(this.state.worksheets);
            },
            (err) => {
                console.log(`Error: ${err}`)
            });
    }


    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
        localStorage.setItem('WorksheetTableAccordion_openSections',JSON.stringify(openSections));
    }


    renderTable(topicId){
        let self = this;

        if(this.state.worksheets.length === 0){
            return <><h3>Keine Arbeitsblätter</h3></>
        }
        return <>{this.state.worksheets.map((w) => {
                return (
                    <div key={w.id}>
                        <Row className={`worksheetListRow ${w.state}`}>
                            <Col xs={12} sm={12} lg={4} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                <b className={'ellipse'}>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={'/trainees/'+w.traineeId}>
                                        <TraineeBadge trainee={w.trainee}></TraineeBadge>
                                    </Link>
                                </b>
                            </Col>

                            <Col xs={6} sm={6} lg={4}  style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                <span style={{marginRight:'0.2em',whiteSpace:'nowrap'}} className={"editedDate"}>{DateTimeUtil.getHumanReadableDate(w.lastEditedTime)},</span>
                                <span style={{whiteSpace:'nowrap'}} className={"editedTime"}>{DateTimeUtil.getHumanReadableTime(w.lastEditedTime)} Uhr</span>
                            </Col>
                            <Col xs={6} sm={6} lg={4} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                <SmartWorksheetActionButtons
                                    worksheet={w}
                                    onDeleteWorkingSheet={this.handleDeleteWorksheet}
                                    traineeId={w.traineeId}
                                    userType={UserController.getUser().type}
                                />
                                <PhaseSwitcher worksheet={w} onWorksheetUpdated={self.onWorksheetUpdated}/>
                                <StatusBadge
                                    changeable={(this.userType === 'instructor')} smallSize={true} onWorksheetUpdated={self.onWorksheetUpdated} worksheet={w}/>
                            </Col>
                        </Row>
                    </div>
                )
            })
        }</>
    }

    renderAccordionWithTable(id,topicId){
        return (
            <>
                <Accordion className={"pradleAccordion"} activeKey={this.state.openSections[id]?id:"-1"}>
                    <Card>
                        <Card.Header>
                            <div className={"cardHeaderWrapper"} onClick={(e) => {e.stopPropagation();this.toggleSection(id)}}>
                                <Row>
                                    <Col xs={10}>
                                        <Row>
                                            <Col xs={12} sm={8} lg={6}>
                                                <h2 className={this.state.openSections[id]?"closed":"open"}>{this.groupedByTopics && <FontAwesomeIcon icon={faDiagramProject} />} {topicId}</h2>
                                            </Col>
                                            <Col xs={12} sm={4} lg={6} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                <WorksheetListStateBadges worksheets={this.state.worksheets[topicId]} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <div align={"right"} style={{paddingRight:'1em'}}>
                                            <div align={"right"} style={{paddingRight:'1em',fontSize:'1.5em'}}>
                                                {this.state.openSections[id]?
                                                    <FontAwesomeIcon icon={faCaretUp} />:
                                                    <FontAwesomeIcon icon={faCaretDown} />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Progress fontSize={"0.2em"} noText={true} bgColor={"#021a91"} worksheets={this.state.worksheets[topicId]}></Progress>
                        </Card.Header>
                        <Accordion.Collapse eventKey={id}>
                            <Card.Body>
                                {this.renderTable(topicId)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        )
    }

    getTopicName(topicId){
        let name = "";
        this.props.allTopics.map((topic,idx)=>{
            if(topic.projectId == topicId){
                name = topic.name;
            }
        });
        return name;
    }


    render() {
        let self = this;

        if(this.topicId == null){
            return (
                <div className={"worksheetTableAccordion"}>
                    {Object.keys(this.state.worksheets).map((topicId, sectionIndex) => {
                        var id = "id" + topicId;
                        this.state.worksheets[topicId] = this.state.worksheets[topicId].sort((a, b) => a.trainee.name.localeCompare(b.trainee.name))
                        return (
                            this.renderAccordionWithTable(id,topicId)
                        );
                    })}
                </div>
            )
        }else{
            return (
                <div className={"pradleAccordion single"}>
                    <Container>
                        {this.renderTable(this.topicId)}
                    </Container>
                </div>
            )
        }

    }
}


export default WorksheetTableAccordion;
import React from 'react';
import './TableInput.css';
import {v4 as uuidv4} from "uuid";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import NumberInputFormField from "../formFields/NumberInputFormField";
import TextInputFormField from "../formFields/TextInputFormField";
import {
    faIndent,
    faPlus,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ComponentHeader from "../text/ComponentHeader";
import InputComponent from "./InputComponent";
import _ from "lodash";



class TableInput extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.renderCell = this.renderCell.bind(this);
        this.insertColumn = this.insertColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.updateColumn = this.updateColumn.bind(this);

        this.insertOption = this.insertOption.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.renderOptionRow = this.renderOptionRow.bind(this);
        this.shouldBeDisabled = _.has(this.component,'writeModeBasedOnPhase')? !this.component.writeModeBasedOnPhase: false;
    }

    _handleChange(id,value) {


        this.component.config[id] = value;
        this.updateComponent(this.component);
    };

    _handleDataChange(columnId,rowIndex,value) {

        if(!this.component.data["rowData"].hasOwnProperty(columnId)) {
            this.component.data["rowData"][columnId] = {};
        }

        if(!this.component.data["rowData"][columnId].hasOwnProperty(rowIndex)) {
            this.component.data["rowData"][columnId][rowIndex] = {};
        }
        this.component.data["rowData"][columnId][rowIndex] = value;

        this.updateComponent(this.component);
    };

    static create(){
        return {
            id: uuidv4(),
            type : "tableInput",
            title: "Tabellen-Eingabe",
            data : {
                "commentFunctionChecked" : true,
                "evaluation": true,
                rowData : {

                }
            },
            config: {
                columns: [],
                rows: 3,
                showHeader: false
            }
        };
    }


    renderCell(column, rowIndex){

        let value = "";

        if(this.component.data["rowData"].hasOwnProperty(column.id)
            && this.component.data["rowData"][column.id].hasOwnProperty(rowIndex)
        ) {
            value = this.component.data["rowData"][column.id][rowIndex];
        }else if(column.defaultValue != undefined){
            value = column.defaultValue;
        }

        const selectedValue = value;

        if(column.type == 'text'){

            return <input className={"form-control"}
                          onChange={(value) => this._handleDataChange(column.id, rowIndex,value.target.value)}
                          type={"text"}
                          disabled={this.shouldBeDisabled}
                          value={selectedValue} />;
        }else if(column.type == 'selectbox'){
            return (
                <div>
                    <select
                        disabled={this.shouldBeDisabled}
                        onChange={(value) => this._handleDataChange(column.id, rowIndex,value.target.value)}
                        className={"form-select"}>
                        {column.options.map((option, i) => {
                            return (
                                <option selected={option.name==selectedValue?"selected":""}>{option.name}</option>
                            )
                        })}
                    </select>
                </div>

            );
        }else if(column.type == 'rowindex'){
            return (
                <Badge>{rowIndex+1}</Badge>
            );
        }else{
            return "ERROR";
        }
    }


    renderTextContentCell(column, rowIndex){

        let value = "";

        if(this.component.data["rowData"].hasOwnProperty(column.id)
            && this.component.data["rowData"][column.id].hasOwnProperty(rowIndex)
        ) {
            value = this.component.data["rowData"][column.id][rowIndex];
        }else if(column.defaultValue != undefined){
            value = column.defaultValue;
        }

        const selectedValue = value;

        if(column.type == 'text'){

            return (<span>{selectedValue}</span>);
        }else if(column.type == 'selectbox'){
            return (
                <span>{selectedValue}</span>
            );
        }else if(column.type == 'rowindex'){
            return (
                <Badge>{rowIndex+1}</Badge>
            );
        }else{
            return "ERROR";
        }
    }

    renderColumnRow(column,columnIndex){
        return (
            <div key={column.id}>
                <Row>
                    <Col xs={12} md={2}>
                        <SingleSelectInputFormField
                            selectedValue={this.component.config.columns[columnIndex].type}
                            options={[
                                {name: 'Texteingabe', value: 'text'},
                                {name: 'Selectbox', value: 'selectbox'},
                                {name: 'Zeilennummer', value: 'rowindex'}
                            ]}
                            onChange={(selectedOption) => {
                                this.component.config.columns[columnIndex].type = selectedOption.value;
                                if(this.component.config.columns[columnIndex].options == undefined){
                                    this.component.config.columns[columnIndex].options = [];
                                }
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextInputFormField
                            title={""}
                            rows={1}
                            value={column.name}
                            onChange={(value) => {
                                this.component.config.columns[columnIndex].name = value;
                                this.updateComponent(this.component);
                            }}
                            description={""}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <SingleSelectInputFormField
                            selectedValue={this.component.config.columns[columnIndex].size}
                            options={[
                                {name: 'Klein', value: 'small'},
                                {name: 'Mittel', value: 'medium'},
                                {name: 'Groß', value: 'big'},
                            ]}
                            onChange={(selectedOption) => {
                                this.component.config.columns[columnIndex].size = selectedOption.value;
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <Button onClick={() => this.deleteColumn(columnIndex)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </Col>
                </Row>
                {this.component.config.columns[columnIndex].type == "selectbox" &&
                    <div>
                        <Row>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={10}>
                                <Form.Group>
                                    <Form.Label>Optionen</Form.Label>
                                    <Form.Floating>
                                        {this.component.config.columns[columnIndex].options.length > 0 &&
                                            <div style={{marginBottom: '0.5em'}}>
                                                {this.renderOptionRowHeader()}
                                            </div>
                                        }
                                        {this.component.config.columns[columnIndex].options.map( (option, idx) => {
                                            return this.renderOptionRow(option, columnIndex, idx);
                                        })}
                                    </Form.Floating>
                                    <Button  onClick={() => this.insertOption(columnIndex)}>Option hinzufügen <FontAwesomeIcon icon={faPlus}/></Button>
                                    <br/><br/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                }
                {(
                        this.component.config.columns[columnIndex].type == "selectbox"
                        || this.component.config.columns[columnIndex].type == "text"
                    ) &&
                    <div>
                        <Row>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={5}>
                                <Form.Label>
                                    <FontAwesomeIcon icon={faIndent}/> Default-Wert
                                </Form.Label>
                                <TextInputFormField
                                    title={""}
                                    rows={1}
                                    value={this.component.config.columns[columnIndex].defaultValue}
                                    onChange={(value) => {
                                        this.component.config.columns[columnIndex].defaultValue = value;
                                        this.updateComponent(this.component);
                                    }}
                                    description={"Geben Sie hier den Standard-Wert der Spalte ein"}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        )
    }

    deleteOption(columnIndex,optionIndex){
        this.component.config.columns[columnIndex].options.splice(optionIndex, 1);
        this.updateComponent(this.component);
    }
    insertOption(columnIndex){
        let id = uuidv4();
        this.component.config.columns[columnIndex].options.push(
            {
                id: id,
                value: "",
                name: "Option "+(this.component.config.columns[columnIndex].options.length+1),
            }
        );
        this.updateComponent(this.component);
    }

    renderOptionRowHeader(){
        return (
            <Row>
                <Col xs={12} md={5}>
                    Name
                </Col>
                <Col xs={12} md={5}>
                    Wert
                </Col>
                <Col xs={12} md={2}>
                </Col>
            </Row>
        )
    }
    renderOptionRow(option,columnIndex,optionIndex){
        return (
            <div key={option.id}>
                <Row>
                    <Col xs={12} md={5}>
                        <TextInputFormField
                            title={""}
                            rows={1}
                            description={""}
                            value={option.name}
                            onChange={(value) => {
                                this.component.config.columns[columnIndex].options[optionIndex].name = value;
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <TextInputFormField
                            title={""}
                            rows={1}
                            description={""}
                            value={option.value}
                            onChange={(value) => {
                                this.component.config.columns[columnIndex].options[optionIndex].value = value;
                                this.updateComponent(this.component);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <Button onClick={() => this.deleteOption(columnIndex,optionIndex)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </Col>
                </Row>

            </div>
        )
    }

    updateColumn(){

    }

    deleteColumn(columnIndex){
        this.component.config.columns.splice(columnIndex, 1);
        this.updateComponent(this.component);
    }
    insertColumn(){
        var id = uuidv4();
        this.component.config.columns.push(
            {
                id: id,
                type: "text",
                name: "Spalte "+(this.component.config.columns.length+1),
                size: "medium",
                options: []
            }
        );
        this.updateComponent(this.component);
    }

    renderEditorView(){
        return (
            <div className={this.component.type}>
                <h3>Zeilen</h3>
                <NumberInputFormField
                    title={"Anzahl an Zeilen"}
                    description={"Wieviele Zeilen soll die Tabelle haben?"}
                    maxValue={100}
                    value={this.component.config.rows}
                    onChange={(value) => this._handleChange('rows',value)}
                />
                <div>
                    <Form.Group>
                        <h3>Spalten</h3>
                        <Form.Floating>
                            {this.component.config.columns.map( (column, idx) => {
                                return (
                                    <div>
                                        { this.renderColumnRow(column,idx) }
                                    </div>
                                );
                            })}
                        </Form.Floating>
                        <Button onClick={this.insertColumn}>Spalte hinzufügen <FontAwesomeIcon icon={faPlus}/></Button>
                    </Form.Group>
                </div>
            </div>

        );
    }

    renderInputView(){
        return (
            <div className="TableInput">
                <div className="table-responsive">
                    <table>
                        <tr key={"row_0"}>
                            {this.component.config.columns.map( (column, idx) => {
                                return (
                                    <th>{column.name}</th>
                                )
                            })}
                        </tr>
                        {[...Array(parseInt(this.component.config.rows))].map((x, i) => {
                            return (
                                <tr key={"row_"+i}>
                                    {this.component.config.columns.map( (column, idx) => {
                                        return (
                                            <td className={column.size}>{this.renderCell(column,i)}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        );
    }

    renderInstructorView(){
        return (
            <div className="TableInput">
                <div className="table-responsive">
                    <table>
                        <tr key={"row_0"}>
                            {this.component.config.columns.map( (column, idx) => {
                                return (
                                    <th>{column.name}</th>
                                )
                            })}
                        </tr>
                        {[...Array(parseInt(this.component.config.rows))].map((x, i) => {
                            return (
                                <tr key={"row_"+i}>
                                    {this.component.config.columns.map( (column, idx) => {
                                        return (
                                            <td className={column.size}>{this.renderTextContentCell(column,i)}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }

}
export default TableInput;
import React, { Component } from 'react';
import UserController from "../controller/UserController";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSignOut, faUser, faUserAstronaut,
     faUserTie
} from "@fortawesome/free-solid-svg-icons";
import '../components/LogoutButtonComponent.css';
import {Link} from "react-router-dom";

class LogoutButtonComponent extends Component {

    constructor(props) {
        super(props);
        this.currentPathName = props.currentPathName;
    }

    render() {
        return (
            <div className={"LogoutButtonComponent"}>
                <div style={{textAlign:'right'}}>

                </div>
            </div>
        )
    }
}


export default LogoutButtonComponent;
import React, {Component} from "react";
import LoadingScreen from "../../includes/LoadingScreen";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import './WorksheetPage.css';
import WorksheetViewComponent from "../../components/WorksheetViewComponent";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";


class WorksheetPage extends Component {
    constructor(props) {
        super(props);
        this.handleExportPDFClicked = this.handleExportPDFClicked.bind(this);
        this.state = {
            worksheetId: props.worksheetId,
            traineeId: props.traineeId,
            trainee: null,
            isFetching: true,
            worksheet : null,
            access_rights: null
        };
    }

    async handleExportPDFClicked() {
        window.location = "/worksheets/"+this.state.worksheet.id+"/pdf";
    }

    async componentDidMount(){
        try {
            let user = UserController.getUser();
            if(this.state.traineeId !== user.id){
                //throw Error("No Access possible");
            }
            const worksheetId = encodeURIComponent(this.state.worksheetId);
            const worksheet = await APIController.getWorksheetById(worksheetId);
            const trainee = await APIController.getTraineeById(worksheet.traineeId);

            if(worksheet.traineeId === user.id){
                this.setState({
                    isFetching: false,
                    worksheet : worksheet,
                    trainee : trainee,
                    access_rights : worksheet.access_rights
                });
            }else{
                //throw Error("Somethings wrong!");
                this.setState({
                    isFetching: false,
                    worksheet : worksheet,
                    trainee : trainee,
                    access_rights : worksheet.access_rights
                });
            }
        } catch (e) {
            console.log(e)
            this.setState({
                isFetching : false,
                worksheet: null,
                access_rights : null
            });
        }
    }


    render() {
        if(!this.state.isFetching && this.state.worksheet == null && this.state.trainee == null){
            return <div>Worksheet Not found</div>
        }else{
            return (
                this.state.isFetching ? (<LoadingScreen/>):(
                    <>
                        <div>
                            <WorksheetViewComponent
                                readOnlyMode={true}
                                instructorMode={true}
                                showEditorView={false}

                                topicId={this.state.worksheet.topic}
                                sections={this.state.worksheet.sections}

                                worksheet={this.state.worksheet}
                                trainee={this.state.trainee}
                            ></WorksheetViewComponent>
                            <div className="WorksheetEidtorPageForTrainee">
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <div>
                                            <div style={{padding:"1em"}} align={"center"}>
                                                    <>
                                                        <Button style={{marginRight:"1em",marginBottom:"1em"}} onClick={this.handleExportPDFClicked} variant="outline-info"><FontAwesomeIcon icon={faFilePdf} /> PDF Anzeigen</Button>
                                                    </>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>

                )
            );
        }
    }
}

export default WorksheetPage;
import React, {Component} from "react";

import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import APIController from "../../controller/APIController";
import defaultImageUrl from "../../assets/topic_images/1.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight, faCaretDown, faCaretUp, faCheck, faCheckSquare, faCircleInfo, faClose, faCubes,
    faDiagramProject, faEyeSlash, faFileImage, faFileText, faImage, faImages, faPaperPlane,
    faPlus, faPlusCircle, faPlusMinus, faPlusSquare, faProcedures, faRefresh,
    faSheetPlastic, faSquare, faTasks, faTrash,
    faUser,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import config from "../../config";
import {Accordion, Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import './TopicsListPageForInstructor.css'

import he from 'he'
import image1 from "../../assets/topic_images/1.jpg";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import PagePopup from "../../components/PagePopup";
import WorksheetImageDropzone from "../../components/WorksheetImageDropzone";
import WorksheetUtil from "../../util/WorksheetUtil";

class SingleTopicsPageForInstructor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            topics: [],
            popupProps: {
                title: "",
                body: "",
                submitText: "Bestätigen",
                cancelText: "Abbrechen",
                show: false,
                showFooter: true
            },
            lastTimeAllSelected: false,
            visibilityKeyMap: {},
            deleteOldFileteredByTopicsSelected: false,
            addImageComponentInFirstSectionSelected: false,
            selectedProfession: props.params['professionId'],
            ausbildungsjahrFilter: props.params['ausbildungsjahrFilter']!==undefined?props.params['ausbildungsjahrFilter']:'*',
            gewerkFilter: props.params['gewerkFilter']!==undefined?props.params['gewerkFilter']:'*'
        }
        this.location = props.location;
        this.params = props.params;
        this.handleChange = this.handleChange.bind(this);
    }




    async addNewWorkingsheetTemplateClicked(topicId, templateId){
        let newProject = await APIController.addWorkingsheetTemplate(topicId, templateId);
        window.location.href = "/editor/"+newProject.id;
    }

    handleChange(e){
        this.state.selectedTopics[e.target.name] = e.target.checked;
        this.setState(this.state);
    }




    selectAllFields(){
        let selectedTopics = {};
        this.state.topics.descriptions.forEach((topic) => {
            selectedTopics[topic.topicId] = !this.state.lastTimeAllSelected;
        });
        this.state.selectedTopics = selectedTopics;
        this.state.lastTimeAllSelected = !this.state.lastTimeAllSelected;
        this.setState(this.state);
    }



    async componentDidMount() {
        await this.reload()
    }


    _getStammgewerkNummerByProfessionId(professionId){
        if(professionId === undefined || professionId.toString().length !== 4){
            return null;
        }
        return professionId.toString()[1]+professionId.toString()[2];
    }

    async reload(){
        try {
            this.setState({
                isFetching: true
            })


            let filters = [
                this.state.ausbildungsjahrFilter,
                this.state.gewerkFilter
            ]

            let topicsResult = await APIController.getAllTopicsGroupedByProfessionId(this.state.selectedProfession,true, filters);
            let topic = topicsResult[0];


            let topicIdBlacklist = await APIController.getTopicBlacklist();

            let allTopicsResult = await APIController.getAllTopicsGroupedByProfessionId(this.state.selectedProfession,true);
            let allTopic = allTopicsResult[0];

            const uniqueValues = new Set();  // Set zum Sammeln eindeutiger Werte

            if(this.state.ausbildungsjahrFilter != null){
                allTopic.descriptions.forEach(item => {
                    const parts = item.topicId.split('-');

                    let filterAddon = '';
                    if(this.state.gewerkFilter != null){
                        filterAddon = '/'+this.state.gewerkFilter
                    }

                    if(parts.length>2){
                        let url ='';
                        if(this.state.ausbildungsjahrFilter != null && this.state.ausbildungsjahrFilter === parts[0] + '-*'){
                            url = '/topics/'+allTopic._id+'/*'+filterAddon
                        }else{
                            url = '/topics/'+allTopic._id+'/'+parts[0]+'-*'+filterAddon
                        }
                        uniqueValues.add({
                            name: parts[0],
                            group: "Ausbildungsjahr",
                            id:parts[0],
                            url:url,
                            selected: (this.state.ausbildungsjahrFilter != null && this.state.ausbildungsjahrFilter === parts[0] + '-*')
                        });
                    }
                });
            }
            if(this.state.gewerkFilter != null){
                allTopic.descriptions.forEach(item => {
                    const parts = item.topicId.split('-');

                    let filterAddon = '';
                    if(this.state.ausbildungsjahrFilter != null){
                        filterAddon = ''+this.state.ausbildungsjahrFilter+'/'
                    }

                    let url ='';
                    if(this.state.gewerkFilter != null && this.state.gewerkFilter ==='*-' + parts[1] + '-*'){
                        url = '/topics/'+allTopic._id+'/'+filterAddon+'*';
                    }else{
                        url = '/topics/'+allTopic._id+'/'+filterAddon+'*-'+parts[1]+'-*';
                    }

                    if(parts.length>2){
                        uniqueValues.add({
                            name: parts[1],
                            group: "Stammgewerk",
                            id:parts[1],
                            url:url,
                            selected: (this.state.gewerkFilter != null && this.state.gewerkFilter === '*-' + parts[1] + '-*')
                        });
                    }
                });

                let stammGewerk = this._getStammgewerkNummerByProfessionId(this.state.selectedProfession);
                if(stammGewerk != null){

                    let filterAddon = '';
                    if(this.state.ausbildungsjahrFilter != null){
                        filterAddon = ''+this.state.ausbildungsjahrFilter+'/'
                    }

                    let url = ''
                    if(this.state.gewerkFilter != null && this.state.gewerkFilter ==='*-' + stammGewerk + '-*'){
                        url = '/topics/'+allTopic._id+'/'+filterAddon+'*';
                    }else{
                        url = '/topics/'+allTopic._id+'/'+filterAddon+'*-'+stammGewerk+'-*';
                    }
                    uniqueValues.add({
                        name: stammGewerk,
                        group: "Stammgewerk",
                        id:stammGewerk,
                        url:url,
                        selected: (this.state.gewerkFilter != null && this.state.gewerkFilter === '*-' + stammGewerk + '-*')
                    });
                }

            }
            const subFilter = Array.from(uniqueValues);  // Set in Array umwandeln

            const filterNavigationElements = Array.from(
                new Set(subFilter.map(item => JSON.stringify(item)))
            ).map(str => JSON.parse(str));


            let topicFilters = [];
            if(this.state.gewerkFilter != null) {
                topicFilters.push(this.state.gewerkFilter)
            }
            if(this.state.ausbildungsjahrFilter != null) {
                topicFilters.push(this.state.ausbildungsjahrFilter)
            }

            let allTemplates = await APIController.getAllProjectsByProfessionId(this.state.selectedProfession);

            let allTemplatesOptions = allTemplates.map((t,idx)=>{
                return(
                    {name: t.title, value: t.id}
                )
            });
            allTemplatesOptions = [{name: "--- Kein Template ausgewählt ---", value: null}].concat(allTemplatesOptions) // [ 4, 3, 2, 1 ]


            this.setState({
                isFetching: false,
                topics: topic,
                selectedProfessionName: allTopic.professionName,
                topicFilters:topicFilters,
                allTemplatesOptions: allTemplatesOptions,
                selectedTemplateOption: allTemplatesOptions.length > 0?allTemplatesOptions[0]:null,
                selectedTopics: {},
                topicIdBlacklist: topicIdBlacklist,
                selectedTopicOptions: [],
                filterNavigationElements: filterNavigationElements
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                topics: [],
                topicFilters:[],
                allTemplatesOptions:[],
                selectedTemplateOption: null,
                selectedTopicOptions: [],
                selectedTopics: {},
                topicIdBlacklist: [],
                filterNavigationElements:[]
            })
        }
    }

    _getListOfSelectedTopics(){
        let selectedTopics = [];
        Object.entries(this.state.selectedTopics).map(([key, value]) => {
            if(value){
                selectedTopics.push(key);
            }
        })
        return selectedTopics;
    }

    renderCreateWorksheetsPopupBody(){
        if(this._getListOfSelectedTopics().length === 0){
            return (
                <Alert key={'warning'} variant={'warning'}>
                    Bitte mindestens 1 Projekt auswählen
                </Alert>
            );
        }
        return (
            <>
                <div>
                    <SingleSelectInputFormField
                        title={<><FontAwesomeIcon icon={faCubes}/> Template auswählen</>}
                        selectedValue={this.state.selectedTemplateOption != null ? this.state.selectedTemplateOption.value : null}
                        options={this.state.allTemplatesOptions}
                        onChange={(selectedOption) => {
                            this.setState({
                                selectedTemplateOption: selectedOption,
                            });
                        }}
                    />


                    <Form.Label>Vorhandene Arbeitsblätter löschen?</Form.Label>
                    <Form.Check // prettier-ignore
                        type={"checkbox"}
                        id={"checkbox-deleteOldFileteredByTopicsSelected"}
                        label={"Ja, alle Arbeitsblätter mit einer der ausgewählten Projekt-Nummern löschen"}
                        checked={this.state.deleteOldFileteredByTopicsSelected}
                        name={"deleteOldFileteredByTopicsSelected"}
                        onChange={(e) => {
                            this.state.deleteOldFileteredByTopicsSelected = !this.state.deleteOldFileteredByTopicsSelected;
                            this.state.popupProps.body = this.renderCreateWorksheetsPopupBody();
                            this.setState(this.state);
                        }}
                    />

                    <Form.Label>Bild-Komponente in erste Sektion einfügen?</Form.Label>
                    <Form.Check // prettier-ignore
                        type={"checkbox"}
                        id={"checkbox-addImageComponentInFirstSectionSelected"}
                        label={"Ja, einfügen"}
                        checked={this.state.addImageComponentInFirstSectionSelected}
                        name={"addImageComponentInFirstSectionSelected"}
                        onChange={(e) => {
                            this.state.addImageComponentInFirstSectionSelected = !this.state.addImageComponentInFirstSectionSelected;
                            this.state.popupProps.body = this.renderCreateWorksheetsPopupBody();
                            this.setState(this.state);
                        }}
                    />

                    <Form.Label htmlFor="disabledSelect">{this._getListOfSelectedTopics().length} Projekte ausgewählt</Form.Label>
                    <ul className={"selected-topics-list"}>
                        {
                            this._getListOfSelectedTopics().map((topicId) => {
                                return <li>{topicId}</li>;
                            })
                        }
                    </ul>

                </div>
            </>
        )
    }

    renderDeletePopupBody(){
        if(this._getListOfSelectedTopics().length === 0){
            return (
                <Alert key={'warning'} variant={'warning'}>
                    Bitte mindestens 1 Projekt auswählen
                </Alert>
            );
        }
        return (
            <>
                <div>
                    <Form.Label htmlFor="disabledSelect">{this._getListOfSelectedTopics().length} Projekte ausgewählt</Form.Label>
                    <ul className={"selected-topics-list"}>
                        {
                            this._getListOfSelectedTopics().map((topicId) => {
                                return <li>{topicId}</li>;
                            })
                        }
                    </ul>
                </div>
            </>
        )
    }


    renderCreateWorksheetTemplatePopupBody(){
        return <>
            <SingleSelectInputFormField
                title={<><FontAwesomeIcon icon={faCubes}/> Übergeordnetes Template <small>(optional)</small></>}
                selectedValue={this.state.selectedTemplateOption != null ? this.state.selectedTemplateOption.value : null}
                options={this.state.allTemplatesOptions}
                onChange={(selectedOption) => {
                    this.setState({
                        selectedTemplateOption: selectedOption,
                    });
                }}
            />
        </>
    }

    renderDeletePopupBody(){
        if(this._getListOfSelectedTopics().length === 0){
            return (
                <Alert key={'warning'} variant={'warning'}>
                    Bitte mindestens 1 Projekt auswählen
                </Alert>
            );
        }
        return (
            <>
                <div>
                    <Form.Label htmlFor="disabledSelect">{this._getListOfSelectedTopics().length} Projekte ausgewählt</Form.Label>
                    <ul className={"selected-topics-list"}>
                        {
                            this._getListOfSelectedTopics().map((topicId) => {
                                return <li>{topicId}</li>;
                            })
                        }
                    </ul>
                </div>
            </>
        )
    }

    toggleVisibilityKey(keyId){
        if (!this.state.visibilityKeyMap.hasOwnProperty(keyId)) {
            this.state.visibilityKeyMap[keyId] = false;
        }
        this.state.visibilityKeyMap[keyId] = !this.state.visibilityKeyMap[keyId];
        this.setState(this.state);
    }

    _renderConnectedTopicsRows(connectedTopics){
        if(this.state.loading){
            return <span>Loading</span>
        }else {
            return connectedTopics.map((connectedTopic) => {
                return (
                    <>
                        <Accordion style={{marginBottom: '1em'}} className={"pradleAccordion"}
                                   activeKey={this.state.visibilityKeyMap['acc-' + connectedTopic._id] ? 'acc-' + connectedTopic._id:'-1' }>
                            <Card>
                                <Card.Header>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        this.toggleVisibilityKey('acc-' + connectedTopic._id)
                                    }} className={"cardHeaderWrapper"}>
                                        <Row>
                                            <Col xs={10}>
                                                <h4 style={{color: 'white'}}
                                                    className={this.state.visibilityKeyMap['acc-' + connectedTopic._id] ? "closed" : "open"}>
                                                    <FontAwesomeIcon icon={faSheetPlastic}/>  Arbeitsblatt {connectedTopic.projectId}
                                                </h4>
                                            </Col>
                                            <Col xs={2}>
                                                <div align={"right"} style={{paddingRight: '1em', fontSize: '1.5em'}}>
                                                    {this.state.visibilityKeyMap['acc-' + connectedTopic._id] ?
                                                        <FontAwesomeIcon icon={faCaretUp}/> :
                                                        <FontAwesomeIcon icon={faCaretDown}/>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={'acc-' + connectedTopic._id}>
                                    <Card.Body>
                                        <h4>
                                            <FontAwesomeIcon
                                                icon={faImages}/> {connectedTopic.files !== undefined ? connectedTopic.files.length : 0} Projektskizzen
                                            / Grafiken
                                        </h4>
                                        <WorksheetImageDropzone projectId={connectedTopic.id} autoSave={true} files={connectedTopic.files}
                                            onFilesUpdated={(updatedFiles) => {
                                                var foundIndex = this.state.topics.descriptions.findIndex(
                                                    description => (true === description.connectedTopics.some(ct => ct._id === connectedTopic._id))
                                                );
                                                let ctIndex = this.state.topics.descriptions[foundIndex].connectedTopics.findIndex(ct => ct._id === connectedTopic._id);
                                                this.state.topics.descriptions[foundIndex].connectedTopics[ctIndex].files = updatedFiles;

                                                this.setState(this.state);
                                            }}>
                                        </WorksheetImageDropzone>
                                        <div style={{marginTop: '1em', marginBottom: '1em'}}>
                                            <Link to={"/editor/" + connectedTopic.id}>
                                                <Button
                                                    className={connectedTopic.template != null ? "connected" : "connected noTemplate"}>
                                                    <FontAwesomeIcon
                                                        icon={faArrowRight}/> Arbeitsblatt {connectedTopic.projectId} öffnen
                                                </Button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </>)
            });
        }
    }

    _getTitleImage(connectedTopics){
        let filesArray = [];
        if(connectedTopics.length > 0 && connectedTopics[0].hasOwnProperty('files')){
            filesArray = connectedTopics[0].files;
        }
        let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
        if(url == null){
            url = defaultImageUrl;
        }
        return <img style={{marginTop:'1em',marginBottom:'1em',background:'white'}} className={"topicImage"} src={url} />
    }

    render() {
        var self = this;
        if (this.state.isFetching) {
            return <LoadingScreen/>;
        }

            return (
                <div className={"TopicsListPageForInstructor"}>
                    <PagePopup
                        className={"evaluationPopup"}
                        onClose={()=>
                            this.setState(prevState => ({
                                popupProps: {
                                    ...prevState.popupProps,
                                    show: false
                                }
                            }))}
                        onSave={this.state.popupProps.onSave}
                        saveButtonText={<><FontAwesomeIcon icon={faCheck} /> {this.state.popupProps.submitText}</>}
                        closeButtonText={<><FontAwesomeIcon icon={faClose} /> {this.state.popupProps.cancelText}</>}
                        key={this.state.popupProps.show+"_popup"}
                        {...this.state.popupProps}>
                    </PagePopup>
                    <PageContent>
                            <div>
                                <Container>
                                    <div>
                                        <h2>{this._getStammgewerkNummerByProfessionId(this.state.selectedProfession)!=null && this._getStammgewerkNummerByProfessionId(this.state.selectedProfession)+" | "}{this.state.selectedProfessionName}</h2>
                                        <Form.Label style={{marginRight:'0.5em'}}>Ausbildungsjahr</Form.Label>{this.state.filterNavigationElements.filter(f=>f.group === 'Ausbildungsjahr').map((item, index) => (
                                            <a className={item.selected?'professionFilter selected':'professionFilter'} href={item.url}>{item.name}</a>
                                        ))}

                                        <Form.Label style={{marginRight:'0.5em',marginLeft:'0.5em'}}>Stammgewerk</Form.Label>{this.state.filterNavigationElements.filter(f=>f.group === 'Stammgewerk').map((item, index) => (
                                            <a className={item.selected?'professionFilter selected':'professionFilter'} href={item.url}>{item.name}</a>
                                        ))}
                                        <Form.Label style={{marginRight:'0.5em',marginLeft:'0.5em'}}>Arbeitsblätter</Form.Label>

                                            <Button type="button" className={"btn btn-primary"} variant="primary" onClick={()=>{
                                                // eslint-disable-next-line no-restricted-globals
                                                    this.setState(prevState => ({
                                                        popupProps: {
                                                            ...prevState.popupProps,
                                                            title: <><FontAwesomeIcon icon={faPlusCircle} /> Arbeitsblätter Generieren</>,
                                                            body: this.renderCreateWorksheetsPopupBody(),
                                                            onSave:async ()=> {
                                                                if(this.state.selectedTemplateOption == null || this.state.selectedTemplateOption.value == null) {
                                                                    window.alert("Sie haben kein Template ausgewählt");
                                                                }else   if(this._getListOfSelectedTopics().length == 0) {
                                                                    window.alert("Sie haben keine Projekte ausgewählt");
                                                                }else {
                                                                    if (window.confirm("Achtung, wollen Sie die Aktion wirklich durchführen? Gelöschte Daten können nicht zurückgesetzt werden.")) {
                                                                        await APIController.sendWorksheetTemplateBulkReset(
                                                                            this.state.selectedProfession,
                                                                            this.state.selectedTemplateOption.value,
                                                                            this.state.deleteOldFileteredByTopicsSelected,
                                                                            this.state.addImageComponentInFirstSectionSelected,
                                                                            this._getListOfSelectedTopics());
                                                                        window.location.reload()
                                                                    }
                                                                }
                                                                this.setState(prevState => ({
                                                                    popupProps: {
                                                                        ...prevState.popupProps,
                                                                        show: false
                                                                    }
                                                                }));
                                                            },
                                                            show: true
                                                        }
                                                    }))

                                            }} ><FontAwesomeIcon icon={faPlusCircle}/> Generieren</Button>

                                        <Button style={{marginLeft:'1em'}} type="button" className={"btn btn-danger"} variant="primary" onClick={()=>{
                                            // eslint-disable-next-line no-restricted-globals
                                            this.setState(prevState => ({
                                                popupProps: {
                                                    ...prevState.popupProps,
                                                    title: <><FontAwesomeIcon icon={faTrash} /> Arbeitsblätter löschen</>,
                                                    body: this.renderDeletePopupBody(),
                                                    show: true,
                                                    onSave:async ()=> {
                                                        if(this._getListOfSelectedTopics().length == 0) {
                                                            window.alert("Sie haben keine Projekte ausgewählt");
                                                        }else {
                                                            if (window.confirm("Achtung, wollen Sie wirklich alle ausgewählte Arbeitsblätter löschen?")) {
                                                                await APIController.sendWorksheetTemplateBulkResetDelete(
                                                                    this._getListOfSelectedTopics()
                                                                );
                                                                window.location.reload()
                                                            }
                                                        }
                                                        this.setState(prevState => ({
                                                            popupProps: {
                                                                ...prevState.popupProps,
                                                                show: false
                                                            }
                                                        }));
                                                    },
                                                }
                                            }))

                                        }} ><FontAwesomeIcon icon={faTrash}/> Löschen</Button>


                                        {this.state.topics === undefined &&
                                            <Alert style={{marginTop:'1em'}}>
                                                Zu dieser Suche wurde keine Projekte gefunden. Bitte zuerst ein Projekt im Uniplus System anlegen.
                                            </Alert>
                                        }

                                        {this.state.topics !== undefined &&
                                            <>
                                            <div style={{marginBottom:'1em'}}><Button className={"btn-sm"}
                                                         onClick={() => {
                                                             this.selectAllFields();
                                                         }}
                                            ><FontAwesomeIcon icon={this.state.lastTimeAllSelected?faCheckSquare:faSquare} /></Button>
                                                <span style={{marginLeft:'1em'}}>{this._getListOfSelectedTopics().length} / {this.state.topics.descriptions.length} Projekte ausgewählt</span>
                                            </div>


                                        {this.state.topics.descriptions.map((topic, index) => (
                                            <div>{
                                                <div  className={"topicRow"} style={topic.connectedTopics.length === 0?{background:'#000'}:{}}>
                                                    <Row>
                                                        <Col xs={12}  md={12} lg={8} style={{display:'flex',justifyContent:'left',alignItems:'start'}}>
                                                            <div style={{paddingTop:'0.5em'}}>
                                                            <Row>
                                                                <Col xs={12}  md={12} lg={12} style={{display:'flex',justifyContent:'left',alignItems:'start'}}>

                                                                        <Form.Check // prettier-ignore
                                                                            type={"checkbox"}
                                                                            id={"default-checkbox"}
                                                                            label={""}
                                                                            ref={""}
                                                                            checked={this.state.selectedTopics[topic.topicId]}
                                                                            name={topic.topicId}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <h3>
                                                                            <span className={"topic"}>{topic.topicId}</span><span style={{marginLeft:'0.5em',marginRight:'0.5em'}}>-</span><span className={"taskName"}>{topic.taskName}</span>
                                                                        </h3>

                                                                </Col>

                                                                <Col xs={12}>
                                                                    {topic.connectedTopics.length === 0 &&
                                                                        <div style={{paddingBottom:'1em'}}>
                                                                            <span className={"badge"} style={{background:'red',color:'white'}}><FontAwesomeIcon icon={faWarning} /> Kein Arbeitsblatt-Template definiert!</span>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col xs={12} lg={9} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                                    <div>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{paddingRight:'1em'}}>Beschreibung</td>
                                                                                <td>{topic.taskDescription?topic.taskDescription:<span className={"badge"} style={{background:'red',color:'white'}}>nicht definiert</span>}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{paddingRight:'1em'}}>Lernfeldnr.</td>
                                                                                <td>{topic.learningFieldId?topic.learningFieldId:<span className={"badge"} style={{background:'red',color:'white'}}>nicht definiert</span>}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{paddingRight:'1em'}}>Rahmenlehrplanr.</td>
                                                                                <td>{topic.curriculumId?topic.curriculumId:<span className={"badge"} style={{background:'red',color:'white'}}>nicht definiert</span>}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} lg={12} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                                    {topic.connectedTopics.length == 0 &&
                                                                        <div>
                                                                            <br />
                                                                            <Button type="button" className={"btn btn-outline"} variant="primary" onClick={()=>{
                                                                                // eslint-disable-next-line no-restricted-globals
                                                                                this.setState(prevState => ({
                                                                                    popupProps: {
                                                                                        ...prevState.popupProps,
                                                                                        title: <><FontAwesomeIcon icon={faSheetPlastic} /> Arbeitsblatt für Projekt {topic.topicId} anlegen</>,
                                                                                        body: this.renderCreateWorksheetTemplatePopupBody(),
                                                                                        show: true,
                                                                                        onSave:async ()=> {
                                                                                            if(this.state.selectedTemplateOption == null || this.state.selectedTemplateOption.value == null) {
                                                                                                this.addNewWorkingsheetTemplateClicked(topic.topicId)
                                                                                            }else{
                                                                                                this.addNewWorkingsheetTemplateClicked(topic.topicId,this.state.selectedTemplateOption.value)
                                                                                            }
                                                                                            this.setState(prevState => ({
                                                                                                popupProps: {
                                                                                                    ...prevState.popupProps,
                                                                                                    show: false
                                                                                                }
                                                                                            }));
                                                                                        },
                                                                                    }
                                                                                }))

                                                                            }} ><FontAwesomeIcon icon={faSheetPlastic}/> Arbeitsblatt verknüpfen</Button>
                                                                        </div>
                                                                    }
                                                                </Col>

                                                            </Row>
                                                            </div>

                                                        </Col>
                                                        <Col xs={12} md={4} lg={4}>
                                                            {<>{this._getTitleImage(topic.connectedTopics)}</>}
                                                        </Col>
                                                        <Col  xs={12}>
                                                            {this._renderConnectedTopicsRows(topic.connectedTopics)}
                                                        </Col>
                                                    </Row>

                                                </div>
                                            }</div>
                                        ))}
                                    </>
                        }
                            </div>
                            <Alert>
                                <h3><FontAwesomeIcon icon={faEyeSlash}/> Globaler Projekt-ID Filter</h3>
                                <ul className={"topicsFilter"}>{this.state.topicIdBlacklist.map((topic) => {return <li>{topic}</li>})}</ul>
                                <small style={{marginTop:'1em', display:'inline-block'}}><FontAwesomeIcon icon={faCircleInfo}/> Die hier angegebenen Projektnummern werden systemweit herausgefiltert. Zur Anpassung dieses Filters, bitte kurze Nachricht an <a href={'mailto:marschner@bauabc.de'}>marschner@bauabc.de.</a> mit Änderungswunsch</small>
                            </Alert>
                        </Container>
                    </div>
                    </PageContent>
                </div>
            )

    }
}

export default SingleTopicsPageForInstructor;
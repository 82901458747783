import React, {Component} from 'react';
import loading from "../assets/loadingicon_blue.gif";

import './LoadinScreen.css'
class LoadingScreen extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className="LoadingScreen">
                <div className={"loadingWraper"}>
                    <img src={loading} className="loadingImage" />
                </div>
            </div>
        )
    }
}
export default LoadingScreen;
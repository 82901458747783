import * as formik from "formik";
import * as yup from "yup";
import React, {Component, useEffect, useState, forwardRef} from "react";
import APIController from "../../controller/APIController";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBank, faEnvelope, faEnvelopeOpen, faHandshake, faHashtag,
    faHourglassStart, faLocationDot, faPerson,
    faPhone,
    faPhoneAlt, faPhoneSquareAlt, faRoad, faTreeCity,
    faUser, faUserAlt,
    faUserTie,
    faVault
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import PageHeader from "../../includes/PageHeader";
import PageContent from "../../includes/PageContent";
import CenteredMessage from "../../includes/CenteredMessage";
import ObjectUtil from "../../util/objectUtil";


const ProfileUpdateForm = (props) => {

    // Fetch Profile Values

    const { Formik, getIn } = formik;
    const schema = yup.object().shape({
        communicationData: yup.object().shape({
            phone: yup.string(),
            email: yup.string().email('Bitte gebe eine gültige E-Mail-Adresse ein').required(),
            mobile: yup.string().required("Required"),
        }),
        bankDetails: yup.object().shape({
            iban: yup.string()
                .matches(
                    '^(?:(?:CR|DE|ME|RS|VA)\\d{20}|(?:CZ|ES|SE|SK|TN)\\d{22}|(?:DK|FI|FO|GL|SD)\\d{16}|(?:AT|BA|EE|LT|XK)\\d{18}|(?:AE|IL|TL)\\d{21}|(?:LY|PT|ST)\\d{23}|(?:IT|SM)\\d{2}[A-Z]\\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\\d{26}|(?:AL|CY)\\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\\d{12}[A-Za-z0-9]{11}\\d{2}|(?:GB|IE)\\d{2}[A-Z]{4}\\d{14}|(?:KZ|LU)\\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\\d{10}[A-Za-z0-9]{12}|AZ\\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\\d{14}|BG\\d{2}[A-Z]{4}\\d{6}[A-Za-z0-9]{8}|BH\\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\\d{25}[A-Z][A-Za-z0-9]|BY\\d{2}[A-Za-z0-9]{4}\\d{4}[A-Za-z0-9]{16}|DO\\d{2}[A-Za-z0-9]{4}\\d{20}|EG\\d{27}|GE\\d{2}[A-Z]\\d{16}|GT\\d{2}[A-Za-z0-9]{24}|GR\\d{9}[A-Za-z0-9]{16}|HR\\d{19}|IS\\d{24}|JO\\d{2}[A-Z]{4}\\d{4}[A-Za-z0-9]{18}|KW\\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\\d{6}[A-Za-z0-9]{20}|LV\\d{2}[A-Z]{4}\\d{13}|MD\\d{2}[A-Za-z0-9]{20}|MK\\d{5}[A-Za-z0-9]{10}\\d{2}|MR\\d{25}|MT\\d{2}[A-Z]{4}\\d{5}[A-Za-z0-9]{18}|MU\\d{2}[A-Z]{4}\\d{19}[A-Z]{3}|NL\\d{2}[A-Z]{4}\\d{10}|NO\\d{13}|SA\\d{4}[A-Za-z0-9]{18}|SC\\d{2}[A-Z]{4}\\d{20}[A-Z]{3}|SI\\d{17}|SV\\d{2}[A-Z]{4}\\d{20}|TR\\d{8}[A-Za-z0-9]{16}|UA\\d{8}[A-Za-z0-9]{19}|VG\\d{2}[A-Z]{4}\\d{16}|GE\\d{2}[A-Z]{2}\\d{16})$',
                    'Bitte gebe eine gültige IBAN ein'
                ).required(),
            creditInstitution: yup.string().required(),
            bic: yup.string().required(),
            differentAccountHolder: yup.string(),
            lfdnr: yup.number(),
        }),
        legalGuardian: yup.object().shape({
            salutation: yup.string().required(),
            name: yup.string().required(),
            firstname: yup.string().required(),
            street: yup.string().required(),
            zip: yup.string().matches('^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$', 'Bitte gebe eine gültige Postleitzahl ein').required(),
            city: yup.string().required(),
            phone: yup.string().required(),
            email: yup.string().email('Bitte gebe eine gültige E-Mail-Adresse ein')
        }),
    });
    const [initialValues, setInitialValues] = React.useState(ObjectUtil.convertNullToEmpty(props.profile));
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    const onSubmitForm = async (values, { resetForm }) => {
        try {
            setApiResponseMessage("");
            setInProgress(true);
            props.state_update_handler({
                profile: values,
                isFetching: true,
            })
            let updatedProfile = await APIController.updateTraineeProfile(values);

            if(updatedProfile){
                setAlertType("alert-success");
                setApiResponseMessage("Dein Profil wurde erfolgreich aktualisiert")
                resetForm();
                props.state_update_handler({
                    profile: updatedProfile,
                    isFetching: false
                })
                setInProgress(false);
                window.location.reload();
            }
        }
        catch (error){
            resetForm();
            setInProgress(false);
            setApiResponseMessage(error.message);
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmitForm}
            onValidationError={errorValues => {
                window.alert("failed")
            }}
            validationSchema={schema}
        >

            {({ handleSubmit, setFieldValue, values, handleBlur, handleChange, touched, errors, validateForm}) => (
                <Form  onSubmit={handleSubmit}
                       autoComplete="off">
                    <div className="communicationData">
                        <Row>
                            <Col className="mb-4">
                                <h4 className="fw-bold text-white">
                                    Kommunikationsdaten
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faEnvelopeOpen}/> E-Mail*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'communicationData.email'}
                                                  id={'communicationData.email'}
                                                  placeholder="E-Mail eingeben"
                                                  value={values.communicationData.email}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.communicationData && !!touched.communicationData.email) &&
                                                      (!!errors.communicationData && !!errors.communicationData.email)
                                                  }
                                                  onChange={(e) => setFieldValue("communicationData.email", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(!!errors.communicationData && !!errors.communicationData.email) ? errors.communicationData.email : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faPhone}/> Telefon
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'communicationData.phone'}
                                                  id={'communicationData.phone'}
                                                  placeholder="Telefon eingeben"
                                                  value={values.communicationData.phone}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.communicationData && !!touched.communicationData.phone) &&
                                                      (!!errors.communicationData && !!errors.communicationData.phone)
                                                  }
                                                  onChange={(e) => setFieldValue("communicationData.phone", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(!!errors.communicationData && !!errors.communicationData.phone) ? errors.communicationData.phone : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faPhoneAlt}/> Mobiltelefon*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'communicationData.mobile'}
                                                  id={'communicationData.mobile'}
                                                  placeholder="Mobiltelefon eingeben"
                                                  value={values.communicationData.mobile}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.communicationData && !!touched.communicationData.mobile) &&
                                                      (!!errors.communicationData && !!errors.communicationData.mobile)
                                                  }
                                                  onChange={(e) => setFieldValue("communicationData.mobile", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(!!errors.communicationData && !!errors.communicationData.mobile) ? errors.communicationData.mobile : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className="bankDetails mt-5">
                        <Row>
                            <Col className="mb-4">
                                <h4 className="fw-bold text-white">
                                    Bankdaten
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faVault}/> IBAN*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'bankDetails.iban'}
                                                  id={'bankDetails.iban'}
                                                  placeholder="IBAN eingeben"
                                                  value={values.bankDetails.iban}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.bankDetails && !!touched.bankDetails.iban) &&
                                                      (!!errors.bankDetails && !!errors.bankDetails.iban)
                                                  }
                                                  onChange={(e) => setFieldValue("bankDetails.iban", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.bankDetails && !!errors.bankDetails.iban) ? errors.bankDetails.iban : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faVault}/> BIC*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'bankDetails.bic'}
                                                  id={'bankDetails.bic'}
                                                  placeholder="BIC eingeben"
                                                  value={values.bankDetails.bic}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.bankDetails && !!touched.bankDetails.bic) &&
                                                      (!!errors.bankDetails && !!errors.bankDetails.bic)
                                                  }
                                                  onChange={(e) => setFieldValue("bankDetails.bic", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.bankDetails && !!errors.bankDetails.bic) ? errors.bankDetails.bic : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} md={2} lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faHashtag}/> LFDnr
                                    </Form.Label>
                                    <Form.Control type="number"
                                                  name={'bankDetails.lfdnr'}
                                                  id={'bankDetails.lfdnr'}
                                                  placeholder="LFDnr eingeben"
                                                  value={values.bankDetails.lfdnr}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.bankDetails && !!touched.bankDetails.lfdnr) &&
                                                      (!!errors.bankDetails && !!errors.bankDetails.lfdnr)
                                                  }
                                                  onChange={(e) => setFieldValue("bankDetails.lfdnr", e.target.value)}
                                                  disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.bankDetails && !!errors.bankDetails.lfdnr) ? errors.bankDetails.lfdnr : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>*/}
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faBank}/> Kreditinstitut*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'bankDetails.iban'}
                                                  id={'bankDetails.iban'}
                                                  placeholder="Kreditinstitut eingeben"
                                                  value={values.bankDetails.creditInstitution}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.bankDetails && !!touched.bankDetails.creditInstitution) &&
                                                      (!!errors.bankDetails && !!errors.bankDetails.creditInstitution)
                                                  }
                                                  onChange={(e) => setFieldValue("bankDetails.creditInstitution", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.bankDetails && !!errors.bankDetails.creditInstitution) ? errors.bankDetails.creditInstitution : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faUserTie}/> Abweichender Kontoinhaber
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'bankDetails.iban'}
                                                  id={'bankDetails.iban'}
                                                  placeholder="Abweichender Kontoinhaber eingeben"
                                                  value={values.bankDetails.differentAccountHolder}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.bankDetails && !!touched.bankDetails.differentAccountHolder) &&
                                                      (!!errors.bankDetails && !!errors.bankDetails.differentAccountHolder)
                                                  }
                                                  onChange={(e) => setFieldValue("bankDetails.differentAccountHolder", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.bankDetails && !!errors.bankDetails.differentAccountHolder) ? errors.bankDetails.differentAccountHolder : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className={'legalGuardian mt-5'}>
                        <Row>
                            <Col className="mb-4">
                                <h4 className="fw-bold text-white">
                                    Informationen zum Erziehungsberechtigten
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faHandshake}/> Anrede*
                                    </Form.Label>
                                    <Form.Select
                                        name={'legalGuardian.salutation'}
                                        id={'legalGuardian.salutation'}
                                        defaultValue={values.legalGuardian.salutation}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            (!!touched.legalGuardian && !!touched.legalGuardian.salutation) &&
                                            (!!errors.legalGuardian && !!errors.legalGuardian.salutation)
                                        }
                                        onChange={(e) => {
                                            return setFieldValue("legalGuardian.salutation", e.target.value)
                                        }}>
                                        {initialValues.legalGuardian.allSalutationOptions.map((option) => {
                                            return (<option key={option.key} value={option.key}>
                                                {option.value}
                                            </option>)
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.salutation) ? errors.legalGuardian.salutation : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faUserAlt}/> Vorname*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.firstname'}
                                                  id={'legalGuardian.firstname'}
                                                  placeholder="Vorname eingeben"
                                                  value={values.legalGuardian.firstname}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.firstname) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.firstname)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.firstname", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.firstname) ? errors.legalGuardian.firstname : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faPerson}/> Nachname*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.name'}
                                                  id={'legalGuardian.name'}
                                                  placeholder="Nachname eingeben"
                                                  value={values.legalGuardian.name}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.name) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.name)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.name", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.name) ? errors.legalGuardian.name : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faPhoneSquareAlt}/> Telefon*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.phone'}
                                                  id={'legalGuardian.phone'}
                                                  placeholder="Telefon eingeben"
                                                  value={values.legalGuardian.phone}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.phone) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.phone)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.phone", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.firstname) ? errors.legalGuardian.firstname : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faEnvelope}/> E-Mail
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.email'}
                                                  id={'legalGuardian.email'}
                                                  placeholder="E-Mail eingeben"
                                                  value={values.legalGuardian.email}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.email) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.email)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.email", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.email) ? errors.legalGuardian.email : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={12} lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faRoad}/> Strasse*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.street'}
                                                  id={'legalGuardian.street'}
                                                  placeholder="Strasse eingeben"
                                                  value={values.legalGuardian.street}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.street) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.street)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.street", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.street) ? errors.legalGuardian.street : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faLocationDot}/> PLZ*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.zip'}
                                                  id={'legalGuardian.zip'}
                                                  placeholder="PLZ eingeben"
                                                  value={values.legalGuardian.zip}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.zip) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.zip)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.zip", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.zip) ? errors.legalGuardian.zip : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={8} lg={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-center">
                                        <FontAwesomeIcon icon={faTreeCity}/> Ort*
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  name={'legalGuardian.city'}
                                                  id={'legalGuardian.city'}
                                                  placeholder="Ort eingeben"
                                                  value={values.legalGuardian.city}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                      (!!touched.legalGuardian && !!touched.legalGuardian.city) &&
                                                      (!!errors.legalGuardian && !!errors.legalGuardian.city)
                                                  }
                                                  onChange={(e) => setFieldValue("legalGuardian.city", e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(!!errors.legalGuardian && !!errors.legalGuardian.city) ? errors.legalGuardian.city : ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-1 fw-semibold">Felder, die mit * markiert sind, sind Pflichtfelder</div>

                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid mt-5">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        onClick={() => validateForm().then((e) => {
                                            if(Object.keys(e).length !== 0){
                                                window.alert("Profil konnte nicht gespeichert werden. Bitte alle Pfichtfelder überprüfen");
                                                var firstElementWithClass = document.querySelector('.invalid-feedback');
                                                firstElementWithClass.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                            }
                                        })}
                                        variant="secondary" size={"lg"}>
                                        Profildaten speichern
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

class TraineeProfileUpdateForm extends Component {
    constructor(props){
        super(props);
        this.params = props.params;
        this.state = {
            isFetching: true,
            reload: true,
            profile : null,
        };

    }

    state_update_handler = (newStateValue) => {
        this.setState(newStateValue);
    }

    async componentDidMount() {
        await this.reload();

    }

    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            const profileResponse = await APIController.getTraineeProfile();

            this.setState({
                isFetching: false,
                reload:false,
                profile : profileResponse,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                profile: null
            });
        }
    }

    render() {
        if(!this.state.isFetching && this.state.profile == null){
            return (
                <>
                    <PageHeader title={"Aktualisiere bitte das Azubi-Profil"} fontAwesomeIcon={faUser}/>
                    <PageContent>
                        <CenteredMessage>
                            <FontAwesomeIcon icon={faUser} />
                            Das Profilaktualisierungsformular konnte nicht geladen werden
                        </CenteredMessage>
                    </PageContent>
                </>
            )
        }
        return (
            <>
                {this.state.isFetching ? (<LoadingScreen />):
                    <div className="TraineeProfileUpdateForm">
                        <PageContent>
                            <Container style={{maxWidth: 'calc(80vw)'}}>
                                <ProfileUpdateForm profile={this.state.profile} state_update_handler={this.state_update_handler} />
                            </Container >
                        </PageContent>
                    </div>
                }
            </>
        )

    }
}

export default TraineeProfileUpdateForm;

class APIError {

    static CODES = {
        UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERRER',
        SECTION_DEPENDS_ON_OTHER_PROJECTS: 'SECTION_DEPENDS_ON_OTHER_PROJECTS',
        PROJECT_DEPENDS_ON_OTHER_PROJECTS: 'PROJECT_DEPENDS_ON_OTHER_PROJECTS',
        FILE_HANDLE_ERROR: 'FILE_HANDLE_ERROR',
    }
}

export default APIError;

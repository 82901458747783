import React, {Component, useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import logo from "../../assets/bauabc_logo.PNG";
import * as formik from "formik";
import * as yup from "yup";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

const ForgotPasswordFormComponent = (props) => {
    const { Formik, Field } = formik;
    const schema = yup.object().shape({
        email: yup.string().required("E-mail kann nicht leer sein").email("Ungültige E-Mail"),
    });
    const [initialValues, setInitialValues] = React.useState({email:""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let payLoadEmail = values.email;
                    let isAccountValid = await APIController.requestForgotPasswordEmail(payLoadEmail);
                    if(isAccountValid.valid){
                        setAlertType("alert-success");
                        setApiResponseMessage("Dein Konto ist gültig. Bitte überprüfe deine E-Mails auf den Link zum Zurücksetzen")
                        resetForm();
                        setInProgress(false);
                    }else{
                        resetForm();
                        setAlertType("alert-warning");
                        setApiResponseMessage("Tut mir leid, dass du kein Konto hast. Stelle sicher, dass du eingeladen wurdest");
                        setInProgress(false);
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Email
                        </Form.Label>
                        <Form.Control type="email" placeholder="E-mail eingeben" id="email" value={values.email}
                                      onBlur={handleBlur}
                                      isInvalid={touched.email && !!errors.email}
                                      onChange={(e) => setFieldValue( "email",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Reset Password
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}



class ForgotPassword extends Component {

    constructor(props){
        super(props);
        if(UserController.isLoggedIn()){

            window.location = UserController.getMyCoursesUrl();
        }
        this.params = props.params;
        this.state = {}
    }

    render() {
        return(
            <>
                <div className="Forgot-password">
                    <Container>
                        <Row className="vh-100 d-flex justify-content-center align-items-center">
                            <Col md={8} lg={6} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                    <div align={'center'}>
                                        <img src={logo} style={{height: "80px"}} className="App-logo" alt="logo"/>
                                    </div>
                                    <div className="mb-3 mt-md-4">
                                        <div align={'center'}>
                                            <h4 className="fw-bold mb-3 text-uppercase ">
                                                <b>Passwort zurücksetzen</b>
                                            </h4>
                                            <p className="fw-light mb-4 ">
                                                Ein Link zum Zurücksetzen Ihres Passworts wird Ihnen per E-Mail
                                                zugesandt
                                            </p>
                                        </div>
                                        <div className="mb-3 mt-md-4">
                                            <ForgotPasswordFormComponent state={this.state} />
                                        </div>
                                    </div>
                                    </Card.Body>
                                    <Card.Footer align={'end'} style={{backgroundColor: 'unset',borderTop:'unset'}}> <NavLink to={"/login"}> <span className={'text-decoration-underline'} >Hast du kein Konto?</span></NavLink></Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default ForgotPassword;